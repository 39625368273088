import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext, validateToken } from '../contexts/auth-context';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const { authenticated } = useContext(AuthContext);
    // const [isTokenValidated, setIsTokenValidated] = useState(false);
    const validToken = validateToken();

    const passToRoute = validToken && authenticated;
    
    // if (typeof roles === 'string') {
    //     roles = [roles];
    // }

    return (
        <Route {...rest} render={props => {
            if (!passToRoute) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location.pathname } }} />
            }

            // authorised so return component
            return <Component {...props} />
        }} />
)}