import React from "react";

import TranslateTextField from './TranslateTextField';

export default function TranslateTextFieldWrapper (props) {
    const { fieldName, values, valueIndex, setFieldValue, ...restProps } = props;

    return(
        <TranslateTextField 
            values={values}
            valueIndex={valueIndex}
            {...restProps}
            onChange={(newValue) => setFieldValue && setFieldValue(fieldName, newValue)}
        />
    )
}