import React, { createContext, useReducer } from 'react';

import { authService } from "../_services/auth.service";

export const AuthContext = createContext({
  token: undefined,
  user: null,
  authenticated: false,
  resetPassword: false,
  updateAuth: () => {}
});

const getUserFromToken = token => {
  if (token) {
    try{ 
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {

    }
  }

  return null;
}

export const getUserIDFromToken = () => {
  let token = authService.getIDToken();

  if (token) {
    try{ 
      return JSON.parse(atob(token.split('.')[1])).user_id;
    } catch (error) {

    }
  }

  return null;
}

export const getResetPasswordValue = () => {
  let token = authService.getIDToken();

  if (token) {
    try {
      return JSON.parse(atob(token.split('.')[1])).reset_password;
    } catch (error) {

    }
  }
}

export const getRolesFromToken = () => {
  let token = authService.getIDToken();

  if (token) {
    try {
      return JSON.parse(atob(token.split('.')[1])).roles;
    } catch (error) {

    }
  }
}

export const validateToken = () => {
  let token = authService.getIDToken();

  if(token) {
    let exp = JSON.parse(atob(token.split('.')[1])).exp;
    if(Date.now() < exp * 1000) {
      return true;
    }
  }

  return false;
}

export const AuthProvider = ({ children }) => {
  let validToken = validateToken();
  let token = authService.getIDToken();

  if(!validToken) {
    authService.logout();
  }

  const updateAuth = async () => {
    const token = (await authService.getIDToken()) || null;
    const isValidToken = validateToken();

    if(!isValidToken) {
      authService.logout();
    } else {
      if (token !== state.token) {
        let resetPasswordValue = getResetPasswordValue(); 
        setState({
          token,
          user: getUserFromToken(token),
          resetPassword: resetPasswordValue,
          authenticated: true
        })
      } else if(token === null) {
        setState({
          token: undefined,
          user: null,
          resetPassword: false,
          authenticated: false
        })
      }
    }
  }

  const initialState = validToken ? {
          token: token,
          user: getUserFromToken(token),
          authenticated: true,
          resetPassword: getResetPasswordValue(),
          updateAuth: updateAuth
        } :  {
          token: undefined,
          user: null,
          authenticated: false,
          resetPassword: false,
          updateAuth: updateAuth
        };

  const [state, setState] = useReducer((oldState, newState) => newState, initialState);

  return (
    <AuthContext.Provider value={{ ...state, updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
}