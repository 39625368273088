import React, { useState, useEffect } from 'react';
import _ from "lodash";

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add'; 

import Chip from '@material-ui/core/Chip';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import UserForm from '../components/UserForm';
import UserAvatar from '../components/UserAvatar';

import { getURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    marginLeft: '100px',
  },
}));

const submitEditUserURL = getURL('edit-user');
const submitNewUserURL = getURL('add-new-user');
const listUserURL = getURL('list-users');
const deleteUserURL = getURL('delete-user');

export default function Users(props) {
    const classes = useStyles();
    const { callApi, callApiAsync } = useAPI();
    const [userData, setUserData] = useState({userList: [], isFetching: false});

    const userInitialValues = {
        username: '',
        password: '',
        first_name: '',
        last_name: '',
        is_admin: false,
        reset_password: false,
        default_locale: 1,
        roles: []
    }
    const [userDataDialog, setUserDataDialog] = useState({ open: false, editMode: false, userData: userInitialValues });
    // const [internalUserData, setInternalUserData] = useState(userInitialValues);

    const mTableEditable = {
        onRowDelete: oldData =>
        new Promise((resolve, reject) => {
            const userObj = {
                username: oldData.username
            }
            // const response = callSecureApi({
            //     method: 'delete',
            //     url: deleteUserURL,
            //     data: userObj
            // });
            // TODO: Check response value
            callApi({
                method: 'delete',
                url: deleteUserURL,
                data: userObj
            });
            const data = userData.userList;
            const index = data.indexOf(oldData);
            data.splice(index, 1);
            setUserData({ userList: _.clone(data) });
            resolve();
        }),
    }

    useEffect(() => {
        const fetchUserData = async() => {
          try{
            setUserData({userList: [], isFetching: true});
            const response = await callApiAsync({
                method: 'get',
                url: listUserURL
            });
            setUserData({userList: response.data, isFetching: false});
          } catch(e) {
            console.log(e);
            setUserData({userList: [], isFetching: false});
          }
        };
        fetchUserData();
        // eslint-disable-next-line
    }, []);

    function handleAddUser() {
        setUserDataDialog({ ...userDataDialog, userData: userInitialValues, editMode: false, open: true });
        // setInternalUserData(userInitialValues);
    }

    function handleNewUserClose() {
        setUserDataDialog({ ...userDataDialog, open: false });
    }

    function handleEditUserCancel() {
        setUserDataDialog({ ...userDataDialog, userData: userInitialValues, open: false });
        // setInternalUserData(userInitialValues);
    }

    function handleNewUserSave(newUser) {
        let { password, ...restUser } = newUser;
        const data = userData.userList;
        data.push(restUser);
        setUserData({ userList: _.clone(data) });
    }

    function handleEditUserSave(updatedUserData) {
        let { password, ...restUser } = updatedUserData;
        let newEntries = _.map(userData.userList, (user) => {
            return user.user_id === restUser.user_id ? restUser : user;
        });
        setUserData({ userList: newEntries });
    }

    function resetForm() {
        setUserDataDialog({ ...userDataDialog, open: false, userData: userInitialValues });
        // setInternalUserData(userInitialValues);
    }

    let returnUserFormData = () => {};
    function dataFunctionSetter(fn) {
        returnUserFormData = fn;
    }

    async function submitForm() {
        let userFormData = returnUserFormData();
        if(userDataDialog.editMode) {
            // const response = await axios.post(submitEditUserURL, values);
            const response = await callApiAsync({
                method: 'post',
                url: submitEditUserURL,
                data: userFormData
            })
            if(response) {
                // console.log(response);
            }
            // TODO: Handle error conditions
            handleEditUserSave(response.data.user);
            resetForm();
        } else {
            const response = await callApiAsync({
                method: 'post',
                url: submitNewUserURL,
                data: userFormData
            })
            // TODO: Handle error conditions
            handleNewUserSave(response.data.user);
            resetForm();
        }
    }

    function editUser(rowData) {
        setUserDataDialog({ open: true, editMode: true, userData: rowData })
        // setInternalUserData(rowData);
    }

    // function updateUserValues(name, value) {
    //     setInternalUserData({ ...internalUserData, [name]: value});
    // }

    return (
        <Container component="main" maxWidth="xl">
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={handleAddUser}
            >
                Add User
            </Button>
            <MaterialTable
                columns={[
                    {
                        field: 'avatar', 
                        title: 'Avatar',
                        filtering: false,
                        render: rowData => {
                            return (<UserAvatar firstName={rowData.first_name} lastName={rowData.last_name} />)
                        }
                    },
                    {
                        field: 'username', 
                        title: 'Username',
                    },
                    {
                        field: 'name', 
                        title: 'Name',
                        render: rowData => {
                            return (<span>{rowData.first_name} {rowData.last_name}</span>)
                        }
                    },
                    {
                        field: 'is_admin', 
                        type: 'boolean', 
                        title: 'Is Admin?',
                    },
                    {
                        field: 'roles', 
                        title: 'Roles',
                        render: rowData => {
                            if(rowData.roles != null) {
                                let roleChips = rowData.roles.map((role) => (
                                    <Chip key={role} size="small" label={role} />
                                ))
                                return roleChips;
                            } else {
                                return (<span></span>);
                            }
                        }
                    },
                    {
                        field: 'default_locale', 
                        title: 'Default Language',
                    },
                    {
                        field: 'reset_password', 
                        type: 'boolean', 
                        title: 'Reset Password',
                    },
                    {
                        field: 'created_at', 
                        type: 'datetime', 
                        title: 'Created At',
                        filtering: false
                    },
                    {
                        field: 'updated_at', 
                        type: 'datetime', 
                        title: 'Updated At',
                        filtering: false
                    },
                ]}
                
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Edit User',
                        onClick: (event, rowData) => editUser(rowData)
                    },
                ]}
                editable={mTableEditable}
                options={{
                    search: false,
                    paging: false,
                    toolbar: false,
                    filtering: true
                }}
                title={''}
                isLoading={userData.isFetching}
                data={userData.userList}
            />
            <Dialog
                fullWidth
                maxWidth="md"
                open={userDataDialog.open}
                onClose={handleNewUserClose}
            >
                <DialogContent>
                    <UserForm 
                        initialValues={userDataDialog.userData}
                        editMode={userDataDialog.editMode}
                        // onChange={updateUserValues}
                        submitFunction={dataFunctionSetter}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitForm} color="primary">
                    Save
                    </Button>
                    <Button onClick={handleEditUserCancel} color="primary">
                    Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}