import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

function buildDurationString(days, nights, hours, minutes) {
  if(days && nights) {
    return days +"D"+ nights +"N";
  } else {
    let daysPart = (days > 1) ? days+" days" : ((days > 0) ? days+" day" : "");
    let hoursPart = (hours > 1) ? hours+" hours" : ((hours > 0) ? hours+" hour" : "");
    let minutesPart = (minutes > 1) ? minutes+" minutes" : ((minutes > 0) ? minutes+" minute" : "");

    let combString = daysPart +" "+ hoursPart +" "+ minutesPart;
    
    return combString;
  }
}

export default function ActivityCard(props) {
    const classes = useStyles();

    const { activityData, valueIndex, onEdit, onEditPricing, onDelete } = props;

    let durationString = buildDurationString(activityData.duration_days, activityData.duration_nights, activityData.duration_hours, activityData.duration_minutes);

    function editActivity(activityData) {
        if(onEdit) onEdit(activityData);
    }
    function editPricing(activityData) {
        if(onEditPricing) onEditPricing(activityData);
    }
    function deleteActivity(activityData) {
        if(onDelete) onDelete(activityData);
    }

    return (
        <Card className={classes.card}>
        <CardContent>
            {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
            {activityData.activity_name}
            </Typography> */}
            <Typography variant="h5" component="h2">
            {/* {activityData.activity_name} */}
            {activityData.lang.activity_name[valueIndex]}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
            {durationString}
            </Typography>
            {/* <Typography variant="body2" component="p">
            well meaning and kindly.
            <br />
            {'"a benevolent smile"'}
            </Typography> */}
        </CardContent>
        <CardActions>
            <Button size="small" onClick={(event) => editActivity(activityData)}>Edit</Button>
            <Button size="small" onClick={(event) => editPricing(activityData)}>Manage Pricing</Button>
            <Button size="small" onClick={(event) => deleteActivity(activityData)}>Delete</Button>
        </CardActions>
        </Card>
    )
}