import React from "react";

import CategoryPlaceholderImage from "../img/category_placeholder.png"

export default function CategoryPlaceholderImageComponent (props) {
  const { className, ...other } = props;

    return (
      <img src={CategoryPlaceholderImage} alt='Category Placeholder' className={className} {...other} />
    );

}