import React, { useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
// import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import clsx from 'clsx';
import { Link } from "react-router-dom";
// import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { useHistory } from "react-router-dom";

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import MailIcon from '@material-ui/icons/Mail';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import TranslateIcon from '@material-ui/icons/Translate';

import AppVersionNotification from './components/AppVersionNotification';

import { SnackbarProvider } from 'notistack';
import { LanguageProvider, LanguageConsumer } from './contexts/language-context';
import { authService } from './_services/auth.service';
import { AuthContext } from './contexts/auth-context';
import { AppVersionContext } from './contexts/app-version-context';

import AppDrawer from './components/AppDrawer';
import Logo from './components/Logo';

import Routes from "./Routes";

require('dotenv').config();

if(!process.env.REACT_APP_API_URL) {
  process.env.REACT_APP_API_URL = "";
}

const theme = createMuiTheme({
  palette: {
    primary: { main: '#005189' }, 
    secondary: { main: '#11cb5f' }, 
  },
});

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  appbarLogo: {
    height: '50px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
}));

export default function App(props) {
    const classes = useStyles();
    const history = useHistory();
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [langAnchorEl, setLangAnchorEl] = React.useState(null);
    // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    // const token = authService.getIDToken();
    const { versionMatch } = useContext(AppVersionContext);

  const { authenticated, resetPassword, updateAuth } = useContext(AuthContext);

  function handleDrawerOpen() {
    setDrawerOpen(true);
  }

  function handleDrawerClose() {
    setDrawerOpen(false);
  }

  // const isMenuOpen = Boolean(anchorEl);
  const isLangMenuOpen = Boolean(langAnchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // function handleProfileMenuOpen(event) {
  //   setAnchorEl(event.currentTarget);
  // }

  // function handleMobileMenuClose() {
  //   setMobileMoreAnchorEl(null);
  // }

  // function handleMenuClose() {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // }

  function handleLangMenuOpen(event) {
    setLangAnchorEl(event.currentTarget);
  }

  function handleLangMenuClose() {
    setLangAnchorEl(null);
    // handleMobileMenuClose();
  }

  async function handleLogout() {
    authService.logout();
    await updateAuth();
    history.push("/login");
  }

  // function handleMobileMenuOpen(event) {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // }

  // const menuId = 'primary-search-account-menu';
  // const renderMenu = (
  //   <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
  //     <MenuItem onClick={handleMenuClose}>My account</MenuItem>
  //   </Menu>
  // );
  const langMenuId = 'primary-lang-select-menu';
  const renderLanguageMenu = (
    <LanguageConsumer>
      {({ language, updateLanguage }) => (
        <Menu
          anchorEl={langAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={langMenuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isLangMenuOpen}
          onClose={handleLangMenuClose}
        >
          <MenuItem value={1} onClick={event => {updateLanguage(event.target.value); handleLangMenuClose();}}>English</MenuItem>
          <MenuItem value={2} onClick={event => {updateLanguage(event.target.value); handleLangMenuClose();}}>简体中文</MenuItem>
        </Menu>
      )}
    </LanguageConsumer>
  );

  // const mobileMenuId = 'primary-search-account-menu-mobile';
  // const renderMobileMenu = (
  //   <Menu
  //     anchorEl={mobileMoreAnchorEl}
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     id={mobileMenuId}
  //     keepMounted
  //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={isMobileMenuOpen}
  //     onClose={handleMobileMenuClose}
  //   >
  //     <MenuItem>
  //       <IconButton aria-label="Show 4 new mails" color="inherit">
  //         <Badge badgeContent={4} color="secondary">
  //           <MailIcon />
  //         </Badge>
  //       </IconButton>
  //       <p>Messages</p>
  //     </MenuItem>
  //     <MenuItem>
  //       <IconButton aria-label="Show 11 new notifications" color="inherit">
  //         <Badge badgeContent={11} color="secondary">
  //           <NotificationsIcon />
  //         </Badge>
  //       </IconButton>
  //       <p>Notifications</p>
  //     </MenuItem>
  //     <MenuItem onClick={handleProfileMenuOpen}>
  //       <IconButton
  //         aria-label="Account of current user"
  //         aria-controls="primary-search-account-menu"
  //         aria-haspopup="true"
  //         color="inherit"
  //       >
  //         <AccountCircle />
  //       </IconButton>
  //       <p>Profile</p>
  //     </MenuItem>
  //   </Menu>
  // );

  return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <LanguageProvider>
            <div className={classes.grow}>
              {renderLanguageMenu}
              <AppBar position="static">
                <Toolbar>
                  {(authenticated && !resetPassword) && (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className={clsx(classes.menuButton, {
                        [classes.hide]: drawerOpen,
                      })}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                  <Logo className={classes.appbarLogo} />
                  {(authenticated && !resetPassword) && (
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'Search' }}
                      />
                    </div>
                  )}
                  <div className={classes.grow} />
                    <div>
                      <IconButton
                        aria-label="Display language"
                        aria-controls={langMenuId}
                        aria-haspopup="true"
                        onClick={handleLangMenuOpen}
                        color="inherit"
                      >
                        <TranslateIcon />
                      </IconButton>
                      {authenticated ? (
                        <Button 
                          type="submit"
                          color="inherit"
                          onClick={handleLogout}
                        >
                          Logout
                        </Button>
                      ) : (
                        <Button 
                          type="submit"
                          color="inherit"
                          component={Link}
                          to="/login"
                        >
                          Login
                        </Button>
                      )}
                    </div>
                </Toolbar>
              </AppBar>
              <AppDrawer
                open={drawerOpen}
                handleDrawerClose={handleDrawerClose}
              />
              {/* {renderMobileMenu}
              {renderMenu} */}
              <Routes />
            </div>
            <AppVersionNotification show={!versionMatch} />
          </LanguageProvider>
        </SnackbarProvider> 
      </ThemeProvider>
  );
}