import React, {useEffect, useState} from 'react';
import _ from "lodash";

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';

import { EditorLanguageConsumer } from '../contexts/editor-language-context';
// import { LanguageProvider, LanguageConsumer } from '../contexts/language-context';
import TimeSlotsField from './fields/TimeSlotsField';
import TranslateTextFieldWrapper from './fields/TranslateTextFieldWrapper';
import AvailabilityDatesField from './fields/AvailabilityDatesField';

import { getURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
}));

export default function EditActivity(props) {
    const classes = useStyles();
    const { callApiAsync } = useAPI();

    const { onSaveActivity, onCancelActivity, editMode, activityID, activityData, newRecord } = props;

    let formikInitialValues = {
        duration_days: null,
        duration_nights: null,
        duration_hours: null,
        duration_minutes: null,
        schedule: [],
        pickup_time: '',
        dropoff_time: '',
        reconfirmation_req: false,
        supports_evoucher: false,
        start_time: '',
        start_location: '',
        end_location: '',
        is_pd: false,
        lang: {
            activity_name: {},
            arrangement: {},
            attention: {},
            language: {},
            inclusions: {},
            exclusions: {},
            additional_description: {},
            cancellation: {},
            pickup_location: {},
            dropoff_location: {}
        },
        t_activity_pricing: []
    }

    if(activityData) {
        formikInitialValues = activityData;
    }

    const [activityDetails, setActivityDetails] = useState({data: formikInitialValues, isFetching: true});

    useEffect(() => {
        if(!activityData && (editMode && activityID)) {
            const fetchActivityDetails = async() => {
                try{
                    const response = await callApiAsync({
                            method: 'get',
                            url:  getURL(activityID),
                        });
                    setActivityDetails({data: response.data[0], isFetching: false});
                    // TODO: Fetch pricing as well
                    // /get/activity/:id/pricing
                    // let url2 = buildURL(`/api/product/get/activity/${activityID}/pricing`);
                    // const responsePricing = await axios.get(url2);
                    const responsePricing = await callApiAsync({
                            method: 'get',
                            url: getURL(`get-activity-pricing`, activityID),
                        });
                    if(false) { console.log(responsePricing); }
                } catch(e) {
                    console.log(e);
                    setActivityDetails({isFetching: false});
                }
            };
            fetchActivityDetails();
        }

        if(newRecord) {
            const fetchNewActivityID = async () => {
                try{
                    const response = await callApiAsync({
                        method: 'get',
                        url: getURL('get-new-activity-id'),
                    });
                    setActivityDetails({data: {...activityDetails.data, activity_id: response.data.activity_id}, isFetching: false});
                } catch(e) {
                    console.log(`Unable to generate Activity ID`);
                    console.log(e);
                }
            };
            fetchNewActivityID();
        }
        // eslint-disable-next-line
    }, [activityData, editMode, activityID, newRecord])

    function confirmSaveActivity(e) {
        if(onSaveActivity) onSaveActivity(activityDetails.data);
    }

    function cancelSaveActivity(e) {
        if(onCancelActivity) onCancelActivity(activityDetails.data);
    }

    function translateFieldWrapper(fieldName, label, values, valueIndex, multiline, rowsMax) {
        return(
            <TranslateTextFieldWrapper
                fieldName={fieldName}
                label={label}
                values={values}
                valueIndex={valueIndex}
                multiline={multiline}
                rowsMax={rowsMax}
                fullWidth={true} 
                margin="dense"
                variant="outlined"
                setFieldValue={setLangFieldValue}
            />
        )
    }

    function textFieldWrapper(fieldName, label, value, multiline, rowsMax) {
        return(
            <FormControl fullWidth>
                <TextField
                    id={fieldName}
                    name={fieldName}
                    label={label}
                    value={value || ''}
                    multiline={multiline}
                    rowsMax={rowsMax}
                    fullWidth={true} 
                    margin="dense"
                    variant="outlined"
                    onChange={handleFieldChange}
                />
            </FormControl>
        )
    }

    function checkboxWrapper(fieldName, label, value) {
        return(
            <FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={value}
                            onChange={handleCheckedChange}
                            name={fieldName}
                            color="primary"
                        />
                    }
                    label={label}
                />
            </FormControl>
        )
    }

    function timeslotsFieldWrapper(fieldName, fieldLabel, value, calcDuration) {
        return(
            <TimeSlotsField 
                label={fieldLabel}
                onChange={(newValue) => setFieldValue(fieldName, newValue)}
                initialValues={value}
                calcDuration={calcDuration}
                duration={ calcDuration ? {
                    days:       activityDetails.data.duration_days,
                    nights:     activityDetails.data.duration_nights,
                    hours:      activityDetails.data.duration_hours,
                    minutes:    activityDetails.data.duration_minutes
                } : null}
            />
        )
    }

    function handleCheckedChange(event) {
        let name = event.target.name;
        let value = event.target.checked;
        setFieldValue(name, value);
    }

    function handleFieldChange(e) {
        setActivityDetails({ data: {...activityDetails.data, [e.target.name]: e.target.value}});
    }

    function setLangFieldValue(name, value) {
        var dataClone = _.cloneDeep(activityDetails);
        dataClone.data.lang[name] = value;
        if(!dataClone.data.lang.activity_id) {
            dataClone.data.lang.activity_id = {};
            // If activity ID is not set in the lang records, set it now
            let fieldKeys = Object.keys(value);
            for(let i=0; i<fieldKeys.length; i++) {
                dataClone.data.lang.activity_id[fieldKeys[i]] = activityDetails.data.activity_id;
            }
        }
        setActivityDetails(dataClone);
    }

    function setFieldValue(name, value) {
        setActivityDetails({ data: {...activityDetails.data, [name]: value}});
    }

    return(
        <div>
            <EditorLanguageConsumer>
                {({ language, updateLanguage }) => (
                    <form className={classes.container} noValidate autoComplete="off">
                        <Grid 
                            container
                            // direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={3}
                        >
                            <Grid item xs={11}>
                                {translateFieldWrapper('activity_name', 'Name', activityDetails.data.lang.activity_name, language)}
                            </Grid>

                            <Grid item xs={2}>
                                {textFieldWrapper('duration_days', 'Days',activityDetails.data.duration_days)}
                            </Grid>
                            <Grid item xs={2}>
                                {textFieldWrapper('duration_nights', 'Nights',activityDetails.data.duration_nights)}
                            </Grid>
                            <Grid item xs={2}>
                                {textFieldWrapper('duration_hours', 'Hours',activityDetails.data.duration_hours)}
                            </Grid>
                            <Grid item xs={2}>
                                {textFieldWrapper('duration_minutes', 'Minutes',activityDetails.data.duration_minutes)}
                            </Grid>

                            <Grid container item xs={11}>
                                {timeslotsFieldWrapper('schedule', 'Schedule', activityDetails.data.schedule, true)}
                            </Grid>
                            <Grid container item xs={11}>
                                {timeslotsFieldWrapper('pickup_dropoff', 'Pickup and Dropoff', activityDetails.data.pickup_dropoff, false)}
                            </Grid>
                            {/* <Grid item xs={11}>
                                {textFieldWrapper('start_time', 'Start Time', activityDetails.data.start_time)}
                            </Grid>
                            <Grid item xs={5}>
                                {textFieldWrapper('pickup_time', 'Pickup Time', activityDetails.data.pickup_time)}
                            </Grid>
                            <Grid item xs={5}>
                                {textFieldWrapper('dropoff_time', 'Dropoff Time', activityDetails.data.dropoff_time)}
                            </Grid>
                            <Grid item xs={5}>
                                {textFieldWrapper('start_location', 'Start Location', activityDetails.data.start_location)}
                            </Grid>
                            <Grid item xs={5}>
                                {textFieldWrapper('end_location', 'End Location', activityDetails.data.end_location)}
                            </Grid> */}
                            <Grid item xs={5}>
                                {checkboxWrapper('reconfirmation_req','Reconfirmation Required?', activityDetails.data.reconfirmation_req)}
                            </Grid>
                            <Grid item xs={5}>
                                {checkboxWrapper('supports_evoucher','Supports eVoucher?', activityDetails.data.supports_evoucher)}
                            </Grid>

                            <Grid item xs={11}>
                                {translateFieldWrapper('arrangement', 'Arrangement', activityDetails.data.lang.arrangement, language, true, 5)}
                            </Grid>
                            <Grid item xs={11}>
                                {translateFieldWrapper('attention', 'Attention', activityDetails.data.lang.attention, language, true, 10)}
                            </Grid>
                            <Grid item xs={11}>
                                {translateFieldWrapper('language', 'Language', activityDetails.data.lang.language, language, true, 10)}
                            </Grid>
                            <Grid item xs={11}>
                                {translateFieldWrapper('inclusions', 'Inclusions', activityDetails.data.lang.inclusions, language, true, 10)}
                            </Grid>
                            <Grid item xs={11}>
                                {translateFieldWrapper('exclusions', 'Exclusions', activityDetails.data.lang.exclusions, language, true, 10)}
                            </Grid>
                            <Grid item xs={11}>
                                {translateFieldWrapper('additional_description', 'Additional Description', activityDetails.data.lang.additional_description, language, true, 10)}
                            </Grid>
                            <Grid item xs={11}>
                                {translateFieldWrapper('cancellation', 'Cancellation', activityDetails.data.lang.cancellation, language, true, 10)}
                            </Grid>
                            <Grid container item xs={11} spacing={3}>
                                <AvailabilityDatesField 
                                    fieldName="schedule"
                                    // Uncomment on change so that it automatically saves instead of needing to click save on the dialog
                                    // created by this field
                                    // onChange={(newValue) => setFieldValue('schedule', newValue)}
                                    onSave={(newValue) => setFieldValue('schedule', newValue)}
                                    label="Availability"
                                    value={activityDetails.data.schedule}
                                />
                            </Grid>
                            {/* <Grid container item xs={5} spacing={3}>
                                {dateFieldWrapper('included_dates', 'Included Dates', activityDetails.data.included_dates)}
                            </Grid>
                            <Grid container item xs={5} spacing={3}>
                                {dateFieldWrapper('excluded_dates', 'Excluded Dates', activityDetails.data.excluded_dates)}
                            </Grid> */}
                            <Grid item xs={11}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={confirmSaveActivity}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={cancelSaveActivity}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </EditorLanguageConsumer>
        </div>
        
    )
}