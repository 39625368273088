import React, {useEffect, useState} from "react";
import _ from "lodash";

import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TranslateIcon from '@material-ui/icons/Translate';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

// import { buildURL } from '../../_helpers/url-builder';

export default function TranslateTextField (props) {
    const defaultLanguageOptions = {
      '1': 'English',
      '2': '简体中文'
    }
    const defaultEmptyValues = {
      '1': '',
      '2': ''
    }

    const { values, valueIndex, label, variant, margin, fullWidth, onChange, ...restProps } = props;
    // const [languageOptions, setLanguageOptions] = useState({options: defaultLanguageOptions, isFetching: false});
    const [translationValues, setTranslationValues] = useState( values || {});
    const [translationDialog, setTranslationDialog] = useState({open:false});

    useEffect(() => {
      setTranslationValues(values || {});
    }, [values])

    function updateTranslation(event) {
      updateLangEntry(event.target.value, valueIndex);

      // let valuesTemp = _.clone(translationValues);
      // valuesTemp[valueIndex] = event.target.value;
      // setTranslationValues(valuesTemp);
      // if (onChange) { onChange(valuesTemp); }
    }

    function updateLangEntry(newValue, language_id) {
      let valuesTemp = _.clone(translationValues);
      valuesTemp[language_id] = newValue;
      setTranslationValues(valuesTemp);
      if (onChange) { onChange(valuesTemp); }
    }

    function handleTranslationIconClick() {
      setTranslationDialog({open:true})
    }

    function handleTranslationDialogClose() {
      setTranslationDialog({open:false})
    }

    const translateFieldList = _.map({ ...defaultEmptyValues, ...values  }, (translation, language_id) => (
      <Grid container item 
        justify="space-around"
        alignItems="center"
        xs={12}
        key={language_id}
      >
        <Grid item xs={2}>
          {defaultLanguageOptions[language_id || 1]}:
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth>
            <TextField 
              variant={variant} 
              margin={margin}
              value={translation || ''}
              onChange={(event) => {
                updateLangEntry(event.target.value, language_id);
              }}
              {...restProps}
            />
        </FormControl>
        </Grid>
      </Grid>
    ));

    // useEffect(() => {
    //   const fetchLanguageOptions = async() => {
    //     try{
    //       let defaultOptions = returnLanguageOptions();
    //       setLanguageOptions({options: defaultOptions, isFetching: true});
          
    //       let url = buildURL('/api/config/validValues/for/language_id');
    //       let response = await axios.get(url);
    //       response = {
    //         data: returnLanguageOptions()
    //       }
    //       setLanguageOptions({options: response.data, isFetching: false});
    //     } catch(e) {
    //       console.log(e);
    //       let response = {
    //         data: returnLanguageOptions()
    //       }
    //       setLanguageOptions({options: response.data, isFetching: false});
    //     }
    //   };
    //   fetchLanguageOptions();
    // }, []);

    return (
      <span>
        <FormControl fullWidth={fullWidth} variant={variant} margin={margin}>
          {label ? (<InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>) : null}
          <OutlinedInput 
            // value={translationValues[valueIndex]} 
            value={translationValues[valueIndex] || ''} 
            onChange={updateTranslation}
            {...restProps}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTranslationIconClick}
                >
                  <TranslateIcon />
                </IconButton>
              </InputAdornment>
            } 
            labelWidth={120}
          />
        </FormControl>
        <Dialog
            fullWidth
            maxWidth="xl"
            open={translationDialog.open}
            onClose={handleTranslationDialogClose}
        >
          <DialogContent>
            {translateFieldList}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleTranslationDialogClose} color="primary">
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );

}