import React, { Component } from "react";
import {DropzoneArea} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import "./Home.css";
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import Container from '@material-ui/core/Container';

import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { withSnackbar } from 'notistack';

import { getURL } from '../_helpers/url-builder';
import { authHeader } from '../_helpers/auth-request';

function SimpleDialog(props) {
  const { onClose, selectedValue, open, handleUploadChange } = props;

  function handleClose() {
    onClose(selectedValue);
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Upload Files</DialogTitle>
      <DropzoneArea 
        dropzoneText="Drag and drop file here or click"
        acceptedFiles={['application/*']}
        onChange={handleUploadChange}
        showPreviewsInDropzone={true}
      />
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

class ImportProduct extends Component {
  tableRef = React.createRef();

  constructor(props){
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      dataRows: [],
      fileUploadDialogOpen: false
    };

    // this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.handleUploadChange = this.handleUploadChange.bind(this);
    this.handleSelectFileClick = this.handleSelectFileClick.bind(this);
    this.handleSelectFileClose = this.handleSelectFileClose.bind(this);
    this.handleMessageDisplay = this.handleMessageDisplay.bind(this);
  }

  handleMessageDisplay(message, variant)  {
    this.props.enqueueSnackbar(message, {variant});
  };

  handleSelectFileClick() {
    this.setState({
      fileUploadDialogOpen: true
    });
  }

  handleSelectFileClose() {
    this.setState({
      fileUploadDialogOpen: false
    });
  };

  handleUploadChange(files){
    this.setState({
      files: files
    });
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file, file.name);

      let url = getURL('import-product-file');
      let headers = authHeader();
      
      axios.post(url, formData, {
        headers,
        onUploadProgress: event => {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      })
        .then(res => {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = { state: "done", percentage: 100 };
          this.setState({ uploadProgress: copy, dataRows: res.data });
          // this.tableRef.current.onQueryChange();
          // console.log(res.data);
          resolve(res.statusText);
        })
        .catch(err => {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = { state: "error", percentage: 0 };
          this.setState({ uploadProgress: copy });
          reject(err.statusText);
          this.handleMessageDisplay(err.response.data.message, 'error');
        })
    });
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);
  
      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  render() {
    return (
        <Container component="main">
          <div className="Home">
            <div className="lander" style={{position: 'relative'}}>
              <DropzoneArea 
                  dropzoneText="Drag and drop file here or click"
                  acceptedFiles={['application/*']}
                  onChange={this.handleUploadChange}
                  showPreviewsInDropzone={true}
                />          
                <Button variant="contained" color="primary"
                  disabled={this.state.files.length < 0 || this.state.uploading}
                  onClick={this.uploadFiles}>
                  Upload
                </Button>
                {this.state.uploading && <div style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', zIndex: 11}}><LoadingSpinner /></div>}
            </div>
          </div>
        </Container>
    );
  }
}

export default withSnackbar(ImportProduct);
/*
            
            <SimpleDialog 
              open={this.state.fileUploadDialogOpen} 
              onClose={this.handleSelectFileClose} 
              handleUploadChange={this.handleUploadChange}
            />*/