import React, { useState } from "react";
import _ from "lodash";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAPI } from '../../_helpers/auth-request';
import { getURL } from '../../_helpers/url-builder';

const getAllKeywordsURL = getURL('get-all-keywords');

export function fromArrayToObjList(array) {
  return _.map(array, (v) => {return { keyword_id: v };});
}

export function fromObjListToArray(objList) {
  return _.map(objList, (o) => {return o.keyword_id});
}

export default function AsyncKeywordSelect(props) {
  const { language, onChange, value } = props;
  
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { callApiAsync } = useAPI();

  const [internalValue, setInternalValue] = useState([]);
  const loading = options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      let newValue = fromArrayToObjList(value);
      setInternalValue(newValue || []);
      return undefined;
    }

    (async () => {
      const response = await callApiAsync({
        method: 'get',
        url: getAllKeywordsURL
      })

      if (active) {
        setOptions(response.data);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [loading, value]);

  // React.useEffect(() => {
  //   setInternalValue(value || []);
  // }, [value])

  function handleChange(event, value) {
    let keywordIds = _.map(value, (v) => {return { keyword_id: v.keyword_id };})
    setInternalValue(keywordIds);
    let keywordArray = fromObjListToArray(keywordIds);
    if(onChange) { onChange(keywordArray); }
  }

  return (
    <Autocomplete
      multiple
      filterSelectedOptions={true}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={internalValue}
      onChange={handleChange}
      getOptionSelected={(option, value) => option.keyword_id === value.keyword_id}
      getOptionLabel={(option) => {
        if(options.length === 0) {
          return "";
        } else {
          let o = _.find(options, { keyword_id: option.keyword_id });
          // let o = _.find(options, { keyword_id: option });
          return o.lang.keyword_text[language] || "<NOT SET>";
        }
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Keywords"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}