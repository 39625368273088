import React, {useEffect, useState} from "react";
import axios from "axios";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const defaultOptions = [
    { key: 0, name: 'Not Set' },
]

export default function AsyncSelect (props) {
    const { initialValue, onChange, mapDataToOptions, optionsURL, value, ...restProps } = props;
    const [options, setOptions] = useState({data: [], isFetching: true});
    const [internalValue, setInternalValue] = useState(initialValue || 0);
    const loading = options.data.length === 0;

    function returnOptions() {
      return defaultOptions;
    }

    useEffect(() => {
      if(!loading) return undefined;

      const mapOptions = (values) => {
        if(mapDataToOptions) {
          return mapDataToOptions(values);
        } else {
          return values.map(value => {
            return {
              value: value.value || value.key,
              key: value.key,
              name: value.name
            }   
          })
        }
      }

      (async() => {
        try{
          let defaultOptions = mapOptions(returnOptions());
          setOptions({data: defaultOptions, isFetching: true});
          
          let response = await axios.get( optionsURL );
          let optionsData = response.data;
          optionsData = mapOptions(optionsData);
          setOptions({data: optionsData, isFetching: false});
        } catch(e) {
          console.log(e);
          let optionsData = mapOptions(returnOptions());
          setOptions({data: optionsData, isFetching: false});
        }
      })();
    }, [mapDataToOptions, optionsURL, loading]);

    function handleChange(event) {
      setInternalValue(event.target.value);
      if(onChange) { onChange(event); }
    }

    if(loading || options.isFetching) {
      return (
        <Select
          value=''
          {...restProps}
        />);
    } else {
      return (
        <Select
          value={internalValue || value || ''}
          onChange={handleChange}
          {...restProps}
        >
          {options.data.map( (option) => (
              <MenuItem value={option.value} key={option.key}>{option.name}</MenuItem>
            )
          )}
        </Select>
      );
    }

}