import React, { createContext } from 'react';

export const LanguageContext = createContext({
  language: 1,
  updateLanguage: () => {},
});

export class LanguageProvider extends React.Component {
  updateLanguage = newLanguage => {
    this.setState({ language: newLanguage });
  };

  state = {
    language: 1,
    updateLanguage: this.updateLanguage,
  };

  render() {
    return (
      <LanguageContext.Provider value={this.state}>
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

export const LanguageConsumer = LanguageContext.Consumer;