import { handleResponse } from '../_helpers/handle-response'
import { buildURL } from '../_helpers/url-builder';
import { callSecureApiAsync } from '../_helpers/auth-request';

export const authService = {
    login,
    logout,
    getIDToken,
    resetPassword
};

function getIDToken() {
    return localStorage.getItem('token');
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(buildURL(`/api/auth/login`), requestOptions)
        .then(handleResponse)
        .then(response => {
            if (response) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                localStorage.setItem('token', response.token);
            }

            return response.token;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
}

async function resetPassword(newPassword, confirmPassword) {
    if(newPassword !== confirmPassword) {
        return Promise.reject('Passwords do not match');
    } else {
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ new_password: newPassword, confirm_password: confirmPassword })
        // };

        
        const response = await callSecureApiAsync({
            method: 'post',
            url: buildURL(`/api/auth/reset/password`),
            data: { new_password: newPassword, confirm_password: confirmPassword }
        })
        // console.log(response.data);
        // console.log(response.data.token);
        localStorage.setItem('token', response.data.token);
        return response;

        // return fetch(buildURL(`/api/auth/reset/password`), requestOptions)
        //     .then(handleResponse)
        //     .then(response => {
        //         return response;
        //     });
    }
}