export function buildURL(endpoint) {
    let url = process.env.REACT_APP_API_URL || '';
    return url + endpoint;
}

const mediaURLs = {
    'product': (id) => {
        return `/api/media/get/for/product/${id}`
    },
    'merchant': (id) => {
        return `/api/media/get/for/merchant/${id}`
    },
    'category': (id) => {
        return `/api/media/get/for/category/${id}`
    },
    'product_pending': (id) => {
        return `/api/media/get/pending/for/product/${id}`
    }
}

export const apiURLs = {
    // Search
    'count-products-by-keywords': () => {
        return '/api/search/count/products/by/keywords';
    },

    // Keywords
    'get-all-keywords': () => {
        return '/api/keywords/get/all';
    },
    'save-keyword': () => {
        return '/api/keywords/save';
    },
    'remove-keyword': () => {
        return '/api/keywords/delete';
    },

    // Media
    'get-all-media': () => {
        return '/api/media/get/all';
    },
    'upload-media': () => {
        return '/api/media/upload';
    },
    'fetch-media': (id) => {
        return `/api/media/get/media/${id}`;
    },
    'update-media-link': (id) => {
        return `/api/media/${id}/update/link`;
    },

    // Category
    'get-categories': () => {
        return '/api/categories/data';
    },
    'get-category-details': (id) => {
        return `/api/categories/detail/${id}`;
    },
    'update-category': (id) => {
        return `/api/categories/update/${id}`;
    },
    'save-categories': () => {
        return `/api/categories/save`;
    },
    'save-category-featured-media': (id) => {
        return `/api/categories/set/featured/${id}`;
    },
    'save-category-banner-media': (id) => {
        return `/api/categories/set/banner/${id}`;
    },

    // Merchant
    'get-all-merchants': () => {
        return `/api/merchant/get/all`;
    },
    'add-new-merchant': () => {
        return '/api/merchant/add';
    },
    'save-merchant': (i) => {
        return `/api/merchant/save`;
    },
    'update-merchant': (id) => {
        return `/api/merchant/update/${id}`;
    },
    'remove-merchant': (id) => {
        return `/api/merchant/remove/${id}`;
    },
    'undo-remove-merchant': (id) => {
        return `/api/merchant/cancel_remove/${id}`;
    },
    'get-merchant-for-edit': (id) => {
        return `/api/merchant/get/merchant/${id}`;
    },

    // Product
    'get-all-products': () => {
        return '/api/product/get/all';
    },
    'get-product-for-edit': (id) => {
        return `/api/product/get/foredit/product/${id}`;
    },
    'get-staged-edit-for-edit': (id) => {
        return `/api/product/get/foredit/stagededit/${id}`;
    },
    'save-product': () => {
        return `/api/product/save_edit`;
    },
    'remove-product': (id) => {
        return `/api/product/remove/${id}`;
    },
    'undo-remove-product': (id) => {
        return `/api/product/cancel_remove/${id}`;
    },
    'update-product-visibility': (id) => {
        return `/api/product/update_visibility/${id}`;
    },
    'get-all-staged-edits': () => {
        return '/api/product/get/staged_edits';
    },
    'remove-staged-edit': (id) => {
        return `/api/product/remove_edit/${id}`;
    },
    'get-staged-and-product-diff': (ids) => {
        return `/api/product/diff/staged/${ids.edit_stage_id}/to/${ids.product_id}`;
    },
    'get-staged-edit': (id) => {
        return `/api/product/get/staged_edit/${id}`;
    },
    'confirm-staged-edit': () => {
        return `/api/product/confirm_edit`;
    },
    'confirm-product-removal': () => {
        return `/api/product/confirm_removal`;
    },
    'get-new-activity-id': () => {
        return `/api/product/get/new/activity_id`;
    },
    'export-products': () => {
        return `/api/product/export/all`;
    },

    // Activity
    'get-activity': (id) => {
        return `/api/product/get/activity/${id}`;
    },

    // Activity Pricing
    'get-activity-pricing': (id) => {
        return `/api/product/get/activity/${id}/pricing`;
    },

    // Config
    'get-server-info': () => {
        return `/api/config/server/info`;
    },

    // Data
    'import-product-file': () => {
        return '/api/data/import';
    },
    'get-pending-import': () => {
        return '/api/import/get/pending';
    },
    'commit-import-file': () => {
        return '/api/import/commit/data/from/file';
    },
    'remove-import-file': () => {
        return '/api/import/remove/data/from/file';
    },
    'get-warnings-for-file': (fileName) => {
        return `/api/import/get/warnings/${fileName}`;
    },
    'get-errors-for-file': (fileName) => {
        return `/api/import/get/errors/${fileName}`;
    },
    'get-conversion-config': () => {
        return '/api/data/get/conversion/config';
    },
    'update-conversion-data': () => {
        return '/api/data/conversion/update';
    },
    'save-conversion-data': () => {
        return '/api/data/conversion/save/new';
    },
    'delete-conversion-data': () => {
        return '/api/data/conversion/delete';
    },

    // User
    'edit-user': () => {
        return '/api/user/edituser';
    },
    'add-new-user': () => {
        return '/api/user/adduser';
    },
    'list-users': () => {
        return '/api/user/listusers';
    },
    'delete-user': () => {
        return '/api/user/deleteuser';
    },

    // Lists
    'language-options': () => {
        return '/api/config/validValues/for/language_id';
    },
    'country-options': () => {
        return '/api/config/list/countries';
    },

    // Data Integrity
    'translation-issues': () => {
        return '/api/integrity/translation';
    },
    'schedule-issues': () => {
        return '/api/integrity/schedule/all';
    },
    'schedule-issues-product': (id) => {
        return `/schedule/for/product/${id}`;
    },

}

export function buildMediaURL(object, objectId) {
    return buildURL(mediaURLs[object](objectId));
}

export function getURL(urlId, options) {
    if(apiURLs[urlId]) {
        return buildURL(apiURLs[urlId](options));
    } else {
        return '';
    }
}