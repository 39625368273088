import React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export default function UploadMediaPreview(props) {
    const {imageURL, name, className, open, onConfirm, onCancel} = props;

    function renderUploadPreview() {
        if(open) {
            return(
                <img 
                    src={imageURL} 
                    alt={name} 
                    className={className}
                />
            )
        } else {
            return null
        }
        
    }

    return (
        <Dialog
            maxWidth="xl"
            open={open}
            onClose={onCancel}
        >
            <DialogContent>
                {renderUploadPreview()}
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} color="primary">
                    Confirm 
                </Button>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}