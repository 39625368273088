import React, { useContext } from 'react';
import _ from "lodash";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PublishIcon from '@material-ui/icons/Publish';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import PeopleIcon from '@material-ui/icons/People';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SettingsIcon from '@material-ui/icons/Settings';
import WebIcon from '@material-ui/icons/Web';
import ImageIcon from '@material-ui/icons/Image';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { getRolesFromToken } from '../contexts/auth-context';
import { AppVersionContext } from '../contexts/app-version-context';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  version: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

export default function AppDrawer(props) {
  const { open, handleDrawerClose, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { version } = useContext(AppVersionContext);

  if(other) {
    
  }
  // function handleDrawerOpen() {
  //   setOpen(true);
  // }

  // function handleDrawerClose() {
  //   setOpen(false);
  // }

  function listItem(name, to, roles, icon) {
    let userRoles = getRolesFromToken();
    let routeRoles = roles;

    if (typeof routeRoles === 'string') {
      if(routeRoles.length > 0) {
        routeRoles = [routeRoles];
      } else {
        routeRoles = null;
      }
    }

    let foundRole = _.find(routeRoles, (role) => {
      return _.find(userRoles, (userRole) => userRole === role);
    });

    if(!routeRoles || foundRole) {
      return(
        <ListItem button
          component={Link}
          to={to}
          onClick={handleDrawerClose}
        >
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={name} />
        </ListItem>
      )
    } else {
      return (null)
    }
  }

  return (
    <div>
      <Drawer 
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {listItem('Products', '/products', '', <FolderSpecialIcon />)}
          {listItem('Category Management', '/CategoryManagement', '', <WebIcon />)}
          {listItem('Keyword Management', '/KeywordManagement', '', <WebIcon />)}
        </List>
        <Divider />
        <List>
          {listItem('Import Product', '/import_products', ['Admin','Editor','Reviewer'], <PublishIcon />)}
          {listItem('Import Review', '/import_review', ['Admin','Editor','Reviewer'], <PublishIcon />)}
          {listItem('Media Management', '/MediaManager', ['Admin'], <ImageIcon />)}
          {listItem('Data Integrity', '/DataIntegrity', ['Admin','Editor','Reviewer'], <AssignmentTurnedInIcon />)}
        </List>
        <Divider />
        <List>
          {listItem('Review Product Edits', '/review_products', ['Admin', 'Editor', 'Reviewer'], <RateReviewIcon />)}
        </List>
        <Divider />
        <List>
          {listItem('Merchants', '/merchants', '', <FolderSpecialIcon />)}
        </List>
        <Divider />
        <List>
          {listItem('Product Conversion Configuration', '/ProductConversion', ['Admin'], <PeopleIcon />)}
          {listItem('Manage Users', '/Users', ['Admin'], <PeopleIcon />)}
          {listItem('Admin', '/Admin', ['Admin'], <SettingsIcon />)}
        </List>
        <Typography variant="caption" display="block" className={classes.version} gutterBottom>
          v{version}
        </Typography>
      </Drawer>
    </div>
  );
}

