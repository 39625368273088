import React, {useState, useEffect} from "react";

import _ from "lodash";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import MediaManager from '../../containers/MediaManager';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#eeeeee',
    margin: theme.spacing(1)
  },
  noDates: {
    fontWeight: "bold"
  },
  date: {
    display: "block"
  }
}));


export default function AssociatedMediaField (props) {
    const classes = useStyles();
    const { label, value, onChange, onSave, mediaDisplayComponent, listURL, removeURL, addURL, saveURL, allowUpdateLinks } = props;

    const [editMediaDialog, setEditMediaDialog] = useState({ open:false })
    const [associatedMedia, setAssociatedMedia] = useState([]);

    useEffect(() => {
      setAssociatedMedia(value);
    }, [value]);

    function handleEditMediaDialogClose() {
      setEditMediaDialog({open:false})
    }

    function handleEditMediaDialogSave() {
      setEditMediaDialog({open:false})
      if(onSave) onSave(associatedMedia);
    }

    function handleEditMediaDialogCancel() {
      setEditMediaDialog({open:false})
    }

    function handleEditMediaClick() {
      setEditMediaDialog({open:true})
    }

    function handleMediaChange(newValue) {
      const mediaIds = _.map(newValue, m => m.media_id);
      if (onChange) { 
        // Controlled component, update through onChange funtion and parent should then update the value
        onChange(mediaIds)
      } else {
        // Uncontrolled, set our state ourselves
        setAssociatedMedia(mediaIds);
      }
    }

    function renderInnerComponent() {
      if(mediaDisplayComponent) {
        return (<mediaDisplayComponent dateValues={associatedMedia}/>)
      } else if(associatedMedia && associatedMedia.length > 0) {
        return (
          <span className={classes.noDates}>{associatedMedia.length} media item(s) associated - Click Edit to view modification options</span>
        )
      } else {
        return (
          <span className={classes.noDates}>No media items associated - Click Edit to view modification options</span>
        )
      }
    }

    return (
      <Grid container item
          direction="column"
      >
          <Grid item>
              {label}
          </Grid>
          <Grid container item 
            justify="space-between"
            alignItems="center"
            className={classes.container} 
          >
            <Grid item xs={9}>
                {renderInnerComponent()}
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    // className={classes.button}
                    startIcon={<ImageIcon />}
                    onClick={handleEditMediaClick}
                >
                    Edit Media
                </Button>
            </Grid>
            <Dialog
                maxWidth="xl"
                open={editMediaDialog.open}
                onClose={handleEditMediaDialogClose}
            >
              <DialogContent>
                <MediaManager
                  onChange={handleMediaChange}
                  onSave={onSave}
                  listURL={listURL}
                  removeURL={removeURL}
                  addURL={addURL}
                  saveURL={saveURL}
                  allowUpdateLinks={allowUpdateLinks}
                />
              </DialogContent>
              <DialogActions>
                {!saveURL 
                  ?
                  (
                    <span>
                      <Button onClick={handleEditMediaDialogSave} color="primary">
                        Save
                      </Button>
                      <Button onClick={handleEditMediaDialogCancel} color="primary">
                        Cancel
                      </Button>
                    </span>
                  )
                  :
                  (
                    <Button onClick={handleEditMediaDialogClose} color="primary">
                      Close
                    </Button>
                  )
                }
                
              </DialogActions>
            </Dialog>
          </Grid>
      </Grid>
    );

}