import React, { createContext } from 'react';

// const defaultLanguageOptions = [
//   { id: 1, value: 'English' },
//   { id: 2, value: '简体中文' }
// ]
export const EditorLanguageContext = createContext({
  language: 1,
  updateLanguage: () => {},
});

export class EditorLanguageProvider extends React.Component {
  updateLanguage = newLanguage => {
    this.setState({ language: newLanguage });
  };

  state = {
    language: this.props.initialValue || 1,
    updateLanguage: this.updateLanguage,
  };

  render() {
    return (
      <EditorLanguageContext.Provider value={this.state}>
        {this.props.children}
      </EditorLanguageContext.Provider>
    );
  }
}

export const EditorLanguageConsumer = EditorLanguageContext.Consumer;