import React from "react";

import StarhopSpinner from "../img/Compass_Rotation_Animation_2.gif"

export default function LoadingSpinner (props) {
  const { className, alpha, ...other } = props;

  let styleAlpha = alpha || 0.7;

    return (
        <div
            style={{
                display: "table",
                width: "100%",
                height: "100%",
                backgroundColor: `rgba(255, 255, 255, ${styleAlpha})`,
            }}
        >
            <div
                style={{
                    display: "table-cell",
                    width: "100%",
                    height: "100%",
                    verticalAlign: "middle",
                    textAlign: "center",
                }}
            >
                <div
                    style={{
                        width: "100px",
                        height: "100px",
                        margin: "auto",
                    }}
                >
                    <img
                        src={StarhopSpinner}
                        alt="Starhoptour Spinner"
                        className={className}
                        {...other}
                        style={{
                            width: "100px",
                            height: "100px",
                        }}
                    />
                </div>
            </div>
        </div>
    );

}