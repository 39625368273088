import React, { useEffect, useState } from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export default function AppVersionNotification(props) {
    const { show } = props;
    const [ showDialog, setShowDialog ] = useState(false);

    useEffect(() => {
        setShowDialog(show);
    }, [show]);

    function handleRefreshClick() {
        window.location.reload();
    }

    return (
        <Dialog
            maxWidth="xl"
            open={showDialog}
            disableBackdropClick
            disableEscapeKeyDown
        > 
            <DialogContent>
            Current running version of the client is outdated , please refresh your page
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRefreshClick} color="primary">
                    Refresh
                </Button>
            </DialogActions>
        </Dialog>
    )
}