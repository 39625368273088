import React, { useContext, useState } from 'react';
import _ from "lodash";
import clsx from 'clsx';
import { useHistory } from "react-router-dom";

import LoadingOverlay from 'react-loading-overlay';
import { useSnackbar } from 'notistack';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import { formatMoney, formatPercent } from '../_helpers/numbers';

import LoadingSpinner from '../components/LoadingSpinner';
import WeekDaysSelector from '../components/WeekDaysSelector';

import { LanguageContext } from '../contexts/language-context';

import { getURL, buildURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';

let confirmEditURL = getURL('confirm-staged-edit');
let confirmRemovalURL = getURL('confirm-product-removal');

const DATE_MODES = {
    SINGLE: 1,
    RANGE: 2
}

const DEFAULT_MAX_DIMS = {
    maxHeight: 150, 
    maxWidth: 200
}

const transparentBorder = {
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderWidth: '1px',
}

const blackBorder = {
    borderColor: '#000000',
    borderStyle: 'solid',
    borderWidth: '1px'
}

const useStyles = makeStyles(theme => ({
    editN: { // New
        backgroundColor: green['200'],
        ...transparentBorder,
        '&:hover': blackBorder
    },
    editD: { // Delete
        backgroundColor: red['300'],
        ...transparentBorder,
        '&:hover': blackBorder
    },
    editE: { // Edit
        backgroundColor: orange['200'],
        ...transparentBorder,
        '&:hover': blackBorder
    },
    editA: { // Array Change
        backgroundColor: yellow['200'],
        ...transparentBorder,
        '&:hover': blackBorder
    },
    deleteButton: {
        backgroundColor: red['700'],
        color: red['50'],
        '&:hover': {
            backgroundColor: red['900']
        }
    },

    button: {
        margin: theme.spacing(1),
    },
    itinerary_section_title: {
        width: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        background: '#acd1ec'
    },
    product_container: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    activity_container: {
        border: '2px',
        borderStyle: 'solid',
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    noMedia: {
      fontWeight: "bold"
    },
    container: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    fieldLabel: {
        fontWeight: 'bold'
    },
    noDates: {
        fontWeight: 'bold',
        color: red['700']
    }
}));

const DiffStates = [
    { id: 1, name: 'Show All' },
    { id: 2, name: 'Show Differences Only' }
]

export default function ReviewProductView(props) {
    const classes = useStyles();
    const { callApiAsync } = useAPI();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { productData, newProduct, removeProduct, editStageID } = props;
    const { language } = useContext(LanguageContext);

    const [diffMode, setDiffMode] = useState(1);
    const [loading, setLoading] = useState(false);

    let defaultFieldComponent = (options) => {
        let variant = (options && options.variant) ? options.variant : "h5";
        return (value) => {
            return (<Typography variant={variant}>{value}</Typography>)
        }
    }

    let mediaArrayComponent = (mediaArray) => {
        if(mediaArray && mediaArray.length > 0) {
            return (
                <Grid item container spacing={1}>
                    {mediaArray.map(media => {
                        return (
                            <Grid item key={media.media_id}>
                                <img alt={media.media_id} src={buildURL(`/images/product/${media.file_path}`)} style={DEFAULT_MAX_DIMS}></img>
                            </Grid>
                        )
                    })}
                </Grid>
            )
        } else {
            return (<span className={classes.noMedia}>No media set</span>)
        }
    }

    function renderRange(min, max) {
        if(!_.isUndefined(min) && !_.isUndefined(max) && !_.isNull(min) && !_.isNull(max)) {
            if( min === max) {
                return `Exactly ${min}`
            } else {
                return `${min} - ${max}`
            }
        } else if ((_.isUndefined(min) || _.isNull(min)) && (_.isUndefined(max) || _.isNull(max))) {
            return `Any`
        } else if(_.isUndefined(min) || _.isNull(min)) {
            return `<${max}`
        } else if(_.isUndefined(max) || _.isNull(max)) {
            return `${min}+`
        }
    }

    function renderDateObj(dateObj) {
        if(dateObj.dateMode === DATE_MODES.SINGLE) {
            return dateObj.value;
        } else if(dateObj.dateMode === DATE_MODES.RANGE) {
            return `${dateObj.value.start} - ${dateObj.value.end}`;
        } else {
            return '';
        }
    }

    function renderScheduleDates(sched) {
        if((!sched.included_dates || (sched.included_dates && sched.included_dates.length === 0)) && (!sched.excluded_dates || (sched.excluded_dates && sched.excluded_dates.length === 0))) {
            return (
                <Grid container item sm={12}>
                    <Grid container item direction="column" sm={10}>
                        <span className={classes.noDates}>No Dates Set</span>
                    </Grid>
                    {/* Available Days */}
                    <Grid item sm={2}>
                        <WeekDaysSelector value={ sched.avail_days } viewMode={true} miniMode={true} />
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid container item sm={12}>
                    {/* Included Dates */}
                    <Grid container item direction="column" sm={5}>
                        <Grid item>Included Dates</Grid>
                    {sched.included_dates && _.isArray(sched.included_dates) && sched.included_dates.map(date => {
                        return(
                            <Grid item key={date.id}>{renderDateObj(date)}</Grid>
                        )
                    })}
                    {!_.isNull(sched.included_dates) && !_.isUndefined(sched.included_dates) && !_.isArray(sched.included_dates) && <Grid item>Error displaying schedule data</Grid>}
                    </Grid>
                    {/* Excluded Dates */}
                    <Grid container item direction="column" sm={5}>
                        <Grid item>Excluded Dates</Grid>
                    {sched.excluded_dates && _.isArray(sched.excluded_dates) && sched.excluded_dates.map(date => {
                        return(
                            <Grid item key={date.id}>{renderDateObj(date)}</Grid>
                        )
                    })}
                    {!_.isNull(sched.excluded_dates) && !_.isUndefined(sched.excluded_dates) && !_.isArray(sched.excluded_dates) && <Grid item>Error displaying schedule data</Grid>}
                    </Grid>
                    {/* Available Days */}
                    <Grid item sm={2}>
                        <WeekDaysSelector value={ sched.avail_days } viewMode={true} miniMode={true} />
                    </Grid>
                </Grid>
            )
        }
    }

    let scheduleComponent = (schedule) => {
        if(schedule) {
            return (
                <span>
                    {schedule.length ? schedule.map(sched => {
                        return (
                            <Grid container item sm={12} key={sched.id}>
                                {renderScheduleDates(sched)}
                            </Grid>
                        )
                    }) : (<span className={classes.noMedia}>No schedule set</span>)}
                </span>
            )
        } else {
            return null;
        }
    }

    let pricingRowComponent = (pricingRow, options) => {
        if(pricingRow) {
            return(
                <Grid container item spacing={1}>
                    <Grid item sm={6}>
                        <span className={classes.fieldLabel}>Age Range:</span> {renderRange(pricingRow.age_min, pricingRow.age_max)}
                    </Grid>
                    <Grid item sm={6}>
                        <span className={classes.fieldLabel}>Pax Range:</span> {renderRange(pricingRow.pax_min, pricingRow.pax_max)}
                    </Grid>
                    {pricingRow.schedule.length ? pricingRow.schedule.map(sched => {
                        return ( 
                            <Grid container item sm={12} key={sched.id}>
                                {renderScheduleDates(sched)}
                                <Grid container item sm={12}>
                                    <Grid item sm={2}>Retail Price</Grid>
                                    <Grid item sm={2}>List Price</Grid>
                                    <Grid item sm={2}>Service Rate</Grid>
                                    <Grid item sm={2}>Net Price</Grid>
                                    <Grid item sm={2}>Currency</Grid>
                                    <Grid item sm={2}>Stock Qty</Grid>
                                </Grid>
                                <Grid container item sm={12}>
                                    <Grid item sm={2}>{formatMoney(sched.retail_price)}</Grid>
                                    <Grid item sm={2}>{formatMoney(sched.list_price)}</Grid>
                                    <Grid item sm={2}>{formatPercent(sched.service_rate)}</Grid>
                                    <Grid item sm={2}>{formatMoney(sched.net_price)}</Grid>
                                    <Grid item sm={2}>{sched.currency_code}</Grid>
                                    <Grid item sm={2}>{sched.stock_qty}</Grid>
                                </Grid>
                            </Grid> 
                        )
                    }) : (<span className={classes.noMedia}>No schedule set</span>)}
                </Grid>
            )
        } else {
            return(null)
        }
    }

    function showAllFields() {
        return diffMode.toString() === '1';
    }

    function buildBulletPoints(showBulletPoints) {
        if (showBulletPoints) {
            return (value) => {return value ? (
                <div>
                    <ul>
                        {value.replace(/\r?\n|\r/gm,'\n').split('\n').map((item, i) => <li key={i}><Typography variant="body2" gutterBottom key={i}>{item.length ? item : '\u00A0'}</Typography></li>)}
                    </ul>
                </div>
            ) : ''}
        } else {
            return (value) => {return value ? (
                <div>
                    {value.replace(/\r?\n|\r/gm,'\n').split('\r\n').map((item, i) => <Typography variant="body2" gutterBottom key={i}>{item.length ? item : '\u00A0'}</Typography>)}
                </div>
            ) : '' }
        }
    }

    let fieldComponent = (options) => {
        let valueRenderFn = (options && options.valueRenderFn) ? options.valueRenderFn : defaultFieldComponent(options);
        return (value) => {
            return valueRenderFn(value)
        }
    }

    async function handleCommitEdit() {
        setLoading(true);
        try {
            const response = await callApiAsync({
                url: confirmEditURL,
                method: 'post',
                data: { product_data: productData.edit, edit_stage_id: editStageID }
            });
            setLoading(false);
            if(response.status === 200) {
                // Save worked, route to the review page
                history.push("/review_products");
                console.log(`Changes saved`);
            } else {
                // Save did not work, show message
                console.log(`Changes save failed`);
                enqueueSnackbar('There was an issue committing changes, please try again',  { variant: 'error' });
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            enqueueSnackbar('There was an issue committing changes, please try again',  { variant: 'error' });
        }
    }

    async function handleConfirmRemoval() {
        setLoading(true);
        try {
            const response = await callApiAsync({
                url: confirmRemovalURL,
                method: 'post',
                data: { product_data: productData.edit, edit_stage_id: editStageID }
            });
            setLoading(false);
            if(response.status === 200) {
                // Save worked, route to the review page
                history.push("/review_products");
                console.log(`Changes saved`);
            } else {
                // Save did not work, show message
                console.log(`Changes save failed`);
                enqueueSnackbar('There was an issue committing changes, please try again',  { variant: 'error' });
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            enqueueSnackbar('There was an issue committing changes, please try again',  { variant: 'error' });
        }
    }

    async function handleCancelEdit() {
        setLoading(true);
        try {
            let edit_stage_id = editStageID;
            let url = getURL('remove-staged-edit', edit_stage_id);
            const response = await callApiAsync({
              method: 'post',
              url
            })
            setLoading(false);
            if(response.status === 200) {
                // Save worked, route to the review page
                history.push("/review_products");
                console.log(`Changes saved`);
            } else {
                // Save did not work, show message
                console.log(`Changes save failed`);
                enqueueSnackbar('There was an issue committing changes, please try again',  { variant: 'error' });
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            enqueueSnackbar('There was an issue committing changes, please try again',  { variant: 'error' });
        }
    }

    function showValue(value, options) {
        if(options.type === 'numeric') {
            return (value || 0).toString();
        }
        if(options.type === 'array') {
            // Confirm that the value is actually an array to avoid any errors
            if(_.isArray(value)) {
                return value.join(', ');
            }
        }

        return value;
    }

    function renderDualField(options) {
        // let editDataEq = _.find(productData.diff, d => _.isEqual(d.path, options.path));
        let editData = _.find(productData.diff, { path: options.path });

        if(showAllFields() || editData || options.force) {
            let productField = productData.product;
            let editField = productData.edit;
            let editKind = editData ? editData.kind : null;
            
            for(let i=0; i<options.path.length; i++) {
                var k = options.path[i];
                if (productField != null && k in productField) {
                    productField = productField[k];
                } else {
                    // Value not found at path specified, set the field to null
                    productField = null;
                }

                if (editField != null && k in editField) {
                    editField = editField[k];
                } else {
                    // Value not found at path specified, set the field to null
                    editField = null;
                }
            }

            if(options.language && editKind) {
                // Since we flag the edit regardless of language, confirm that the viewed language matches
                // the diff findings and set editKind to null if the language does not match the diff path
                editKind = editData.path[editData.path.length-1] === options.language.toString() ? editKind : null;
            }

            if(options.language) {
                return renderDualFieldValues({
                    left:  productField ? productField[options.language] : '', 
                    right: editField    ? editField[options.language]    : '', 
                    editKind, 
                    variant: options.variant,
                    fieldFn: options.fieldFn
                });
            } else {
                return renderDualFieldValues({
                    left:   options.showValueFn ? options.showValueFn(productField, options) : showValue(productField, options), 
                    right:  options.showValueFn ? options.showValueFn(editField, options)    : showValue(editField   , options), 
                    editKind, 
                    variant: options.variant,
                    fieldFn: options.fieldFn
                });
            }
        }
    }

    function renderDualFieldValues(options) {
        let className = options.editKind ? classes[`edit${options.editKind}`] : null;
        let variant = (options && options.variant) ? options.variant : "h5";
        let fieldFn = options.fieldFn ? options.fieldFn : fieldComponent({ variant });
        return (
            <Grid container item className={className} justify="space-evenly">
                <Grid item sm={5}>
                    {fieldFn(options.left, options)}
                </Grid>
                <Grid item sm={5}>
                    {fieldFn(options.right, options)}
                </Grid>
            </Grid>
        )
    }

    function renderSingleField(options) {
        let editField = productData.edit;
        let editData = _.find(productData.diff, { path: options.path });
        let editKind = editData ? editData.kind : null;
        
        for(let i=0; i<options.path.length; i++) {
            var k = options.path[i];
            if (editField != null && k in editField) {
                editField = editField[k];
            } else {
                // Value not found at path specified, set the field to null
                editField = null;
            }
        }

        if(options.language) {
            return renderSingleFieldValues({
                right: editField ? editField[options.language] : '', 
                editKind, 
                variant: options.variant,
                fieldFn: options.fieldFn
            });
        } else {
            return renderSingleFieldValues({
                right: options.showValueFn ? options.showValueFn(editField, options) : showValue(editField   , options), 
                editKind, 
                variant: options.variant,
                fieldFn: options.fieldFn
            });
        }
    }

    function renderSingleFieldValues(options) {
        let variant = (options && options.variant) ? options.variant : "h5";
        let fieldFn = options.fieldFn ? options.fieldFn : fieldComponent({ variant });
        return (
            <Grid item sm={10}>
                {fieldFn(options.right, options)}
            </Grid>
        )
    }

    function showField(options) {
        if(!newProduct) {
            return renderDualField(options);
        } else {
            return renderSingleField(options);
        }
    }

    function handleDiffModeChange(event) {
        setDiffMode(event.target.value);
    }

    function buildDurationString(activity) {
        if(!activity) {
            return '';
        }

        let { duration_days, duration_nights, duration_hours, duration_minutes } = activity;
        if(duration_days && duration_nights) {
            return duration_days +"D"+ duration_nights +"N";
        } else {
            let daysPart = (duration_days > 1) ? duration_days+" days" : ((duration_days > 0) ? duration_days+" day" : "");
            let hoursPart = (duration_hours > 1) ? duration_hours+" hours" : ((duration_hours > 0) ? duration_hours+" hour" : "");
            let minutesPart = (duration_minutes > 1) ? duration_minutes+" minutes" : ((duration_minutes > 0) ? duration_minutes+" minute" : "");
        
            let combString = daysPart +" "+ hoursPart +" "+ minutesPart;
            
            return combString;
        }
    }

    function renderActivity(index, language) {
        return(
            <Grid container item spacing={1}>
                {showField({
                    path: ['t_activity', index, 'lang', 'activity_name'], 
                    variant: 'subtitle2',
                    force: true, 
                    language
                })}
                {showField({
                    path: ['t_activity', index, 'activity_id'], 
                    variant: 'subtitle2',
                    showValueFn: (value) => value ? `(Activity ID: ${value})` : '',
                })}
                <Typography variant="subtitle2" className={classes.itinerary_section_title} >Duration</Typography>
                {showField({
                    path: ['t_activity', index],
                    variant: 'body2',
                    showValueFn: (activity) => buildDurationString(activity),
                })}
                <Typography variant="subtitle2" className={classes.itinerary_section_title} >Activity Arrangement</Typography>
                {showField({
                    path: ['t_activity', index, 'lang', 'arrangement'], 
                    language,
                    fieldFn: fieldComponent({ valueRenderFn: buildBulletPoints(true) })
                })}
                <Typography variant="subtitle2" className={classes.itinerary_section_title} >Inclusions</Typography>
                {showField({
                    path: ['t_activity', index, 'lang', 'inclusions'], 
                    language,
                    fieldFn: fieldComponent({ valueRenderFn: buildBulletPoints(true) })
                })}
                <Typography variant="subtitle2" className={classes.itinerary_section_title} >Exclusions</Typography>
                {showField({
                    path: ['t_activity', index, 'lang', 'exclusions'], 
                    language,
                    fieldFn: fieldComponent({ valueRenderFn: buildBulletPoints(true) })
                })}
                <Typography variant="subtitle2" className={classes.itinerary_section_title} >Attention Items</Typography>
                {showField({
                    path: ['t_activity', index, 'lang', 'attention'], 
                    language,
                    fieldFn: fieldComponent({ valueRenderFn: buildBulletPoints(true) })
                })}
                <Typography variant="subtitle2" className={classes.itinerary_section_title} >Change and Cancellation</Typography>
                {showField({
                    path: ['t_activity', index, 'lang', 'cancellation'], 
                    language,
                    fieldFn: fieldComponent({ valueRenderFn: buildBulletPoints(true) })
                })}
                <Typography variant="subtitle2" className={classes.itinerary_section_title} >Schedule</Typography>
                {showField({
                    path: ['t_activity', index, 'schedule'], 
                    variant: 'subtitle2',
                    showValueFn: (value) => value,
                    fieldFn: scheduleComponent,
                })}
            </Grid>
        )
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner={<LoadingSpinner alpha={'0'}/>}
            text='Committing Changes ...'
        >
            <Grid container className={classes.container}>
                <Grid container item spacing={3} justify="center" className={classes.diffHeader}>
                    {!newProduct && (
                        <Grid item>
                            <FormControl>
                                <InputLabel htmlFor="diff-show-native-helper">Mode</InputLabel>
                                <NativeSelect
                                    value={diffMode}
                                    onChange={handleDiffModeChange}
                                    inputProps={{
                                        name: 'diff-show',
                                        id: 'diff-show-native-helper',
                                    }}
                                >
                                    {DiffStates.map((diffState) => {
                                        return(<option value={diffState.id} key={diffState.id}>{diffState.name}</option>)
                                    })}
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                    )}            
                    <Grid item>
                        <Button 
                            autoFocus
                            variant="contained"
                            color={removeProduct ? "" : "primary"}
                            onClick={removeProduct ? handleConfirmRemoval : handleCommitEdit}
                            className={removeProduct ? classes.deleteButton : null}
                        >
                            {removeProduct ? "Confirm Removal" : " Commit Changes"}
                        </Button>       
                    </Grid>
                    <Grid item>
                        <Button 
                            autoFocus
                            variant="contained"
                            color="primary"
                            href="/review_products"
                        >
                            Return to Review
                        </Button> 
                    </Grid>
                    <Grid item>
                        <Button 
                            autoFocus
                            variant="contained"
                            color="primary"
                            onClick={handleCancelEdit}
                        >
                            Cancel Edit
                        </Button> 
                    </Grid>
                </Grid>
                <Grid container item spacing={1} className={classes.product_container}>
                    {showField({
                        path: ['lang', 'product_name'], 
                        variant: 'h5', 
                        force: true, 
                        language,
                        displayName: 'Product Name'
                    })}
                    {showField({
                        path: ['product_id'], 
                        variant: 'subtitle2',
                        showValueFn: (value) => value ? `(Product ID: ${value})` : '',
                    })}
                    {showField({
                        path: ['assoc_media'], 
                        variant: 'body1',
                        showValueFn: (value) => _.map(value, v => _.find(productData.lookups.media, { media_id: v })),
                        fieldFn: mediaArrayComponent,
                        type: 'array',
                        lookupId: 'media'
                    })}
                    {showField({
                        path: ['product_code'], 
                        variant: 'body1'
                    })}
                    {showField({
                        path: ['country_code'], 
                        variant: 'body1',
                        showValueFn: (value) => {
                            let lookupEntry = _.find(productData.lookups.countries, { country_code: value });
                            return lookupEntry ? lookupEntry.name : '';
                        }
                    })}
                    {showField({
                        path: ['district_code'], 
                        variant: 'body1'
                    })}
                    {showField({
                        path: ['keywords'], 
                        variant: 'body1',
                        showValueFn: (value) => {
                            let lookupValues = _.map(value, v => {
                                let entry = _.find(productData.lookups.keywords, { keyword_id: v })
                                return entry.lang.keyword_text[language];
                            });
                            return lookupValues.join(', ');
                        },
                        type: 'array',
                        lookupId: 'keywords'
                    })}
                    {showField({
                        path: ['lang', 'major_attraction'], 
                        variant: 'body1', 
                        language
                    })}
                    {showField({
                        path: ['lang', 'depart_from'], 
                        variant: 'body1', 
                        language
                    })}
                    {showField({
                        path: ['lang', 'slogan'], 
                        variant: 'h5', 
                        language
                    })}
                    {showField({
                        path: ['lang', 'overview'], 
                        language,
                        fieldFn: fieldComponent({ valueRenderFn: buildBulletPoints(false) })
                    })}
                    {showField({
                        path: ['schedule'], 
                        variant: 'subtitle2',
                        showValueFn: (value) => value,
                        fieldFn: scheduleComponent,
                    })}
                    {/* Render dates information */}
                    {/* Iterate thru activities from products, then any additional activities from edit */}
                    {(() => {
                        // Show new activities (kind = 'N')
                        let addedActivities = _.filter(productData.diff, (d) => _.isEqual(d.path, ['t_activity']) && d.item.kind === 'N');
                        return addedActivities.map(a => {
                            let index = a.index;
                            let activity = productData.edit.t_activity[index];
                            return (
                                <Grid container item spacing={1} key={activity.activity_id} className={clsx(classes.activity_container, classes.editN)}>
                                    {renderActivity(index, language)}
                                    <Typography variant="subtitle2" className={classes.itinerary_section_title} >Pricing</Typography>
                                    {activity.t_activity_pricing && activity.t_activity_pricing.map((pricing, pricingIndex) => {
                                        let pricingEditData = _.find(productData.diff, { path: ['t_activity', index, 't_activity_pricing', pricingIndex] });
                                        if(!(showAllFields() || pricingEditData)) {
                                            return (null);
                                        } else {
                                            return (
                                                <Grid container item spacing={1} key={pricing.activity_pricing_id}>
                                                    {showField({
                                                        path: ['t_activity', index, 't_activity_pricing', pricingIndex, 'lang', 'target_name'], 
                                                        variant: 'subtitle2',
                                                        language
                                                    })}
                                                    {showField({
                                                        path: ['t_activity', index, 't_activity_pricing', pricingIndex], 
                                                        variant: 'subtitle2',
                                                        showValueFn: (value) => value,
                                                        fieldFn: pricingRowComponent,
                                                    })}
                                                </Grid>
                                            );
                                        }
                                    })}
                                </Grid>
                            )
                        });
                    })()}
                    {productData.edit.t_activity && productData.edit.t_activity.map((activity, index) => {
                        let editData = _.find(productData.diff, { path: ['t_activity', index] });
                        if(!(showAllFields() || editData)) {
                            return (null);
                        } else {
                            return (
                                <Grid container item spacing={1} key={activity.activity_id} className={classes.activity_container}>
                                    {renderActivity(index, language)}
                                    <Typography variant="subtitle2" className={classes.itinerary_section_title} >Pricing</Typography>
                                    {activity.t_activity_pricing && activity.t_activity_pricing.map((pricing, pricingIndex) => {
                                        let pricingEditData = _.find(productData.diff, { path: ['t_activity', index, 't_activity_pricing', pricingIndex] });
                                        if(!(showAllFields() || pricingEditData)) {
                                            return (null);
                                        } else {
                                            return (
                                                <Grid container item spacing={1} key={pricing.activity_pricing_id}>
                                                    {showField({
                                                        path: ['t_activity', index, 't_activity_pricing', pricingIndex, 'lang', 'target_name'], 
                                                        variant: 'subtitle2',
                                                        language
                                                    })}
                                                    {showField({
                                                        path: ['t_activity', index, 't_activity_pricing', pricingIndex, 'lang', 'currency_code'], 
                                                        variant: 'subtitle2',
                                                        language,
                                                        fieldFn: fieldComponent({ valueRenderFn: (value) => value ? `(Currency: ${value})` : '' }),
                                                        showValueFn: (value) => value ? `(Currency: ${value})` : '',
                                                    })}
                                                    {showField({
                                                        path: ['t_activity', index, 't_activity_pricing', pricingIndex], 
                                                        variant: 'subtitle2',
                                                        showValueFn: (value) => value,
                                                        fieldFn: pricingRowComponent,
                                                    })}
                                                </Grid>
                                            );
                                        }
                                    })}
                                </Grid>
                            )
                        }
                    })}
                    {(() => {
                        let deletedActivities = _.filter(productData.diff, (d) => _.isEqual(d.path, ['t_activity']) && d.item.kind === 'D');
                        // Show deleted activities (kind = 'D')
                        return deletedActivities.map(d => {
                            let index = d.index;
                            let activity = productData.product.t_activity[index];
                            return (
                                <Grid container item spacing={1} key={activity.activity_id} className={clsx(classes.activity_container, classes.editD)}>
                                    {showField({
                                        path: ['t_activity', index, 'lang', 'activity_name'], 
                                        variant: 'subtitle2',
                                        force: true, 
                                        language
                                    })}
                                    {showField({
                                        path: ['t_activity', index, 'activity_id'], 
                                        variant: 'subtitle2',
                                        showValueFn: (value) => value ? `(Activity ID: ${value})` : '',
                                    })}
                                </Grid>
                            )
                        })
                        
                    })()}
                </Grid>
            </Grid>
        </LoadingOverlay>
    )
}