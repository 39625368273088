import React, {useEffect, useState, useContext } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { getURL } from '../_helpers/url-builder';
import LoadingSpinner from '../components/LoadingSpinner';

import { EditorLanguageProvider, EditorLanguageConsumer } from '../contexts/editor-language-context';
import LanguageSelect from '../components/fields/LanguageSelect';
import TranslateTextFieldWrapper from '../components/fields/TranslateTextFieldWrapper';
import { useAPI } from '../_helpers/auth-request';
import { LanguageContext } from '../contexts/language-context';

const defaultInitialLanguage = 1;
const getAllKeywordsURL = getURL('get-all-keywords');
const saveKeywordsURL = getURL('save-keyword');
const deleteKeywordsURL = getURL('remove-keyword');

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    marginLeft: '100px',
  },
}));

export default function Keywords() {
  const classes = useStyles();
  const { callApiAsync } = useAPI();
  const { language } = useContext(LanguageContext);

  const initialEditKeywordDialogState = {
    values: {},
    isActive: true, 
    keyword_id: null
  }
  
  const [keywordData, setKeywordData] = useState({keywordList: [], isFetching: false});
  const [keywordDialog, setKeywordDialog] = useState({ open:false, new: false, data: initialEditKeywordDialogState });

  const reFetchData = async() => {
    try{
      setKeywordData({keywordList: keywordData.keywordList, isFetching: true});
      const response = await callApiAsync({
        method: 'get',
        url: getAllKeywordsURL
      })
      setKeywordData({keywordList: response.data, isFetching: false});
    } catch(e) {
      console.log(e);
      setKeywordData({keywordList: keywordData.keywordList, isFetching: false});
    }
  };

  useEffect(() => {
    const fetchKeywordData = async() => {
      try{
        setKeywordData({keywordList: [], isFetching: true});
        const response = await callApiAsync({
          method: 'get',
          url: getAllKeywordsURL
        })
        setKeywordData({keywordList: response.data, isFetching: false});
      } catch(e) {
        console.log(e);
        setKeywordData({keywordList: [], isFetching: false});
      }
    };
    fetchKeywordData();
    // eslint-disable-next-line
  }, []);

  async function handleSave() {
    // console.log(keywordDialog.data);
    await callApiAsync({
        url: saveKeywordsURL,
        method: 'post',
        data: { keyword_data: keywordDialog.data, new_object: keywordDialog.new }
    })
    resetState();
    // TODO: Make this to update just the keyword that was edited instead of all the keywords
    reFetchData();
  }

  async function deleteKeyword(keywordId) {
    try{
      setKeywordData({keywordList: keywordData.keywordList, isFetching: true});
      await callApiAsync({
          url: deleteKeywordsURL,
          method: 'delete',
          data: { keyword_id: keywordId }
      })
      // TODO: Make this to update just the keyword that was edited instead of all the keywords
      reFetchData();
      return true;
    } catch(e) {
      console.log(e);
      setKeywordData({keywordList: keywordData.keywordList, isFetching: false});
      return false;
    }
  }

  function resetState() {
    setKeywordDialog({ open:false, new: false, data: initialEditKeywordDialogState })
  }

  function editKeyword(keywordData) {
    let kwObj = {
      values: keywordData.lang.keyword_text,
      isActive: keywordData.is_active,
      keyword_id: keywordData.keyword_id
    }
    // console.log(kwObj);
    setKeywordDialog({ open: true, new: false, data: kwObj })
  }

  function handleAddKeyword() {
    setKeywordDialog({ ...keywordDialog, open: true, new: true });
  }

  function handleClose() {
    resetState();
  }

  function setKeywordValue(name, value) {
    let kwObj = {
      values: value,
      keyword_id: keywordDialog.data.keyword_id,
      isActive: keywordDialog.data.isActive
    }
    setKeywordDialog({ ...keywordDialog, data: kwObj })
  }

  function handleActiveChange(event) {
    let kwObj = {
      values: keywordDialog.data.values,
      keyword_id: keywordDialog.data.keyword_id,
      isActive: event.target.checked
    }
    setKeywordDialog({ ...keywordDialog, data: kwObj })
  };  

  function renderTextField(valueIndex) {
    let attribs = {
        fieldName: keywordDialog.new ? "new-keyword" : "edit-keyword",
        label: keywordDialog.new ? "New Keyword" : "Edit",
        valueIndex: valueIndex
    }
    return(
      <TranslateTextFieldWrapper
          {...attribs}
          values={keywordDialog.data.values || ['']}
          fullWidth={true} 
          margin="dense"
          variant="outlined"
          setFieldValue={setKeywordValue}
      />
    )
  }

  const editorLanguageSelector = (
      <EditorLanguageConsumer>
          {({ language, updateLanguage }) => {
              return (
                  <LanguageSelect 
                      initialValue={language}
                      onChange={(event) => updateLanguage(event.target.value)}
                  />
              )}
          }
      </EditorLanguageConsumer>
  );

  function resolveName(nameObj) {
    if(nameObj[language]) {
        return nameObj[language];
    } else {
        let defaultKey = Object.keys(nameObj)[0];
        return nameObj[defaultKey];
    }
  }

  return (
    <EditorLanguageProvider initialValue={defaultInitialLanguage}> 
      <Container component="main">
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={(event) => handleAddKeyword()}
            >
              Add Keyword
            </Button>
          </Grid>
          <Grid item sm={12}>
            <MaterialTable
              maxWidth="xs"
              columns={[
                {
                  field: `lang.keyword_text[${language}]`, 
                  type: 'string', 
                  title: 'Keyword',
                  render: rowData => <span>{resolveName(rowData.lang.keyword_text)}</span>
                },
                {
                  field: 'keyword_id', 
                  type: 'string', 
                  title: 'Keyword ID'
                }
              ]}
              components={{
                OverlayLoading: LoadingSpinner
              }}
              options={{
                search: true,
                toolbar: false,
                paging: true,
                filtering: true,
                actionsColumnIndex: -1
              }}
              isLoading={keywordData.isFetching}
              data={keywordData.keywordList}
              editable={{
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    const result = deleteKeyword(oldData.keyword_id);
                    if(result) {
                      resolve();
                    } else {
                      reject();
                    }
                  }),
              }}
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Edit Product',
                  onClick: (event, rowData) => editKeyword(rowData)
                },
                // {
                //   icon: 'more',
                //   tooltip: 'View Details',
                //   onClick: (event, rowData) => editKeyword(rowData)
                // }
              ]}
            />
          </Grid>
        </Grid>
        <Dialog open={keywordDialog.open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            {keywordDialog.new && ("New Entry")}
            {!keywordDialog.new && ("Editing Entry")}
          </DialogTitle>
          <DialogContent>
            {editorLanguageSelector}
            <EditorLanguageConsumer>
              {({ language }) => (
                <span>
                  {renderTextField(language)}
                </span>
              )}
            </EditorLanguageConsumer>
            <FormControlLabel
              control={
                <Switch 
                  checked={keywordDialog.data.isActive}
                  onChange={handleActiveChange}
                  color="primary"
                />
              }
              label="Active"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </EditorLanguageProvider>
  );
}