import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import useForm from "./useForm";
import { authService } from "../_services/auth.service";
import { AuthContext } from '../contexts/auth-context';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const ResetPassword = (props) => {
    const { values, handleChange, handleSubmit } = useForm(submitResetPassword);
    const { updateAuth } = useContext(AuthContext);
    const classes = useStyles();

    const [submitted, setSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    function submitResetPassword() {
        
        setSubmitted(true);
        // stop here if form is invalid
        if (!(values.new_password && values.confirm_password)) {
            return;
        }
 
        setLoading(true);
        authService.resetPassword(values.new_password, values.confirm_password)
            .then(
                async (response) => {
                    await updateAuth();
                    console.log(`Password reset successful - redirecting`);
                    const from = props.location.state ? (props.location.state.from || "/") : "/";
                    console.log(`Authentication succesful, redirecting to ${from}`);
                    props.history.push(""+from);
                },
                error => {
                    setError(error);
                    setLoading(false);
                }
            );
    }

    if(submitted || loading || error) {
        
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
            <Typography component="h1" variant="h5">
                Please Reset Your Password
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="new_password"
                    label="New Password"
                    type="password"
                    id="new_password"
                    autoComplete="new-password"
                    onChange={handleChange} value={values.new_password || ''}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirm_password"
                    label="Confirm Password"
                    type="password"
                    id="confirm_password"
                    autoComplete="confirm-password"
                    onChange={handleChange} value={values.confirm_password || ''}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Update Password
                </Button>
            </form>
            </div>
        </Container>
    );
}

export default ResetPassword;