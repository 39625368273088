import React, {useState} from "react";

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from "@material-ui/core/Typography";

import Autocomplete from '@material-ui/lab/Autocomplete';

import LanguageSelect from './fields/LanguageSelect';

export default function UserForm (props) {
    const { onSubmit, initialValues, editMode, onChange, submitFunction } = props;

    const values = {
      user_id: initialValues.user_id || '',
      username: initialValues.username || '',
      password: editMode ? 'dummy password' : '',
      first_name: initialValues.first_name || '',
      last_name: initialValues.last_name || '',
      is_admin: initialValues.is_admin || false,
      reset_password: initialValues.reset_password || false,
      default_locale: initialValues.default_locale || 1,
      roles: initialValues.roles || []
    }

    const roleOptions = [
      "Admin",
      "Editor",
      "Reviewer"
    ]

    const [userDetails, setUserDetails] = useState(values);

    function handleChange(event) {
      let name = event.target.name;
      let value = event.target.value;
      handleValueChange(name, value);
    }

    function handleCheckedChange(event) {
      let name = event.target.name;
      let value = event.target.checked;
      handleValueChange(name, value);
    }

    function handleValueChange(name, value) {
      let updatedUserDetails = { ...userDetails, [name]: value };
      setUserDetails(updatedUserDetails)
      if( onChange ) onChange(name, value);
    }

    if( submitFunction ) {
      submitFunction(() => {
        return userDetails;
      })
    }

    return (
      <form onSubmit={onSubmit}>
          <Grid container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={1}
          >
              <Grid container item md={6}>
                  <FormControl fullWidth>
                      <TextField
                          name="username"
                          label="Username"
                          variant="outlined"
                          margin="dense"
                          value={userDetails.username}
                          onChange={handleChange}
                          disabled={editMode}
                          inputProps={{autoComplete: 'new-password'}}
                      />
                  </FormControl>
              </Grid>
              <Grid container item md={6}>
                  <FormControl fullWidth>
                      <TextField 
                          name="password"
                          label="Password"
                          type="password"
                          variant="outlined"
                          margin="dense"
                          value={userDetails.password}
                          onChange={handleChange}
                          disabled={editMode}
                          inputProps={{autoComplete: 'new-password'}}
                      />
                  </FormControl>
              </Grid>
              <Grid container item md={6} justify="space-between">
                <Grid item md={6}>
                  <FormControl>
                      <TextField 
                          name="first_name"
                          label="First Name"
                          variant="outlined"
                          margin="dense"
                          value={userDetails.first_name}
                          onChange={handleChange}
                          inputProps={{autoComplete: 'new-password'}}
                      />
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl>
                      <TextField 
                          name="last_name"
                          label="Last Name"
                          variant="outlined"
                          margin="dense"
                          value={userDetails.last_name}
                          onChange={handleChange}
                          inputProps={{autoComplete: 'new-password'}}
                      />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item md={6}>
                  <FormControl>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={userDetails.is_admin}
                                onChange={handleCheckedChange}
                                name="is_admin"
                                color="primary"
                            />
                          }
                          label="Is Admin?"
                      />
                  </FormControl>
                  <FormControl>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={userDetails.reset_password}
                                onChange={handleCheckedChange}
                                name="reset_password"
                                color="primary"
                            />
                          }
                          label="Reset Password on Login"
                      />
                  </FormControl>
              </Grid>
              <Grid container item md={6} spacing={1}>
                  <Grid item>
                      <Typography variant="h6" gutterBottom>Default Language:</Typography>
                  </Grid>
                  <Grid item>
                      <LanguageSelect 
                          name="default_locale"
                          initialValue={userDetails.default_locale}
                          value={userDetails.default_locale}
                          onChange={handleChange}
                      />
                  </Grid>
              </Grid>
              <Grid container item md={6} spacing={1}>
                  <Grid item>
                      <Typography variant="h6" gutterBottom>Roles:</Typography>
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      multiple
                      name="roles"
                      size="small"
                      value={userDetails.roles}
                      options={roleOptions}
                      // getOptionLabel={(option) => option.role}
                      onChange={(event, newValue) => handleValueChange('roles', newValue, event)}
                      filterSelectedOptions={true}
                      getOptionSelected={(option) => {
                        return userDetails.roles.indexOf(option) >= 0;
                      }}
                      // defaultValue={[top100Films[13]]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="roles"
                          placeholder="Roles"
                        />
                      )}
                    />
                  </Grid>
              </Grid>

              
          </Grid>
      </form>
    );

}