import React, {useState, useEffect} from "react";
import _ from "lodash";

import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';

import Container from '@material-ui/core/Container';

import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';

import { EditableDatesField } from "./fields/EditableDatesField";
import { formatDate } from "../_helpers/dates";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    spacer: {
        flex: '1 1 10%'
    },
    root: {
        minWidth: 350,
        maxWidth: 350
    }
}))

const DEFAULT_NEW_DATE = {
    dateMode: 1,
    value: formatDate(new Date())
}

export default function EditDatesComponent (props) {
    const { title, data, onChange, viewMode } = props;
    const [internalData, setInternalData] = useState(data || []);
    const classes = useStyles();

    useEffect(() => {
        setInternalData(data || []);
    }, [data])

    function handleAddClick() {
        let newDateObj = _.clone(DEFAULT_NEW_DATE);
        newDateObj.id = (internalData.length > 0 ? _.max(internalData.map(v => v.id)) : 0) + 1;
        let newData = ([...internalData, newDateObj])
        setInternalData(newData);
        if(onChange) onChange(newData);
    }

    function handleDelete(id) {
        let newData = _.cloneDeep(internalData);
        const index = _.findIndex(newData, { id });
        newData.splice(index, 1);
        setInternalData(newData);
        if(onChange) onChange(newData);
    }

    function handleChange(newDatum, id) {
        let newData = _.map(internalData, (e) => e.id === id ? {...newDatum, id} : e);
        setInternalData(newData);
        if(onChange) onChange(newData);
    }

    return (
        <Container disableGutters className={classes.root}>
            {title || !viewMode ? (
                <Toolbar>
                    {title ? (<Typography variant="h6">{title ? title: ""}</Typography>) : null}
                    {viewMode ? null : (
                        <span>
                            <div className={classes.spacer} />
                            <IconButton aria-label="add" onClick={handleAddClick}>
                                <AddBoxIcon />
                            </IconButton>  
                        </span>
                    )} 
                </Toolbar>
            ) : null}
            {internalData.map(datum => (
                <EditableDatesField 
                    {...datum}
                    className={classes.content}
                    key={datum.id} 
                    valueId={datum.id}
                    onDelete={handleDelete}
                    onChange={(newVal) => handleChange(newVal, datum.id)}
                    viewMode={viewMode}
                />
            ))}
        </Container>
    ) 
}