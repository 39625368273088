import React, {useState} from "react";

import { makeStyles  } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { Calendar, DateRange } from 'react-date-range';
import { formatDate } from "../../_helpers/dates";

const useStyles = makeStyles(theme => ({
    spacer: {
        flex: '1 1 10%'
    },
    content: {
        paddingLeft: theme.spacing(1)
    }
}))

const DATE_MODES = {
    SINGLE: 1,
    RANGE: 2
}

function EditableDatesField (props) {
    const { value, id, dateMode, viewMode, onChange, onDelete } = props;
    const classes = useStyles();

    const [data, setData] = useState({ dateMode: dateMode || DATE_MODES.RANGE, value: value || {} });
    const [anchorEl, setAnchorEl] = useState(null);
    
    const open = Boolean(anchorEl);
    const elemId = open ? 'simple-popover' : undefined;
    const showEditButtons = !viewMode;

    function renderDateText() {
        if(data.dateMode === DATE_MODES.SINGLE) {
            return (
                <span>{data.value}</span>
            )
        } else if(data.dateMode === DATE_MODES.RANGE) {
            return (
                <span>{data.value.start} - {data.value.end}</span>
            )
        } else {
            return (
                <span>Invalid date object</span>
            )
        }
    }

    function onEditClick(event) {
        // if(onChange) onChange(data)
        setAnchorEl(event.currentTarget)
    }

    function onDeleteClick(event) {
        setData({ dateMode: dateMode || DATE_MODES.RANGE, value: value || {} })
        if(onDelete) onDelete(id);
    }

    function handleModeChange(event) {
        let newMode = event.target.checked ? DATE_MODES.RANGE : DATE_MODES.SINGLE;
        
        let newObj = { dateMode: newMode }
        if(newMode === DATE_MODES.RANGE) {
            newObj.value = {start: data.value, end: data.value};
        } else if(newMode === DATE_MODES.SINGLE) {
            newObj.value = data.value.start;
        } else {

        }
        setData(newObj);
        if(onChange) onChange(newObj)
    }

    function handleRangeSelect(dates) {
        let newObj = { ...data, value: {start: formatDate(dates.selection.startDate), end: formatDate(dates.selection.endDate)}};
        setData(newObj);
        if(onChange) onChange(newObj)
    }

    function handleSelect(date) {
        let newObj = { ...data, value: formatDate(date)};
        setData(newObj);
        setAnchorEl(null);
        if(onChange) onChange(newObj)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    function renderDisplayMode() {
        return (
            <Grid container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item>
                    <Typography>
                        {renderDateText()}
                    </Typography>
                </Grid>
                {showEditButtons ? (<div className={classes.spacer} />) : null}
                {showEditButtons ? (
                        <Grid item>
                            <IconButton onClick={onEditClick} aria-label="edit">
                                <EditIcon />
                            </IconButton>   
                        </Grid>
                    ) : null}
                {showEditButtons ? (
                        <Grid item>
                            <IconButton onClick={onDeleteClick} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                        ) : null}
            </Grid>
        )
    }

    function renderModeSelect() {
        return(
            <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Single</Grid>
                    <Grid item>
                        <Switch checked={data.dateMode === DATE_MODES.RANGE} onChange={handleModeChange} name="dateMode" />
                    </Grid>
                    <Grid item>Range</Grid>
                </Grid>
            </Typography>
        )
    }

    function renderDatePicker() {
        if(data.dateMode === DATE_MODES.RANGE) {
            // debugger;
            return (
                <Popover
                        id={elemId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Grid container alignItems="center" direction="column">
                        <Grid item>{renderModeSelect()}</Grid>
                        <Grid item>
                            <DateRange
                                ranges={[{
                                    startDate: new Date(data.value.start),
                                    endDate: new Date(data.value.end),
                                    key: 'selection',
                                }]}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                direction="horizontal"
                                onChange={handleRangeSelect}
                            />
                        </Grid>
                    </Grid>
                </Popover>
            )
        } else if(data.dateMode === DATE_MODES.SINGLE) {
            // debugger;
            return (
                <Popover
                        id={elemId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Grid container alignItems="center" direction="column">
                        <Grid item>{renderModeSelect()}</Grid>
                        <Grid item>
                        <Calendar
                            date={new Date(data.value)}
                            onChange={handleSelect}
                        />
                        </Grid>
                    </Grid>
                </Popover>
            )
        }
    }

    return (
        <Paper elevation={1} className={classes.content}>
            {/* {editMode ? renderEditMode() : renderDisplayMode()} */}
            {renderDisplayMode()}
            {renderDatePicker()}
        </Paper>
    ) 
}

export default EditableDatesField;
export { DATE_MODES, EditableDatesField };