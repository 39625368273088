import React, {useEffect, useState, useContext} from 'react';
import _ from "lodash";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DownloadIcon from '@material-ui/icons/GetApp';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { useSnackbar } from 'notistack';

import { Link } from "react-router-dom";

import { getURL } from '../_helpers/url-builder';
import LoadingSpinner from '../components/LoadingSpinner';

import EditProduct from "../components/EditProduct";

import { useAPI } from '../_helpers/auth-request';
import { LanguageContext } from '../contexts/language-context';

const EXPORT_PRODUCT_URL = getURL('export-products');

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    marginLeft: '100px',
  },
}));

export default function Products() {
  const classes = useStyles();
  const { callApiAsync } = useAPI();

  const initialEditProductDialogState = { 
    open: false, 
    editMode: false, 
    newProduct: false, 
    productID: null,
    editStageID: null
  }
  
  const [productData, setProductData] = useState({productList: [], isFetching: false});
  const [editProductDialog, setEditProductDialog] = useState(initialEditProductDialogState);
  const { language } = useContext(LanguageContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchProdctData = async() => {
      try{
        setProductData({productList: [], isFetching: true});
        const response = await callApiAsync({
          method: 'get',
          url: getURL('get-all-products')
        })
        setProductData({productList: response.data, isFetching: false});
      } catch(e) {
        console.log(e);
        setProductData({productList: [], isFetching: false});
      }
    };
    fetchProdctData();
    // eslint-disable-next-line
  }, []);

  function handleEditProductDialogOpen(newProduct) {
    setEditProductDialog({ open: true, editMode: false, newProduct: newProduct, productID: null, editStageID: null});
  }

  async function deleteProduct(productData) {
    let product_id = productData.product_id;
    let pData = _.cloneDeep(productData);
    pData.mark_remove = true;
    const removeProductURL = getURL('remove-product', product_id);
    try {
      const response = await callApiAsync({
          method: 'post',
          url: removeProductURL,
          data: {
            product_data: pData
          }
      });
      if(response.status === 200) {
          enqueueSnackbar('Product has been marked for removal',  { variant: 'success' })
          return true;
          // refetchCategoryData();
      } else {
        enqueueSnackbar('Encountered an issue while removing the product',  { variant: 'error' })
        return false;
    }
    } catch(e) {
      enqueueSnackbar('Encountered an issue while removing the product',  { variant: 'error' })
      return false;
    }
    
  }

  async function toggleProductVisibility(productData) {
    let product_id = productData.product_id;
    const updateProductVisibilityURL = getURL('update-product-visibility', product_id);
    let data = {
      is_visible: !productData.is_visible
    }
    try {
      const response = await callApiAsync({
          method: 'post',
          url: updateProductVisibilityURL,
          data
      });
      if(response.status === 200) {
          enqueueSnackbar(`Product is now marked as ${data.is_visible ? 'Visibile' : 'NOT Visible'}`,  { variant: 'success' })
          return true;
      } else {
        enqueueSnackbar('Encountered an issue while updating the product',  { variant: 'error' })
        return false;
    }
    } catch(e) {
      enqueueSnackbar('Encountered an issue while updating the product',  { variant: 'error' })
      return false;
    }
  }

  async function cancelDeleteProduct(productData) {
    let product_id = productData.product_id;
    let pData = _.cloneDeep(productData);
    pData.mark_remove = false;
    const cancelRemoveProductURL = getURL('undo-remove-product', product_id);
    try {
      const response = await callApiAsync({
          method: 'post',
          url: cancelRemoveProductURL,
          data: {
            product_data: pData
          }
      });
      if(response.status === 200) {
          enqueueSnackbar('Product is no longer marked for removal',  { variant: 'success' })
          return true;
      } else {
        enqueueSnackbar('Encountered an issue while updating the product',  { variant: 'error' })
        return false;
    }
    } catch(e) {
      enqueueSnackbar('Encountered an issue while updating the product',  { variant: 'error' })
      return false;
    }
    
  }

  async function exportProducts() {
    window.open(EXPORT_PRODUCT_URL, "_blank");
  }

  function handleEditProductDialogClose() {
    setEditProductDialog(initialEditProductDialogState);
  }

  function resolveName(nameObj) {
    if(nameObj[language]) {
        return nameObj[language];
    } else {
        let defaultKey = Object.keys(nameObj)[0];
        return nameObj[defaultKey];
    }
  }

  async function handleVisibilityClick(rowData) {
    let result = await toggleProductVisibility(rowData);
    if(result) {
      const updateList = _.map(productData.productList, p => p.product_id !== rowData.product_id ? p : {...p, is_visible: !rowData.is_visible});
      setProductData({...productData, productList: updateList})
    }
  }

  async function handleDeleteClick(rowData) {
    let result = false;
    if(rowData.mark_remove) {
        result = await cancelDeleteProduct(rowData);
    } else {
        result = await deleteProduct(rowData);
    }
    // debugger;
    if(result) {
        const updateList = _.map(productData.productList, p => p.product_id !== rowData.product_id ? p : {...p, mark_remove: !rowData.mark_remove});
        setProductData({...productData, productList: updateList})
    }
  }

  return (
      <Container component="main">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={(event) => handleEditProductDialogOpen(true)}
        >
          Add Product
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<DownloadIcon />}
          onClick={(event) => exportProducts()}
        >
          Export Products
        </Button>
        <MaterialTable
          maxWidth="xs"
          columns={[
            {
              field: 'is_visible',
              type: 'boolean',
              title: 'Is Visible?',
              render: rowData => {
                  if(rowData.is_visible) {
                      return (
                        <IconButton aria-label="add" className={classes.margin} onClick={() => handleVisibilityClick(rowData)}>
                            <VisibilityIcon fontSize="inherit" />
                        </IconButton>
                      )
                  } else {
                    return (
                      <IconButton aria-label="add" className={classes.margin} onClick={() => handleVisibilityClick(rowData)}>
                          <VisibilityOffIcon fontSize="inherit" />
                      </IconButton>
                    )
                  }
              }
            },
            {
              field: `lang.product_name[${language}]`, 
              type: 'string', 
              title: 'Product Name',
              render: rowData => <span>{resolveName(rowData.lang.product_name)}</span>
            },
            {
              field: 'product_id', 
              type: 'string', 
              title: 'Product ID'
            },
            {
              // TODO: This should be resolved to the name (switchable by the language selector)
              field: 'name', 
              type: 'string', 
              title: 'Country'
            },
            {
              field: 'is_edit',
              type: 'boolean',
              title: 'Edits Pending?'
            },
            {
              field: 'mark_remove',
              type: 'boolean',
              title: 'Marked for Removal?'
            },
            {
              field: 'actions',
              title: 'Actions',
              filtering: false,
              render: rowData => {
                return (
                  <span>
                      <Link to={`/EditProduct/${rowData.product_id}`}>
                          <IconButton>
                              <EditIcon />
                          </IconButton>
                      </Link>
                      <IconButton 
                          className={classes.margin}
                          onClick={(event) => {handleDeleteClick(rowData)}}>
                          {rowData.mark_remove ? <RestoreFromTrashIcon /> : <DeleteIcon />}
                      </IconButton>
                  </span>
                )
              }
            }
          ]}
          components={{
            OverlayLoading: LoadingSpinner
          }}
          options={{
            search: true,
            toolbar: false,
            paging: true,
            filtering: true,
            actionsColumnIndex: -1
          }}
          isLoading={productData.isFetching}
          data={productData.productList}
        />
        <EditProduct 
          editMode={editProductDialog.editMode}
          productID={editProductDialog.productID}
          newProduct={editProductDialog.newProduct}
          open={editProductDialog.open}
          onClose={handleEditProductDialogClose}
          onSave={handleEditProductDialogClose}
        />
      </Container>
  );
}