import React from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import EditMerchant from '../components/EditMerchant';

export default function EditMerchantStandalone(props) {
    const { merchant_id } = useParams();
    let history = useHistory();

    const dialogState = { 
      open: true, 
      editMode: true, 
      newMerchant: false
    }

    function handleEditMerchantDialogClose() {
        history.push('/merchants')
    }

    return(
        <EditMerchant 
            open={dialogState.open}
            editMode={dialogState.editMode}
            newMerchant={dialogState.newMerchant}
            merchantID={merchant_id}
            onClose={handleEditMerchantDialogClose}
            // onSave={handleEditMerchantDialogClose}
            keepOpenOnSave={true}
        />
    )
}