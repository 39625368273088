import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';


export default function Products(Home) {
    return (
        <Container component="main" maxWidth="xs">
          <Typography variant="h2" gutterBottom>{"Home"}</Typography>
        </Container>
    );
}