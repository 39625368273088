import React, { useState, useEffect, useContext } from 'react';

import _ from "lodash";

import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';

import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

// You can import the default tree with dnd context
import SortableTree, { addNodeUnderParent, removeNodeAtPath, map as mapTree, getFlatDataFromTree } from 'react-sortable-tree';

import { useSnackbar } from 'notistack';

import AsyncKeywordSelect from '../components/fields/AsyncKeywordSelect';
import LoadingSpinner from '../components/LoadingSpinner';
import TranslateTextFieldWrapper from '../components/fields/TranslateTextFieldWrapper';
import AssociatedMediaField from '../components/fields/AssociatedMediaField';
import SingleMediaField from '../components/fields/SingleMediaField';

import { getURL, buildMediaURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';
import { LanguageContext } from '../contexts/language-context';

const categoryDataURL = getURL('get-categories');
const saveCategoryDataURL = getURL('save-categories');
const countProductsByKeywordsURL = getURL('count-products-by-keywords');

const useStyles = makeStyles(theme => ({
    spacer: {
        flex: '1 1 10%'
    },
    root: {
        minWidth: 350,
        maxWidth: 350
    }
}))

const DEFAULT_NEW_CATEGORY = {
    children: [],
    disp_level: 1,
    is_enabled: false,
    // keywords: [],
    legacy_code: "",
    lang: {
        name: {
            '1': "New Category",
            '2': "New Category"
        },
        language_id: {
            '1': 1,
            '2': 2
        }
    },
    parent_category_id: null,
    parent_country_alpha3: "",
    parent_country_code: "",
    parent_region_code: "",
    region_code: "",
    sort: -1,
    banner_media: null,
    featured_media: null
}

export default function CategoryManagement() {
    const [data, setData] = useState({});
    const [maxCategoryID, setMaxCategoryID] = useState(0);
    const [catTreeData, setCatTreeData] = useState({});
    const [detailedCatInfo, setDetailCatInfo] = useState({ data: {}, selectedNode: {}, isLoading: false});
    const [editMode, setEditMode] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [productMatch, setProductMatch] = useState({loading: false, count:0});

    const { callApiAsync } = useAPI();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { language } = useContext(LanguageContext);

    // const getNodeKey = (node) => {
    //     return node.category_id;
    // }

    const getNodeKey = ({ node }) => {
        return node.category_id;
    }

    // const getNodeKey = ({ treeIndex }) => treeIndex;

    useEffect(() => {
        if(!data.listCategories) return;
        setCatTreeData({
            treeData: data.categoriesTree 
            // There's a bug with this method where paths are not generated correctly using the getNodeKey function
            // getTreeFromFlatData({
            //     flatData: data.listCategories.map(node => ({ ...node, title: " ", subtitle: " " })),
            //     getKey: (node) => getNodeKey({ node }),
            //     getParentKey: node => node.parent_category_id,
            //     rootKey: null
            // })
        })
    }, [data])

    useEffect(() => {
        const fetchCategoryData = async() => {
            try{
                setData({});
                const response = await callApiAsync({
                    method: 'get',
                    url: categoryDataURL
                });
                setData(response.data);
                setMaxCategoryID(response.data.categoryInfo.max_category_id);
            } catch(e) {
                console.log(e);
                setData({});
            }
        };
        fetchCategoryData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!detailedCatInfo.data.keywords) return;
        const fetchCategoryData = async() => {
            try{
                setProductMatch({loading: true});
                setData({});
                const response = await callApiAsync({
                    method: 'post',
                    url: countProductsByKeywordsURL,
                    data: {
                        keywords: detailedCatInfo.data.keywords
                    }
                })
                setProductMatch({loading: false, count:response.data.count});
            } catch(e) {
                console.log(e);
                setProductMatch({loading: false, count:0});
            }
        };
        fetchCategoryData();
        // eslint-disable-next-line
    }, [detailedCatInfo.data.keywords])
    
    const handleEditModeChange = event => {
        setEditMode(event.target.checked);
    };

    // function getDataSet(withCategory, isVisible) {
    //     let index = withCategory ? 'withCategorySet' : 'withNoCategorySet';
    //     let obj = _.find(data[index], {is_visible: isVisible});

    //     return obj && obj.count ? obj.count : 0;
    // }

    async function refetchCategoryData() {
        try{
            setData({});
            const response = await callApiAsync({
                method: 'get',
                url: categoryDataURL
            });
            setData(response.data);
            setMaxCategoryID(response.data.categoryInfo.max_category_id);
        } catch(e) {
            console.log(e);
            setData({});
        }
    };

    function handleCheckedChange(event) {
        let name = event.target.name;
        let value = event.target.checked;
        handleFieldChange(name, value);
    }

    function handleLangFieldChange(field, newValue) {
        let langCopy = detailedCatInfo.data.lang;
        let newLang = {
            ...langCopy,
            [field]: newValue
        }
        let detailCatInfoData = detailedCatInfo.data;
        setDetailCatInfo({
            ...detailedCatInfo,
            data: {
                ...detailCatInfoData,
                lang: newLang
            }
        })
    }

    function handleFieldChange(field, newValue) {
        let detailCatInfoData = detailedCatInfo.data;
        setDetailCatInfo({
            ...detailedCatInfo,
            data: {
                ...detailCatInfoData,
                [field]: newValue
            }
        })
    }

    function handleKeywordChange(val) {
        let detailCatInfoData = detailedCatInfo.data;

        setDetailCatInfo({
            ...detailedCatInfo,
            data: {
                ...detailCatInfoData,
                keywords: val
            }
        })
    }

    async function handleSingleCategorySave() {
        let category_id = detailedCatInfo.data.category_id;
        const updateCategoryURL = getURL('update-category', category_id);
        const response = await callApiAsync({
            method: 'post',
            url: updateCategoryURL,
            data: {
                category_data: detailedCatInfo.data
            }
        });
        // TODO: Add a notification based on response status
        if(response.status === 200) {
            enqueueSnackbar('Category details saved succesfully',  { variant: 'success' })
            refetchCategoryData();
        } else {
            enqueueSnackbar('Encountered an issue while saving category',  { variant: 'error' })
        }
    }

    async function handleCategoryDataSave(categoryData) {
        const response = await callApiAsync({
            method: 'post',
            url: saveCategoryDataURL,
            data: {
                category_data: categoryData
            }
        })
        // TODO: Add a notification based on response status
        if(response.status === 200) {
            enqueueSnackbar('Category tree saved succesfully',  { variant: 'success' })
        } else {
            enqueueSnackbar('Encountered an issue while saving',  { variant: 'error' })
        }
    }

    function resolveCatName(catData) {
        let catName = catData.lang ? catData.lang.name[language] : null;
        if(catName) {
            return (<span>{catName}</span>)
        } else {
            return ("No name set")
        }
    }

    function renderProductMatches(catInfo) {
        if(productMatch.count > 0) {
            return (
                <Typography>
                    {productMatch.count} products are in this category
                </Typography>
            );
        } else {
            return (
                <Typography>
                    No products are in this category
                </Typography>
            );
        }
    }

    function renderCatInfo(catInfo) {
        if (typeof catInfo.category_id === 'undefined') {
            return (<Paper>Select a category to view details</Paper>)
        } else {
            return (
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} direction="column">
                                    <Grid item>
                                        <Typography variant="h6">
                                            {resolveCatName(detailedCatInfo.data)} - (ID: {catInfo.category_id})
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TranslateTextFieldWrapper
                                            id="name"
                                            name="name"
                                            fieldName="name"
                                            label="Category Name"
                                            valueIndex={language}
                                            values={detailedCatInfo.data.lang ? detailedCatInfo.data.lang.name : null}
                                            fullWidth={true} 
                                            margin="dense"
                                            variant="outlined"
                                            setFieldValue={handleLangFieldChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AsyncKeywordSelect 
                                            language={language}
                                            value={catInfo.keywords}
                                            onChange={handleKeywordChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AssociatedMediaField 
                                            fieldName="banner_media"
                                            // Uncomment on change so that it automatically saves instead of needing to click save on the dialog
                                            // created by this field
                                            onChange={(newValue) => handleFieldChange('banner_media', newValue)}
                                            // onSave={(newValue) => handleFieldChange('banner_media', newValue)}
                                            label="Banner Media"
                                            value={catInfo.banner_media}
                                            listURL={buildMediaURL('category', catInfo.category_id)}
                                            saveURL={getURL('save-category-banner-media', catInfo.category_id)}
                                            allowUpdateLinks={true}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <SingleMediaField 
                                            fieldName="featured_media"
                                            // Uncomment on change so that it automatically saves instead of needing to click save on the dialog
                                            // created by this field
                                            // onChange={(newValue) => setFieldValue('schedule', newValue)}
                                            onSave={(newValue) => handleFieldChange('featured_media', newValue)}
                                            saveURL={getURL('save-category-featured-media', catInfo.category_id)}
                                            label="Featured Media"
                                            value={catInfo.featured_media}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TranslateTextFieldWrapper
                                            id="overview"
                                            name="overview"
                                            fieldName="overview"
                                            label="Overview"
                                            valueIndex={language}
                                            values={detailedCatInfo.data.lang ? detailedCatInfo.data.lang.overview : null}
                                            fullWidth={true} 
                                            multiline
                                            margin="dense"
                                            variant="outlined"
                                            setFieldValue={handleLangFieldChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {renderProductMatches(catInfo)}
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={catInfo.is_enabled}
                                                    onChange={handleCheckedChange}
                                                    name="is_enabled"
                                                    color="primary"
                                                />
                                            }
                                            label="Is Enabled?"
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            // className={classes.button}
                            onClick={(event) => handleSingleCategorySave()}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    }

    const loadCatInfo = async ({
        node,
        path,
        treeIndex,
        lowerSiblingCounts: _lowerSiblingCounts,
    }) => {
        try{
            setDetailCatInfo({ data:{}, selectedNode: {}, isLoading: true });
            const response = await callApiAsync({
                method: 'get',
                url: getURL('get-category-details', node.category_id),
            });
            setDetailCatInfo({ data: response.data, selectedNode: node, isLoading: false });
        } catch(e) {
            console.log(e);
            setDetailCatInfo({ data:{}, selectedNode: {}, isLoading: false });
        }
    };

    function handleRootAddClick() {
        let new_category_id = (maxCategoryID || 0) + 1;
        let newCategory = { 
            ...DEFAULT_NEW_CATEGORY,
            category_id: new_category_id
        };
        newCategory.lang.category_id = {
            '1': new_category_id,
            '2': new_category_id
        }
        setCatTreeData({
            treeData: catTreeData.treeData.concat(newCategory)
        });
        setMaxCategoryID(new_category_id);
        setHasUnsavedChanges(true);
    }

    function handleAddChild(rowInfo) {
        let newCat = _.cloneDeep(DEFAULT_NEW_CATEGORY);
        let new_category_id = (maxCategoryID || 0) + 1;
        newCat.parent_category_id = rowInfo.node.category_id;
        newCat.category_id = new_category_id;
        setCatTreeData({
            treeData: addNodeUnderParent({
                treeData: catTreeData.treeData,
                parentKey: rowInfo.node.category_id,
                expandParent: true,
                getNodeKey,
                newNode: newCat,
                addAsFirstChild: true
            }).treeData
        })
        setMaxCategoryID(new_category_id);
        setHasUnsavedChanges(true);
    }

    function handleDelete(rowInfo) {
        // Build path leading to this node
        // let result = resolvePath(catTreeData.treeData, rowInfo.path, 0);

        setCatTreeData({
            treeData: removeNodeAtPath({
                treeData: catTreeData.treeData,
                path: rowInfo.path,
                getNodeKey
            })
        })
        setHasUnsavedChanges(true);
    }

    function getCategoryButtons(rowInfo) {
        if(editMode) {
            return [
                <IconButton aria-label="add" className={classes.margin} size="small" onClick={() => handleAddChild(rowInfo)}>
                    <AddIcon fontSize="inherit" />
                </IconButton>
                ,
                <IconButton aria-label="delete" className={classes.margin} size="small" onClick={() => handleDelete(rowInfo)}>
                    <DeleteOutlineIcon fontSize="inherit" />
                </IconButton>
            ]
        } else {
            return [
                <button
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => loadCatInfo(rowInfo)}
                >
                    ℹ
                </button>,
            ]
        }
    }

    function nodeCallback({ node }) {
        if(node.children) {
            for(let i = 0; i<node.children.length; i++) {
                node.children[i].disp_level = (node.disp_level || 0) + 1;
                node.children[i].sort = (i+1)*10;
            }
        }
        // console.log(node);
        return node;
    }

    function updateBaseData(treeData) {
        setData({
            ...data,
            categoriesTree: treeData
        })
    }

    function saveTreeEdit() {
        setEditMode(false);
        setHasUnsavedChanges(false);
        updateBaseData(catTreeData.treeData)
        // TODO: Save to server
        let fixedTree = mapTree({
            treeData: catTreeData.treeData,
            getNodeKey,
            callback: nodeCallback,
            ignoreCollapsed: false
        });
        setCatTreeData({ treeData: fixedTree });
        updateBaseData(fixedTree);
        let flatTree = getFlatDataFromTree({
            treeData: fixedTree,
            getNodeKey,
            ignoreCollapsed: false
        });
        // Filter our children data
        let filteredTree = _.map(flatTree, ({ node }) => {
            let { children, ...restData } = node;
            return restData;
        })
        handleCategoryDataSave(filteredTree);
        console.log(filteredTree);
    }

    function cancelTreeEdit() {
        setEditMode(false);
        setHasUnsavedChanges(false);
        setCatTreeData({
            treeData: data.categoriesTree 
            // There's a bug with this method where paths are not generated correctly using the getNodeKey function
            // getTreeFromFlatData({
            //     flatData: data.listCategories.map(node => ({ ...node, title: " ", subtitle: " " })),
            //     getKey: (node) => getNodeKey({ node }),
            //     getParentKey: node => node.parent_category_id,
            //     rootKey: null
            // })
        })
    }

    function renderEditHandle() {
        if(hasUnsavedChanges) {
            return (
                <Grid container spacing={3}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            // className={classes.button}
                            onClick={(event) => saveTreeEdit()}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            // className={classes.button}
                            onClick={(event) => cancelTreeEdit()}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <FormControlLabel
                    control={
                    <Switch 
                        checked={editMode}
                        onChange={handleEditModeChange}
                        color="primary"
                    />
                    }
                    label="Edit Tree"
                />
            )
        }
    }

    return (
        <Container component="main" maxWidth="xl">
            {/* <Grid container spacing={3} justify="center">
                <Grid item sm={3}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3} direction="column">
                                <Grid item>
                                    <Typography variant="h5">
                                        Products without a category set
                                    </Typography>
                                </Grid>
                                <Grid container item spacing={3} justify="center">
                                    <Grid item>
                                        <Typography variant="h4">
                                            {getDataSet(false, true)}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            Visible
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4">
                                            {getDataSet(false, false)}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            Invisible
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item sm={3}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3} direction="column">
                                <Grid item>
                                    <Typography variant="h5">
                                        Products with a category set
                                    </Typography>
                                </Grid>
                                <Grid container item spacing={3} justify="center">
                                    <Grid item>
                                        <Typography variant="h4">
                                            {getDataSet(true, true)}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            Visible
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4">
                                            {getDataSet(true, false)}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            Invisible
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid> */}
            <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
                <Grid item sm={4}>
                    {catTreeData.treeData && (
                        <span>
                            {!editMode ? (
                                <Toolbar>
                                    {renderEditHandle()}
                                </Toolbar>
                            ) : (
                                <Toolbar>
                                    {renderEditHandle()}
                                    <div className={classes.spacer} />
                                    <IconButton aria-label="add" onClick={handleRootAddClick}>
                                        <AddBoxIcon />
                                    </IconButton>  
                                </Toolbar>
                            )} 
                            <SortableTree
                                treeData={catTreeData.treeData}
                                canDrag={editMode}
                                getNodeKey={getNodeKey}
                                // TODO: When onChange is called, we should also call setData with it
                                onChange={treeData => {
                                    setCatTreeData({ treeData });
                                    updateBaseData(treeData);
                                }}
                                onMoveNode={({ treeData, node, nextParentNode }) => {
                                    let newParentID = null;
                                    if(nextParentNode) {
                                        newParentID = nextParentNode.category_id;
                                    }
                                    node.parent_category_id = newParentID;
                                    setCatTreeData({ treeData });
                                    updateBaseData(treeData);
                                    setHasUnsavedChanges(true);
                                }}
                                isVirtualized={false}
                                generateNodeProps={rowInfo => ({
                                    buttons: getCategoryButtons(rowInfo),
                                    title: rowInfo.node.lang ? rowInfo.node.lang.name[language] : "<Not Set>",
                                    subtitle: `   (ID: ${rowInfo.node.category_id})`
                                    // BUG: in this way, the wrong class is set for the title (should be to accomodate the subtitle)
                                    // subtitle: (rowInfo.node.legacy_code ? "Legacy Code: "+ rowInfo.node.legacy_code : " ")
                                })}
                            />
                        </span>
                    )}
                    {!catTreeData.treeData && (<LoadingSpinner />)}
                </Grid>
                <Grid item sm={4}>
                    {detailedCatInfo.isLoading && (<LoadingSpinner/>)}
                    {(detailedCatInfo.data && !detailedCatInfo.isLoading) && renderCatInfo(detailedCatInfo.data)}
                </Grid>
            </Grid>
        </Container>
    );
}