import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  thumbnail_column: {
    width: '88px',
    float: 'left',
    marginRight:'1em'
  },
  thumbnail_image: {
    marginBottom: '13px'
  },
  display_image: {
    width: '561px',
    height: '336px',
    float: 'left'
  },
  section: {
    marginBottom: '1em',
    width: '100%'
  },
  itinerary_section_title: {
    width: '100%',
    padding: '10px',
    background: '#acd1ec'
  }
}));

export default function ProductPanel (props) {
    const classes = useStyles();
    const { language, data, ...restProps } = props;

    if(restProps) {
      
    }

    let language_index = 0;
    if(data.t_lang_product[language-1]) {
      language_index = language-1;
    }
    let t_lang_product = data.t_lang_product[language_index];

    function buildBulletPoints(string, showBulletPoints) {

      if(showBulletPoints) {
        return(
          <div>
            <ul>
             {string.split ('\n').map ((item, i) => <li key={i}><Typography variant="body2" gutterBottom key={i}>{item}</Typography></li>)}
            </ul>
          </div>
        )
      } else {
        return(
          <div>
            {string.split ('\n').map ((item, i) => <Typography variant="body2" gutterBottom key={i}>{item}</Typography>)}
          </div>
        )
      }
    }

    return (
      <Grid container className={classes.root} spacing={3}>
        <Grid container item>
          <Grid item sm={5} lg={5}>
            <div className={classes.thumbnail_column}>
              <img src="http://via.placeholder.com/88x44" alt="thumbnail" className={classes.thumbnail_image}/>
              <img src="http://via.placeholder.com/88x44" alt="thumbnail" className={classes.thumbnail_image} />
              <img src="http://via.placeholder.com/88x44" alt="thumbnail" className={classes.thumbnail_image} />
              <img src="http://via.placeholder.com/88x44" alt="thumbnail" className={classes.thumbnail_image} />
              <img src="http://via.placeholder.com/88x44" alt="thumbnail" className={classes.thumbnail_image} />
            </div>
            <div className={classes.display_image}>
              <img src="http://via.placeholder.com/561x336" alt="Full size" />
            </div>
          </Grid>
          <Grid item sm={7} lg={7} container>
            <Typography variant="h5">{t_lang_product.product_name}</Typography>
            <Grid item container spacing={3}>
              <Grid item xs={2}>
                <Typography variant="body1">Major Attraction:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{t_lang_product.major_attraction}</Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item xs={2}>
                <Typography variant="body1">Product Code:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{data.product_code}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <div className={classes.section}>
            <Typography variant="h5">{t_lang_product.slogan}</Typography>
          </div>
          <div className={classes.section}>
            <Typography variant="h6">Description</Typography>
            {buildBulletPoints(t_lang_product.overview)}
          </div>
          <div className={classes.section}>
            <Typography variant="h6">Itinerary Plan</Typography>
              {data.t_activity.map(activity => {
                let controls = `panel${activity.activity_id}-content`;
                let id = `panel${activity.activity_id}-header`;
                let t_lang_activity = activity.t_lang_activity[language_index];

                return(
                  <ExpansionPanel key={activity.activity_id}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={controls}
                      id={id}
                      key={activity.activity_id}
                    >
                      <Typography variant="subtitle2">{t_lang_activity.activity_name}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                      >
                        <div className={classes.itinerary_section_title}>
                          <Typography variant="subtitle2" >Activity Arrangement</Typography>
                        </div>
                        {buildBulletPoints(t_lang_activity.arrangement, true)}
                        
                        <div className={classes.itinerary_section_title}>
                          <Typography variant="subtitle2" >Inclusions</Typography>
                        </div>
                        {buildBulletPoints(t_lang_activity.inclusions, true)}
                        
                        <div className={classes.itinerary_section_title}>
                          <Typography variant="subtitle2" >Exclusions</Typography>
                        </div>
                        {buildBulletPoints(t_lang_activity.exclusions, true)}
                        
                        <div className={classes.itinerary_section_title}>
                          <Typography variant="subtitle2" >Attention Items</Typography>
                        </div>
                        {buildBulletPoints(t_lang_activity.attention, true)}
                        
                        <div className={classes.itinerary_section_title}>
                          <Typography variant="subtitle2" >Change and Cancellation</Typography>
                        </div>
                        {buildBulletPoints(t_lang_activity.cancellation, true)}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              })}
          </div>
          
        </Grid>
      </Grid>
    );

}

ProductPanel.defaultProps = {
  language: 1
}