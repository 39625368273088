import React, {useEffect, useState} from 'react';
import Container from '@material-ui/core/Container';
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import { yellow, red } from '@material-ui/core/colors';

import { LanguageConsumer } from '../contexts/language-context';
import ProductPanel from '../components/ProductPanel';
import LoadingSpinner from '../components/LoadingSpinner';
import ProductPageFull from '../components/ProductPageFull'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import WarnIcon from '@material-ui/icons/Warning';
import RemoveIcon from '@material-ui/icons/Remove';

import { getURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';

const useStyles = makeStyles(theme => ({
  language_selector_form: {
    float: 'right'
  },
  product_panel_title: {
    display: 'inline'
  },
  badgeMargin: {
    margin: theme.spacing(2)
  },
  iconWarn: {
    color: yellow['A400']
  },
  iconError: {
    color: red[500]
  }
}));

export default function ImportReview(props) {
  const classes = useStyles();
  const { callApiAsync } = useAPI();
  const [data, setData] = useState({importFiles: [], isFetching: false});
  const [productPreviewRow, setProductPreviewRow] = useState({data: {}});
  const [productDialogOpen, setProductDialogOpen] = useState(false);

  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [warningData, setWarningData] = useState({data: [], isFetching: false});
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorData, setErrorData] = useState({data: [], isFetching: false});

  // const [testMode, setTestMode] = React.useState(true);
  // const handleTestModeChange = event => {
  //   setTestMode(event.target.checked);
  // };

  useEffect(() => {
    const fetchImportFiles = async() => {
      try{
        setData({importFiles: [], isFetching: true});
        
        let url = getURL('get-pending-import');
        const response = await callApiAsync({
          method: 'get',
          url
        })
        setData({importFiles: response.data, isFetching: false});
      } catch(e) {
        console.log(e);
        setData({importFiles: [], isFetching: false});
      }
    };
    fetchImportFiles();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(productPreviewRow.data.product_code) {
      console.log(`Product selected ${productPreviewRow.data.product_code}`);
      setProductDialogOpen(true);
    }
  }, [productPreviewRow])

  function handleProductDialogClose() {
    setProductDialogOpen(false);
  }

  function handleWarningDialogOpen() {
    setWarningDialogOpen(true);
  }

  function handleWarningDialogClose() {
    setWarningDialogOpen(false);
  }

  function handleErrorDialogOpen() {
    setErrorDialogOpen(true);
  }

  function handleErrorDialogClose() {
    setErrorDialogOpen(false);
  }

  function saveImportRow(event, row) {
    const saveImportStagingData = async() => {
      try{
        setData({importFiles: data.importFiles, isFetching: true});
        
        let url = getURL('commit-import-file');
        let postData = {
          source_file: row.source_file,
          test_mode: false
        }
        await callApiAsync({
          method: 'post',
          url,
          data: postData
        })
        let importIndex = data.importFiles.data.findIndex((element) => {
          return element.source_file === row.source_file;
        });
        data.importFiles.data.splice(importIndex, 1);
        setData({importFiles: data.importFiles, isFetching: false});
      } catch(e) {
        console.log(e);
        setData({importFiles: data.importFiles, isFetching: false});
      }
    };
    saveImportStagingData();

    console.log("You saved " + row.source_file);
  }

  function deleteImportRow(event, row) {
    const deleteImportStagingData = async() => {
      try{
        setData({importFiles: data.importFiles, isFetching: true});
        
        let url = getURL('remove-import-file');
        let postData = {
          source_file: row.source_file
        }
        await callApiAsync({
          method: 'post',
          url,
          data: postData
        })
        let importIndex = data.importFiles.data.findIndex((element) => {
          return element.source_file === row.source_file;
        });
        data.importFiles.data.splice(importIndex, 1);
        setData({importFiles: data.importFiles, isFetching: false});
      } catch(e) {
        console.log(e);
        setData({importFiles: data.importFiles, isFetching: false});
      }
    };
    deleteImportStagingData();
  }

  function showWarnings(event, rowData) {
    console.log(`Show warnings for ${rowData.source_file}`);
    handleWarningDialogOpen();
    const fetchFileWarnings = async() => {
      try{
        setWarningData({data: [], isFetching: true});
        
        let url = getURL('get-warnings-for-file', rowData.source_file);
        const response = await callApiAsync({
          method: 'get',
          url
        })
        setWarningData({data: response.data, isFetching: false});
      } catch(e) {
        console.log(e);
        setWarningData({data: [], isFetching: false});
      }
    };
    fetchFileWarnings();
  }

  function showErrors(event, rowData) {
    console.log(`Show errors for ${rowData.source_file}`);
    handleErrorDialogOpen();
    const fetchFileErrors = async() => {
      try{
        setErrorData({data: [], isFetching: true});
        
        let url = getURL('get-errors-for-file', rowData.source_file);
        const response = await callApiAsync({
          method: 'get',
          url
        })
        setErrorData({data: response.data, isFetching: false});
      } catch(e) {
        console.log(e);
        setErrorData({data: [], isFetching: false});
      }
    };
    fetchFileErrors();
  }

    return (
        <Container maxWidth="xl">
          {/* <FormControlLabel
            control={
              <Switch 
                checked={testMode}
                onChange={handleTestModeChange}
                color="primary"
              />
            }
            label="Test Mode"
          /> */}
          <MaterialTable
            title="Import Results"
            columns={[
              {field: 'source_file', type: 'string', title: 'Source File'},
              {
                field: 'import_date', 
                type: 'datetime', 
                title: 'Import Time', 
                filtering: false,
                render: rowData => <span>{new Date(Date.parse(rowData.import_date)).toLocaleString('en-US')}</span>
              },
              {
                field: 'counts.t_product',
                type: 'numeric',
                title: 'Product #',
                filtering: false
              },
              {
                field: 'counts.t_activity',
                type: 'numeric',
                title: 'Activity #',
                filtering: false
              },
              {
                field: 'counts.t_activity_pricing',
                type: 'numeric',
                title: 'Pricing #',
                filtering: false
              },
              {
                field: 'has_issue', 
                title: 'Has Issues?',
                filtering: false,
                render: rowData => {
                  if(rowData.warn_count > 0 && rowData.error_count > 0) {
                    return(
                      <div>
                        <IconButton onClick={event => showWarnings(event, rowData)}>
                          <Badge badgeContent={rowData.warn_count} color="primary">
                            <WarnIcon className={classes.iconWarn}/>
                          </Badge>
                        </IconButton>
                        <IconButton onClick={event => showErrors(event, rowData)}>
                          <Badge badgeContent={rowData.error_count} color="primary">
                            <ErrorIcon className={classes.iconError}/>
                          </Badge>
                        </IconButton>
                      </div>
                    )
                  }
                  if(rowData.warn_count > 0) {
                    return(
                      <IconButton onClick={event => showWarnings(event, rowData)}>
                        <Badge badgeContent={rowData.warn_count} color="primary">
                          <WarnIcon className={classes.iconWarn}/>
                        </Badge>
                      </IconButton>
                    )
                  }
                  if(rowData.error_count > 0) {
                    return(
                      <IconButton onClick={event => showErrors(event, rowData)}>
                        <Badge badgeContent={rowData.error_count} color="primary">
                          <ErrorIcon className={classes.iconError}/>
                        </Badge>
                      </IconButton>
                    )
                  }
                  return (
                    <RemoveIcon />
                  )
                }
              },
            ]}
            actions={[
              {
                icon: 'save',
                tooltip: 'Commit Imported Data',
                onClick: (event, rowData) => saveImportRow(event, rowData)
              },
              {
                icon: 'delete',
                tooltip: 'Delete Imported Data',
                onClick: (event, rowData) => deleteImportRow(event, rowData)
              }
            ]}
            options={{
              pageSizeOptions: [5, 10, 20],
              pageSize: 5,
              actionsColumnIndex: -1,
              filtering: true,
              paging: false
            }}
            components={{
              OverlayLoading: LoadingSpinner
            }}
            detailPanel={ rowData => {
              return (
                <div>
                  {rowData.data_json.map((row, index) => {
                    return (
                      <LanguageConsumer key={row.product_id}>
                        {({ language }) => (
                          <ProductPanel 
                            key={row.product_id}
                            data={row}
                            language={language}
                            setProductPreview={setProductPreviewRow}
                          />
                        )}
                      </LanguageConsumer>
                    );
                  })}
                </div>
              );
            }}
            isLoading={data.isFetching}
            data={data.importFiles.data}
          />
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={warningDialogOpen}
            onClose={handleWarningDialogClose}
            aria-labelledby="import-warning-dialog-title"
          >
            <DialogTitle id="import-warning-dialog-title">
              <div>
                Import Warnings
              </div>
            </DialogTitle>
            <DialogContent>
              <MaterialTable
                columns={[
                  {field: 'type', type: 'string', title: 'Type'},
                  {field: 'associated_id', type: 'string', title: 'Associated ID'},
                  {field: 'detail_id', type: 'string', title: 'Detail ID'},
                  {field: 'message', type: 'string', title: 'Message'},
                ]}
                components={{
                  OverlayLoading: LoadingSpinner
                }}
                options={{
                  search: false,
                  toolbar: false
                }}
                isLoading={warningData.isFetching}
                data={warningData.data}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            aria-labelledby="import-error-dialog-title"
          >
            <DialogTitle id="import-error-dialog-title">
              <div>
                Import Errors
              </div>
            </DialogTitle>
            <DialogContent>
              <MaterialTable
                columns={[
                  {field: 'type', type: 'string', title: 'Type'},
                  {field: 'associated_id', type: 'string', title: 'Associated ID'},
                  {field: 'detail_id', type: 'string', title: 'Detail ID'},
                  {field: 'message', type: 'string', title: 'Message'},
                ]}
                components={{
                  OverlayLoading: LoadingSpinner
                }}
                options={{
                  search: false,
                  toolbar: false
                }}
                detailPanel={rowData => {
                  return (
                    <Grid container spacing={3}>
                      {
                        Object.keys(rowData.error_object).map( (key) => {
                          return(
                            <Grid container item>
                              <Grid item xs={2}>
                                {key}
                              </Grid>
                              <Grid item xs={10}>
                                {rowData.error_object[key]}
                              </Grid>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  )
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                isLoading={errorData.isFetching}
                data={errorData.data}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={productDialogOpen}
            onClose={handleProductDialogClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              <div className={classes.product_panel_title}>
                Product Details
              </div>
              <div className={classes.language_selector_form}>
                <LanguageConsumer>
                  {({ language, updateLanguage }) => (
                    <form autoComplete="off">
                      <FormControl>
                        <InputLabel htmlFor="lang-simple">Language</InputLabel>
                        <Select
                          value={language}
                          onChange={event => {
                            updateLanguage(event.target.value);
                          }}
                          inputProps={{
                            name: 'language',
                            id: 'lang-simple',
                          }}
                        >
                          <MenuItem value={1}>English</MenuItem>
                          <MenuItem value={2}>简体中文</MenuItem>
                        </Select>
                      </FormControl>
                    </form>  
                  )}
                </LanguageConsumer>
              </div>
            </DialogTitle>
            <DialogContent>
            <LanguageConsumer>
              {({ language }) => (
                <ProductPageFull 
                  data={productPreviewRow.data} 
                  language={language}
                />
              )}
            </LanguageConsumer>
            </DialogContent>
          </Dialog>
        </Container>
    );
}