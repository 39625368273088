import React from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import EditProduct from '../components/EditProduct';

export default function EditProductStandalone(props) {
    const { product_id } = useParams();
    let history = useHistory();

    const dialogState = { 
      open: true, 
      editMode: true, 
      newProduct: false
    }

    function handleEditProductDialogClose() {
        history.push('/products')
    }

    return(
        <EditProduct 
            open={dialogState.open}
            editMode={dialogState.editMode}
            newProduct={dialogState.newProduct}
            productID={product_id}
            onClose={handleEditProductDialogClose}
            // onSave={handleEditProductDialogClose}
            keepOpenOnSave={true}
        />
    )
}