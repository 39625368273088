import React, {useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import { getURL } from '../_helpers/url-builder';
import LoadingSpinner from '../components/LoadingSpinner';

import { useAPI } from '../_helpers/auth-request';

const getConversionConfigURL = getURL('get-conversion-config');
const updateDataURL = getURL('update-conversion-data');
const saveNewDataURL = getURL('save-conversion-data');
const deleteDataURL = getURL('delete-conversion-data');

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    marginLeft: '100px',
  },
}));

export default function ProductConversion() {
  const classes = useStyles();
  const { callApiAsync } = useAPI();
  
  const [data, setData] = useState({list: [], isFetching: false});

  const reFetchData = async() => {
    try{
      setData({list: data.list, isFetching: true});
      const response = await callApiAsync({
        method: 'get',
        url: getConversionConfigURL
      })
      setData({list: response.data, isFetching: false});
    } catch(e) {
      console.log(e);
      setData({list: data.list, isFetching: false});
    }
  };

  useEffect(() => {
    const fetchData = async() => {
      try{
        setData({list: [], isFetching: true});
        const response = await callApiAsync({
          method: 'get',
          url: getConversionConfigURL
        })
        setData({list: response.data, isFetching: false});
      } catch(e) {
        console.log(e);
        setData({list: [], isFetching: false});
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  async function handleSave(newData) {
    // console.log(keywordDialog.data);
    await callApiAsync({
        url: saveNewDataURL,
        method: 'post',
        data: newData
    })
    // TODO: Make this to update just the keyword that was edited instead of all the keywords
    reFetchData();
  }

  async function handleUpdate(newData) {
    // console.log(keywordDialog.data);
    await callApiAsync({
        url: updateDataURL,
        method: 'post',
        data: newData
    })
    // TODO: Make this to update just the keyword that was edited instead of all the keywords
    reFetchData();
  }

  async function deleteRecord(id) {
    try{
      setData({list: data.list, isFetching: true});
      await callApiAsync({
          url: deleteDataURL,
          method: 'delete',
          data: { id }
      })
      // TODO: Make this to update just the keyword that was edited instead of all the keywords
      reFetchData();
      return true;
    } catch(e) {
      console.log(e);
      setData({list: data.list, isFetching: false});
      return false;
    }
  }

  function handleAddKeyword() {
    // setKeywordDialog({ ...keywordDialog, open: true, new: true });
  }
  
  const mTableEditable = {
        onRowAdd: newData =>
            new Promise((resolve, reject) => {
                // const data = productDatesData;
                // data.push(newData);
                // setProductDatesData(_.clone(data));
                handleSave(newData);
                resolve();
            }),
        onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
                // const data = productDatesData;
                // const index = data.indexOf(oldData);
                // data[index] = newData;
                // setProductDatesData(_.clone(data));
                handleUpdate(newData);
                resolve();
            }),
        onRowDelete: oldData =>
            new Promise((resolve, reject) => {
                const result = deleteRecord(oldData.id);
                if(result) {
                    resolve();
                } else {
                    reject();
                }
            }),
    }

  return (
      <Container component="main">
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={(event) => handleAddKeyword()}
            >
              Add Record
            </Button>
            <MaterialTable
              maxWidth="xs"
              columns={[
                { field: 'id',                      title: 'ID'                                 },
                { field: 'header_value',            title: 'Header'                             },
                { field: 'language_id',             title: 'Lang ID'                            },
                { field: 'conv_table',              title: 'Conversion Table'                   },
                { field: 'conv_column',             title: 'Conversion Column'                  },
                { field: 'is_localized_field',      title: 'Localized?',        type: 'boolean' },
                { field: 'is_list_field',           title: 'List?',             type: 'boolean' },
              ]}
              components={{
                OverlayLoading: LoadingSpinner
              }}
              options={{
                pageSizeOptions: [20, 50],
                pageSize: 20,
                search: false,
                paging: true,
                filtering: true,
                actionsColumnIndex: -1
              }}
              isLoading={data.isFetching}
              data={data.list}
              editable={mTableEditable}
            />
          </Grid>
        </Grid>
      </Container>
  );
}