import React, {useState} from "react";

import _ from "lodash";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import ScheduleIcon from '@material-ui/icons/Schedule';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Paper from '@material-ui/core/Paper';

import ScheduleEditor from '../ScheduleEditor';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#eeeeee',
    margin: theme.spacing(1)
  },
  noDates: {
    fontWeight: "bold"
  },
  date: {
    display: "block"
  }
}));


export default function TimeSlotsField (props) {
  const classes = useStyles();
    const { initialValues, duration, calcDuration, onChange, timesDisplayComponent, label } = props;

    const [editDatesDialog, setEditDatesDialog] = useState({ open:false })
    const [timeValues, setDateValues] = useState(initialValues || []);
    const [internalDateValues, setInternalDateValues] = useState(initialValues || []);

    const calendarDates = _.clone(timeValues);

    function handleEditTimesDialogClose() {
      setEditDatesDialog({open:false})
    }

    function handleEditTimesDialogSave() {
      setEditDatesDialog({open:false})
      setDateValues(internalDateValues);
      if(onChange) onChange(internalDateValues);
    }

    function handleEditDatesDialogCancel() {
      setEditDatesDialog({open:false})
    }

    function handleEditDatesClick() {
      setEditDatesDialog({open:true})
    }

    function handleDatesChange(newValues) {
      setInternalDateValues(newValues);
    }

    const timeRanges = (
      <Grid container spacing={2} direction="row">
        {timeValues.map((dateRange, idx) => (
          <Grid container item key={idx}>
            <Paper key={idx}>
              <Grid container>
                <Grid item xs={6}>
                  {dateRange.start}
                </Grid>
                <Grid item xs={6}>
                  {dateRange.start_location}
                </Grid>
                <Grid item xs={6}>
                  {dateRange.end}
                </Grid>
                <Grid item xs={6}>
                  {dateRange.end_location}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    )

    function renderInnerComponent() {
      if(timesDisplayComponent) {
        return (<timesDisplayComponent timeValues={timeValues}/>)
      } else if(timeValues && timeValues.length > 0) {
        return timeRanges
      } else {
        return (
          <span className={classes.noDates}>{label} - No Schedule Set</span>
        )
      }
    }

    return (
      <Grid container item 
        justify="space-between"
        alignItems="flex-start"
        className={classes.container} 
      >
        <Grid container item xs={10}>
            {renderInnerComponent()}
        </Grid>
        <Grid item>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<ScheduleIcon />}
                onClick={handleEditDatesClick}
            >
                Edit Times
            </Button>
        </Grid>
        <Dialog
            maxWidth="xl"
            open={editDatesDialog.open}
            onClose={handleEditTimesDialogClose}
        >
          <DialogContent>
            <ScheduleEditor 
              title={label}
              showRanges={true}
              hideShowRangesSwitch={true}
              onChange={handleDatesChange}
              values={calendarDates}
              duration={duration}
              calcDuration={calcDuration}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditTimesDialogSave} color="primary">
              Save
            </Button>
            <Button onClick={handleEditDatesDialogCancel} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );

}