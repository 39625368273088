import React, {useState, useEffect} from "react";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

import { useAPI } from '../../_helpers/auth-request';
import { buildURL, getURL } from '../../_helpers/url-builder';
import CategoryPlaceholder from '../CategoryPlaceholder';
import UploadMediaPreview from '../UploadMediaPreview';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#eeeeee',
    margin: theme.spacing(1)
  },
  noDates: {
    fontWeight: "bold"
  },
  date: {
    display: "block"
  },
  mediaPreview: {
    maxWidth: 400,
    maxHeight: 400,
  },
  uploadInput: {
    display: 'none',
  },
}));

const UPLOAD_IMAGE_URL = getURL('upload-media');

export default function SingleMediaField (props) {
    const classes = useStyles();
    const { callApiAsync } = useAPI();
    // const { label, value, onChange, onSave, mediaDisplayComponent } = props;
    const { label, value, onSave, mediaDisplayComponent, saveURL } = props;

    // const [editMediaDialog, setEditMediaDialog] = useState({ open:false })
    const [associatedMedia, setAssociatedMedia] = useState("");
    const [mediaDetails, setMediaDetails] = useState({ file_path: "", isFetching: false });
    const [uploadImage, setUploadImage] = useState({ set: false });

    useEffect(() => {
        setAssociatedMedia(value);
        if(value) {
            let fetchMediaDetailsURL = getURL('fetch-media', value);
            let fetchMedia = async() => {
                try {
                    setMediaDetails({ file_path: "", isFetching: true })
                    const response = await callApiAsync({
                        method: "get",
                        url: fetchMediaDetailsURL,
                    });
                    setMediaDetails({
                        file_path: response.data.file_path,
                        isFetching: false,
                    });
                } catch (e) {
                    console.log(e);
                    setMediaDetails({ file_path: "", isFetching: false })
                }
            };
            fetchMedia();
        }
        // eslint-disable-next-line
    }, [value]);

    async function handleAddMediaDialogSave() {
        const formData = new FormData();
        formData.append("file", uploadImage.file, uploadImage.file.name);

        const response = await callApiAsync({
            method: "post",
            url: UPLOAD_IMAGE_URL,
            data: formData
        })
        
        if(response.status === 200) {
            let canCommit = true;
            if(saveURL && response.data && response.data.media_id) {
                const saveResponse = await callApiAsync({
                    method: "post",
                    url: saveURL,
                    data: {
                        media_id: response.data.media_id
                    }
                })
                canCommit = saveResponse.status === 200;
            }
    
            if(canCommit) {
                setAssociatedMedia(response.data.media_id)
                setUploadImage({ set: false });
                if(onSave) {
                    onSave(response.data.media_id)
                } else {
                    setMediaDetails({
                        file_path: response.data.file_path,
                        isFetching: false
                    })
                }
            }
        }
    }

    function handleAddMediaDialogCancel() {
        setUploadImage({ set: false });
    }

    function handleDelete() {
        setAssociatedMedia("");
        if(onSave) {
            onSave(null)
        } else {
            setMediaDetails({ file_path: "", isFetching: false })
        }
    }

    function handleImageChange(e) {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
            setUploadImage({
                file: file,
                imagePreviewUrl: reader.result,
                set: true
            });
        }
    
        reader.readAsDataURL(file)
    }

    // TODO: Fix where the placeholder is visible while the image is loading
    function renderInnerComponent() {
        if (mediaDisplayComponent) {
            return <mediaDisplayComponent dateValues={associatedMedia} />;
        } else if (associatedMedia && mediaDetails.file_path) {
            return (
                <span className={classes.noDates}>
                    <img 
                        src={buildURL(`/images/product/${mediaDetails.file_path}`)} 
                        alt={associatedMedia}
                        className={classes.mediaPreview}
                    />
                </span>
            );
        } else {
            return (
                <CategoryPlaceholder
                    className={classes.mediaPreview}
                />
                // <span className={classes.noDates}>
                //     No media items associated
                // </span>
            );
        }
    }

    return (
        <Grid container item
            direction="column"
        >
            <Grid item>
                {label}
            </Grid>
            <Grid container item 
                justify="space-between"
                alignItems="center"
                className={classes.container} 
            >
                <Grid item xs={9}>
                    {renderInnerComponent()}
                </Grid>
                <Grid container item
                    direction="column"
                    xs={2}
                    spacing={3}
                >
                    <Grid item>
                        <input 
                            accept="image/*" 
                            className={classes.uploadInput} 
                            id="icon-button-file" 
                            type="file" 
                            onChange={handleImageChange}
                        />
                        <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                <CloudUploadIcon />
                            </IconButton>
                        </label>
                        {/* <IconButton 
                            aria-label="upload"
                            color="primary"
                        >
                            <CloudUploadIcon />
                        </IconButton> */}
                    </Grid>
                    <Grid item>
                        <IconButton 
                            aria-label="delete"
                            color="primary"
                            disabled={!associatedMedia}
                            onClick={handleDelete}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <UploadMediaPreview
                open={uploadImage.set}
                imageURL={uploadImage.imagePreviewUrl}
                name={uploadImage.file && uploadImage.file.name}
                className={classes.mediaPreview}
                onConfirm={handleAddMediaDialogSave}
                onCancel={handleAddMediaDialogCancel}
            />
        </Grid>
        
    );

}