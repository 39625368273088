import React, {useState, useEffect} from "react";
import _ from "lodash";
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: "-11px",
    marginRight: "0px",
  },
  dayOn: {
    padding: '3px',
    backgroundColor: green['300'],
    borderColor: '#555555',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  dayOff: {
    padding: '3px',
    backgroundColor: red['300'],
    borderColor: '#555555',
    borderStyle: 'solid',
    borderWidth: '1px',
  }
}));

export default function WeekDaysSelector (props) {
    const classes = useStyles();

    const { value, onChange, viewMode, miniMode } = props;

    // const internalValue = ((v) => {
    //   if(Array.isArray(v)) {
    //     return {
    //       '0': false,    // Starting with 0 being Sunday
    //       '1': false,    // Monday
    //       '2': false,    // Tuesday
    //       '3': false,    // Wednesday
    //       '4': false,    // Thursday
    //       '5': false,    // Friday
    //       '6': false,    // Saturday
    //     };
    //   } else {
    //     return v;
    //   }
    // })(value);
    const [weekdays, setWeekdays] = useState({
      '0': true,    // Starting with 0 being Sunday
      '1': true,    // Monday
      '2': true,    // Tuesday
      '3': true,    // Wednesday
      '4': true,    // Thursday
      '5': true,    // Friday
      '6': true,    // Saturday
    });

    const dayList = [
      {id: '0', text: 'Su'},
      {id: '1', text: 'M'},
      {id: '2', text: 'T'},
      {id: '3', text: 'W'},
      {id: '4', text: 'Th'},
      {id: '5', text: 'F'},
      {id: '6', text: 'S'},
    ];

    const idxToWeekdayMap = {
        '0': 'Su',
        '1': 'M',
        '2': 'T',
        '3': 'W',
        '4': 'Th',
        '5': 'F',
        '6': 'S',
    };

    useEffect(() => {
      if(Array.isArray(value)) {
        // Convert it to an object for our state setter
        let convValue = {
          '0': value.includes('Su'),    // Starting with 0 being Sunday
          '1': value.includes('M'),     // Monday
          '2': value.includes('T'),     // Tuesday
          '3': value.includes('W'),     // Wednesday
          '4': value.includes('Th'),    // Thursday
          '5': value.includes('F'),     // Friday
          '6': value.includes('S'),     // Saturday
        }
        setWeekdays(convValue);
      } else {
        setWeekdays(value);
      }
    }, [value]);

    function convertWeekDays(arr) {
        let weekdayArray = Object.keys(arr).map(key => {
            return arr[key] ? idxToWeekdayMap[key] : null;
        })
        _.pull(weekdayArray, null);
        return weekdayArray;
    }

    const handleChange = name => event => {
        let newWeekdays = { ...weekdays, [name]: event.target.checked };
        let convWeekdays = convertWeekDays(newWeekdays);
        setWeekdays(convWeekdays);
        if (onChange) onChange(convWeekdays);
    };

    return (
      <div>
        {dayList.map( day => {
          if(viewMode && miniMode) {
            return (
              <span key={day.id} className={weekdays[day.id] ? classes.dayOn : classes.dayOff}>{day.text}</span>
            )
          } else {
            return(
              <FormControlLabel
                value={day.id}
                className={classes.root}
                key={day.id}
                control={<Checkbox
                          checked={weekdays[day.id]}
                          onChange={handleChange(day.id)}
                          disabled={viewMode}
                          value={day.id}
                        />}
                label={day.text}
                labelPlacement="top"
              />
            )
          }
        })}
      </div>
    );

}