import React, { useState, useEffect } from 'react';
// import _ from "lodash";

import MaterialTable from 'material-table';

import Container from '@material-ui/core/Container';
import { makeStyles } from "@material-ui/core/styles";
import { red } from '@material-ui/core/colors';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import IconButton from '@material-ui/core/IconButton'
import ErrorIcon from '@material-ui/icons/Error';
// import Badge from '@material-ui/core/Badge';

import LoadingSpinner from '../components/LoadingSpinner';

import { getURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';

const useStyles = makeStyles(theme => ({
    iconError: {
      color: red[500]
    }
  }));

const ISSUE_LOOKUP = {
    'empty-schedule': 'No schedule set',
    'empty-avail_days': 'No available day set',
    'invalid-avail_days-value': 'Invalid Available Days value',
    'empty-included_dates': 'No included dates set',
    'invalid-included_dates': 'Invalid included dates value',
    'invalid-excluded_dates': 'Invalid excluded dates value',
}

const translationDataURL = getURL('translation-issues');
const scheduleDataURL = getURL('schedule-issues');

export default function DataIntegrity(props) {
    const classes = useStyles();
    const { callApiAsync } = useAPI();

    const [translationData, setTranslationData] = useState({data: [], isFetching: true});
    const [scheduleData, setScheduleData] = useState({data: [], isFetching: true});
    const [issueDisplay, setIssueDisplay] = useState({data: {}, open: false});

    useEffect(() => {
        const fetchData = async() => {
          try{
            setTranslationData({data: [], isFetching: true});
            setScheduleData({data: [], isFetching: true});
            const translationResponse = await callApiAsync({
                method: 'get',
                url: translationDataURL
            });
            const scheduleResponse = await callApiAsync({
                method: 'get',
                url: scheduleDataURL
            });
            setTranslationData({data: translationResponse.data, isFetching: false});
            setScheduleData({data: scheduleResponse.data, isFetching: false});
          } catch(e) {
            console.log(e);
            setTranslationData({data: [], isFetching: false});
            setScheduleData({data: [], isFetching: false});
          }
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    function showIssues(rowData) {
        setIssueDisplay({data: rowData.issues, open: true});
    }

    function resetIssueDisplay() {
        setIssueDisplay({data: {}, open: false});
    }

    function renderIssues(issues) {
        if(!issues || issues.length === 0) {
            return null;
        } else {
            return (
                <span>
                    {
                        issues.map(issue => <div>{ISSUE_LOOKUP[issue.type]}</div>)
                    }
                </span>
            )
        }
    }

    return (
        <Container component="main" maxWidth="xl">
            <Typography variant="h2" gutterBottom>Translations</Typography>
            <Grid container spacing={3}>
                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Products</Typography>
                            {
                                translationData.isFetching ? null : (
                                    <Typography variant="h3">{translationData.data.product.count}</Typography>
                                )
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Activities</Typography>
                            {
                                translationData.isFetching ? null : (
                                    <Typography variant="h3">{translationData.data.activity.count}</Typography>
                                )
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Activity Pricing</Typography>
                            {
                                translationData.isFetching ? null : (
                                    <Typography variant="h3">{translationData.data.activity_pricing.count}</Typography>
                                )
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Typography variant="h2" gutterBottom>Schedules</Typography>
            <MaterialTable 
                columns={[
                    {
                        field: 'product_id',
                        title: 'Product ID'
                    },
                    {
                        field: 'num_activities',
                        title: '# of Problem Activities',
                        filtering: false,
                        render: rowData => <span>{rowData.issues.activity ? rowData.issues.activity.ids.length : "0"}</span>
                    },
                    {
                        field: 'num_pricings',
                        title: '# of Problem Pricings',
                        filtering: false,
                        render: rowData => <span>{rowData.issues.activity_pricing ? rowData.issues.activity_pricing.ids.length : "0"}</span>
                    },
                    {
                        title: 'Issues',
                        filtering: false,
                        render: rowData => {
                            return(
                                <IconButton onClick={event => showIssues(rowData)}>
                                    {/* <Badge badgeContent={rowData.error_count} color="primary"> */}
                                        <ErrorIcon className={classes.iconError}/>
                                    {/* </Badge> */}
                                </IconButton>
                            )
                        }
                    }
                ]}
                isLoading={scheduleData.isFetching}
                data={scheduleData.data}
                options={{
                    search: false,
                    paging: false,
                    toolbar: false,
                    filtering: true
                }}
            />
            <Dialog
              fullWidth={true}
              maxWidth="xl"
              open={issueDisplay.open}
              onClose={resetIssueDisplay}
              aria-labelledby="issue-display"
            >
                <DialogTitle id="issue-display"> Issues Identified </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} direction="column">
                        <Grid item>
                            {
                                issueDisplay.data.product ?
                                <MaterialTable
                                    title="Product"
                                    columns={[
                                        {field: 'product_id', type: 'string', title: 'Product ID'},
                                        {
                                            field: 'problemsFound', 
                                            type: 'string', 
                                            title: 'Issues Identified',
                                            render: rowData => renderIssues(rowData.problemsFound)
                                        },
                                    ]}
                                    components={{
                                        OverlayLoading: LoadingSpinner
                                    }}
                                    options={{
                                        search: false,
                                        pageSize: 1,
                                        pageSizeOptions: [1]
                                    }}
                                    data={issueDisplay.data.product ? issueDisplay.data.product.records : []}
                                />
                                : 
                                <Typography variant="h6">Product - No issues identified</Typography>
                            }
                        </Grid>
                        <Grid item>
                            {
                                issueDisplay.data.activity ?
                                <MaterialTable
                                    title="Activities"
                                    columns={[
                                        {field: 'activity_id', type: 'string', title: 'Activity ID'},
                                        {
                                            field: 'problemsFound', 
                                            type: 'string', 
                                            title: 'Issues Identified',
                                            render: rowData => renderIssues(rowData.problemsFound)
                                        },
                                    ]}
                                    components={{
                                        OverlayLoading: LoadingSpinner
                                    }}
                                    options={{
                                        search: false,
                                        pageSize: issueDisplay.data.activity ? 5 : 1,
                                        pageSizeOptions: issueDisplay.data.activity ? [5,10,20] : [1]
                                    }}
                                    data={issueDisplay.data.activity ? issueDisplay.data.activity.records : []}
                                />
                                : 
                                <Typography variant="h6">Activity - No issues identified</Typography>
                            }
                        </Grid>
                        <Grid item>
                            {
                                issueDisplay.data.activity_pricing ?
                                <MaterialTable
                                    title="Activity Pricing"
                                    columns={[
                                        {field: 'activity_pricing_id', type: 'string', title: 'Activity Pricing ID'},
                                        {
                                            field: 'problemsFound', 
                                            type: 'string', 
                                            title: 'Issues Identified',
                                            render: rowData => renderIssues(rowData.problemsFound)
                                        },
                                    ]}
                                    components={{
                                        OverlayLoading: LoadingSpinner
                                    }}
                                    options={{
                                        search: false,
                                        pageSize: issueDisplay.data.activity_pricing ? 5 : 1,
                                        pageSizeOptions: issueDisplay.data.activity_pricing ? [5,10,20] : [1]
                                    }}
                                    data={issueDisplay.data.activity_pricing ? issueDisplay.data.activity_pricing.records : []}
                                />
                                : 
                                <Typography variant="h6">Activity Pricing - No issues identified</Typography>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Container>
    );
}