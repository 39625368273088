import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
      />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
            onChange({
                target: {
                    value: values.value,
                },
            });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};
  
export default function CurrencyField(props) {    
    const [value, setValue] = React.useState('');

    if(false) {
        setValue(1);
    }
  
    // const handleChange = event => {
    //     setValue(event.target.value);
    // };
  
    return (
        <TextField
            value={value}
            // onChange={handleChange}
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
            {...props}
        />
    );
}