import React, { createContext, useReducer } from "react";

export const AppVersionContext = createContext({
    version: process.env.REACT_APP_VERSION,
    versionMatch: true,
    updateVersionMatch: () => {},
});
AppVersionContext.displayName = 'AppVersionContext';

export const AppVersionProvider = ({ children }) => {
    const updateVersionMatch = versionMatch => {
        setState({
            versionMatch: versionMatch
        })
    }

    const initialState = {
        version: process.env.REACT_APP_VERSION,
        versionMatch: true,
        updateVersionMatch: updateVersionMatch
    };

    const [state, setState] = useReducer(
        (oldState, newState) => newState,
        initialState
    );

    return (
        <AppVersionContext.Provider value={state}>
            {children}
        </AppVersionContext.Provider>
    );
};

export const AppVersionConsumer = AppVersionContext.Consumer;