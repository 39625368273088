import React, {useEffect, useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import MaterialTable from 'material-table';

import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';

import Container from '@material-ui/core/Container';

import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import PageviewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import LoadingSpinner from '../components/LoadingSpinner';
import UserAvatar from '../components/UserAvatar';
import EditProduct from "../components/EditProduct";
import { getURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';
import { LanguageContext } from '../contexts/language-context';
import { getUserIDFromToken } from '../contexts/auth-context';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    marginLeft: '100px',
  },
  newChip: {
    color: green['500'],
    borderColor: green['500']
  },
  modifyChip: {
    color: yellow['700'],
    borderColor: yellow['700']
  },
  removeChip: {
    color: red['900'],
    borderColor: red['900']
  }
}));

export default function ReviewProducts() {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const currentUserID = getUserIDFromToken();
  const { callApiAsync } = useAPI();

  const initialEditProductDialogState = { 
    open: false, 
    editMode: false, 
    newProduct: false, 
    productID: null,
    editStageID: null
  }
  
  const [editProductDialog, setEditProductDialog] = useState(initialEditProductDialogState);
  // const [editProductData, setEditProductData] = useState({});
  const [productData, setProductData] = useState({productList: [], isFetching: false});
  // const [editProductDialog, setEditProductDialog] = useState({ open: false, editMode: false, newProduct: false, productID: null});

  const NewChip = (<Chip label="ADD" variant="outlined" className={classes.newChip} />);
  const ModifyChip = (<Chip label="MODIFY" variant="outlined" className={classes.modifyChip} />);
  const RemoveChip = (<Chip label="REMOVE" variant="outlined" className={classes.removeChip} />);

  useEffect(() => {
    const fetchProdctData = async() => {
      try{
        setProductData({productList: [], isFetching: true});
        const response = await callApiAsync({
          secure: true,
          method: 'get',
          url: getURL('get-all-staged-edits')
        })
        if(response.status === 200) {
          setProductData({productList: response.data, isFetching: false});
        } else {
          setProductData({productList: [], isFetching: false});
        }
      } catch(e) {
        console.log(e);
        setProductData({productList: [], isFetching: false});
      }
    };
    fetchProdctData();
    // eslint-disable-next-line
  }, []);

  async function deleteEditData(editData) {
    const response = await callApiAsync({
      method: 'post',
      url: getURL('remove-staged-edit', editData.edit_stage_id)
    })

    if(response.statues === 200) {
      // TODO: Slice the record from the productList
    }
  }

  function editProduct(productData) {
    // If edit_stage_id is not null, use that, otherwise use productID
    setEditProductDialog({ open: true, editMode: true, newProduct: false, productID: null, editStageID: productData.edit_stage_id});
  }
  
  function handleEditProductDialogClose() {
    setEditProductDialog(initialEditProductDialogState);
  }

  function resolveName(nameObj) {
    if(nameObj[language]) {
        return nameObj[language];
    } else {
        let defaultKey = Object.keys(nameObj)[0];
        return nameObj[defaultKey];
    }
  }

  return (
      <Container component="main" maxWidth="xl">
        <MaterialTable
          columns={[
            {
              field: 'edit_stage_id', 
              title: 'Edit Staging ID',
            },
            {
              field: 'data_type', 
              title: 'Data Type',
            },
            {
              field: 'object_id', 
              title: 'Entry ID',
              render: rowData => {
                if(rowData.object_id) {
                  return (<span>{rowData.object_id}</span>)
                } else {
                  return (<span><RemoveIcon /></span>)
                }
              }
            },
            {
              field: `data_object.lang.product_name[${language}]`, 
              title: 'Entry Name',
              render: rowData => <span>{resolveName(rowData.data_object.lang.product_name)}</span>
            },
            {
              field: 'new_object', 
              type: 'boolean',
              title: 'New Entry?',
            },
            {
              field: 'edit_type', 
              title: 'Edit Type',
              customFilterAndSearch: (term, rowData) => {
                let rowTerm = '3';
                if(rowData.mark_remove) {
                  rowTerm = '2';
                } else if(rowData.new_object) {
                  rowTerm = '1';
                } 
                return term.length === 0 || term.indexOf(rowTerm) !== -1;
              },
              lookup: { 1: 'Add', 2: 'Remove', 3: 'Modify' },
              render: rowData => {
                if(rowData.mark_remove) {
                  return RemoveChip;
                } else if(rowData.new_object) {
                  return NewChip;
                } else {
                  return ModifyChip;
                }
              }
            },
            {
              field: 'edited_by', 
              title: 'Edited By',
              filtering: false,
              render: rowData => {
                  return (<UserAvatar firstName={rowData.first_name} lastName={rowData.last_name} />)
              }
            },
            {
                field: 'created_at', 
                type: 'datetime', 
                title: 'Created At',
                filtering: false
            },
            {
                field: 'updated_at', 
                type: 'datetime', 
                title: 'Updated At',
                filtering: false
            },
            {
                field: 'actions',
                title: 'Actions',
                filtering: false,
                render: rowData => {
                  let product_id_str = rowData.object_id ? `/${rowData.object_id}` : '';
                  if(rowData.mark_remove) {
                    product_id_str = '';
                  }
                  if(rowData.new_object) {
                    product_id_str = '';
                  }
                  return (
                    <span>
                      <Link to={`/ReviewEdit/${rowData.edit_stage_id}${product_id_str}`}>
                        <IconButton className={classes.margin}>
                          <PageviewIcon />
                        </IconButton>
                      </Link>
                      {!rowData.mark_remove && rowData.user_id === currentUserID && (
                        <IconButton 
                          className={classes.margin}
                          onClick={(event) => {editProduct(rowData)}}>
                          <EditIcon />
                      </IconButton>
                      )}
                      {rowData.user_id === currentUserID && (
                        <IconButton 
                            className={classes.margin}
                            onClick={(event) => {deleteEditData(rowData)}}>
                            <DeleteIcon />
                        </IconButton>
                      )}
                    </span>
                  )
                }
            }
          ]}
          components={{
            OverlayLoading: LoadingSpinner
          }}
          options={{
            search: true,
            toolbar: false,
            paging: true,
            filtering: true,
            actionsColumnIndex: -1
          }}
          isLoading={productData.isFetching}
          data={productData.productList}
        />

        <EditProduct 
          editMode={editProductDialog.editMode}
          editStageID={editProductDialog.editStageID}
          open={editProductDialog.open}
          onClose={handleEditProductDialogClose}
          onSave={handleEditProductDialogClose}
        />
      </Container>
  );
}