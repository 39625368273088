import axios from "axios";
import { useState } from 'react';
import { useContext } from 'react';
import { AppVersionContext } from '../contexts/app-version-context';

export function authHeader() {
    // return authorization header with basic auth credentials
    // let token = JSON.parse(localStorage.getItem('token'));
    let token = localStorage.getItem('token');

    if (token) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}

export function appIdentificationHeaders() {
    return {
        'X-App-Name': process.env.REACT_APP_NAME,
        'X-App-Version': process.env.REACT_APP_VERSION
    }
}

export function buildApiHeaders(include) {
    let appH = appIdentificationHeaders();
    let authH = authHeader();

    if(include) {
        return {
            ...appH,
            ...authH,
            ...include
        }
    } else {
        return appH;
    }
}

function checkVersionResponse(response) {
    if(response.status === 420) {
        // Instead of refreshing it should set a context
        // window.location.reload(true);
        // const { updateVersionMatch } = useContext(AppVersionContext);
        console.log('Current running version of the client is outdated, please refresh your page');
    }
    return response;
}

export function callSecureApi(options) {
    let header = authHeader();
    options.headers = buildApiHeaders(header);
    options.validateStatus = (status) => status < 500;
    let response = axios(options);
    return checkVersionResponse(response);
}

export async function callSecureApiAsync(options) {
    let header = authHeader();
    options.headers = buildApiHeaders(header);
    options.validateStatus = (status) => status < 500;

    // let response = await axios(options);
    // return checkVersionResponse(response);
    return await axios(options);
}

export function callApi(options) {
    options.headers = buildApiHeaders();
    options.validateStatus = (status) => status < 500;
    let response = axios(options);
    return checkVersionResponse(response);
}

export async function callApiAsync(options) {
    options.headers = buildApiHeaders();
    options.validateStatus = (status) => status < 500;

    let response = await axios(options);
    return checkVersionResponse(response);
}

export const useAPI = () => {
    const [ response, setResponse ] = useState({});
    const { updateVersionMatch } = useContext(AppVersionContext);

    function checkResponse(response) {
        if(response.status === 420) {
            // Instead of refreshing it should set a context
            let { expected, received } = response.data;
            console.log(`Current running version of the client is outdated (expected ${expected}, received ${received}), please refresh your page`);
            updateVersionMatch(false);
        }
        return response;
    }

    async function callApiAsync(options) {
        let header = authHeader();
        options.headers = buildApiHeaders(header);
        options.validateStatus = (status) => status < 500;
    
        let response = await axios(options);
        checkResponse(response);
        setResponse(response);
        return response;
    }

    function callApi(options) {
        let header = authHeader();
        options.headers = buildApiHeaders(header);
        options.validateStatus = (status) => status < 500;

        let response = axios(options);
        checkResponse(response);
        setResponse(response);
        return response;
    }

    return { response, callApiAsync, callApi };
}