import React, {useState, useEffect} from "react";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import CalendarScheduler from '../CalendarScheduler';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#eeeeee',
    margin: theme.spacing(1)
  },
  noDates: {
    fontWeight: "bold"
  },
  date: {
    display: "block"
  }
}));


export default function AvailabilityDatesField (props) {
    const classes = useStyles();
    const { value, onChange, onSave, datesDisplayComponent, label } = props;

    const [editDatesDialog, setEditDatesDialog] = useState({ open:false })
    const [internalDateValues, setInternalDateValues] = useState({included_dates: [], excluded_dates: []});

    useEffect(() => {
      setInternalDateValues(value);
    }, [value]);

    function handleEditDatesDialogClose() {
      setEditDatesDialog({open:false})
    }

    function handleEditDatesDialogSave() {
      setEditDatesDialog({open:false})
      if(onSave) onSave(internalDateValues);
    }

    function handleEditDatesDialogCancel() {
      setEditDatesDialog({open:false})
    }

    function handleEditDatesClick() {
      setEditDatesDialog({open:true})
    }

    function handleDatesChange(newValues) {
      setInternalDateValues(newValues);
      if(onChange) onChange(newValues);
    }

  //   function scheduleDetails(scheduleData) {
  //     return (
  //       <Grid container direction="row" justify="space-around" alignItems="flex-start">
  //         {/* <Grid container item sm={5}> */}
  //           <Grid item sm={5}>
  //             <EditDatesComponent 
  //               title="Included Dates"
  //               data={scheduleData.included_dates}
  //               onChange={onChange}
  //             />
  //           </Grid>
  //         {/* </Grid> */}
  //         {/* <Grid container item sm={5}> */}
  //           <Grid item sm={5}>
  //             <EditDatesComponent 
  //               title="Excluded Dates"
  //               data={scheduleData.excluded_dates}
  //               onChange={onChange}
  //             />
  //           </Grid>
  //         {/* </Grid> */}
  //       </Grid>
  //     )
  // }

    function renderInnerComponent() {
      if(datesDisplayComponent) {
        return (<datesDisplayComponent dateValues={internalDateValues}/>)
      } else if(internalDateValues && internalDateValues.length > 0) {
        // return scheduleDetailPanel(internalDateValues);
        return (
          <span className={classes.noDates}>{label} - Click Edit to view availablity settings</span>
        )
      } else {
        return (
          <span className={classes.noDates}>{label} - No Dates Set</span>
        )
      }
    }

    return (
      <Grid container item 
        justify="space-between"
        alignItems="flex-start"
        className={classes.container} 
      >
        <Grid item xs={9}>
            {renderInnerComponent()}
        </Grid>
        <Grid item>
            <Button
                variant="contained"
                color="primary"
                size="small"
                // className={classes.button}
                startIcon={<DateRangeIcon />}
                onClick={handleEditDatesClick}
            >
                Edit Dates
            </Button>
        </Grid>
        <Dialog
            maxWidth="xl"
            open={editDatesDialog.open}
            onClose={handleEditDatesDialogClose}
        >
          <DialogContent>
            <CalendarScheduler 
              title={label}
              onChange={handleDatesChange}
              values={internalDateValues}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDatesDialogSave} color="primary">
              Save
            </Button>
            <Button onClick={handleEditDatesDialogCancel} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );

}