import React from "react";
import Avatar from '@material-ui/core/Avatar';
// import ToolTip from '@material-ui/core/ToolTip';

export default function UserAvatar (props) {
  const { firstName, lastName } = props;

  if(firstName && lastName) {
      return (
        // <ToolTip title={`${firstName} ${lastName}`}>
          <Avatar>{firstName.charAt(0)}{lastName.charAt(0)}</Avatar>
        // </ToolTip>
      )
  } else {
    return (<Avatar></Avatar>)
  }

}