import React, { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { getURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';

const configInfoURL = getURL('get-server-info');

export default function Admin() {
    const [data, setData] = useState({
        env: '',
        host: '',
        port: '',
        database: ''
    });
    const { callApiAsync } = useAPI();

    useEffect(() => {
        const fetchUserData = async() => {
          try{
            setData({});
            const response = await callApiAsync({
                method: 'get',
                url: configInfoURL
            });
            setData(response.data);
          } catch(e) {
            console.log(e);
            setData({});
          }
        };
        fetchUserData();
        // eslint-disable-next-line
    }, []);

    return (
        <Container component="main" maxWidth="xl">
            <Grid container>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Database
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item  sm={3}>
                                <Typography color="textSecondary">
                                    Environment
                                </Typography>
                            </Grid>
                            <Grid item  sm={9}>
                                <Typography color="textSecondary">
                                    {data.env}
                                </Typography>
                            </Grid>
                            <Grid item  sm={3}>
                                <Typography color="textSecondary">
                                    Host IP
                                </Typography>
                            </Grid>
                            <Grid item  sm={9}>
                                <Typography color="textSecondary">
                                    {data.host}
                                </Typography>
                            </Grid>
                            <Grid item  sm={3}>
                                <Typography color="textSecondary">
                                    Host Port
                                </Typography>
                            </Grid>
                            <Grid item  sm={9}>
                                <Typography color="textSecondary">
                                    {data.port}
                                </Typography>
                            </Grid>
                            <Grid item  sm={3}>
                                <Typography color="textSecondary">
                                    Name
                                </Typography>
                            </Grid>
                            <Grid item  sm={9}>
                                <Typography color="textSecondary">
                                    {data.database}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Container>
    );
}