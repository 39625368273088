import React, {useEffect, useState} from 'react';
import _ from "lodash";

import moment from 'moment';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';

import { EditorLanguageProvider, EditorLanguageConsumer } from '../contexts/editor-language-context';
import LanguageSelect from './fields/LanguageSelect';
import AssociatedMediaField from './fields/AssociatedMediaField';

import TranslateTextFieldWrapper from './fields/TranslateTextFieldWrapper';

import { getURL, buildMediaURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';

const defaultInitialLanguage = 1;
const saveMerchantEditURL = getURL('save-merchant');
const saveMerchantNewURL = getURL('add-new-merchant');

const useStyles = makeStyles(theme => ({
    langSelect: {
        backgroundColor: '#eeeeee',
        margin: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1),
    },
    appBar: {
        position: 'relative',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    title: {
        marginLeft: theme.spacing(2),
    },
    spacer: {
        flex: 1
    }
}));

export default function EditMerchant(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { callApiAsync } = useAPI();

    const { editMode, merchantID, editStageID, newMerchant, merchantData, open, onClose, onSave, keepOpenOnSave } = props;
    
    const [openState, setOpenState] = useState(false);

    const merchantInitialValues = {
        merchant_contact_number: '',
        lang: {
            merchant_name: {},
            merchant_description: {},
            merchant_address: {}
        }
    }

    const [merchantDetails, setMerchantDetails] = useState({data: merchantData || merchantInitialValues, isFetching: true});
    const [editDetails, setEditDetails] = useState({data: {}, isFetching: true});
    const [dirtyData, setDirtyData] = useState(false);

    useEffect(() => {
        setOpenState(open);
    }, [open])

    useEffect(() => {
        // TODO: Have the logic only really fetch if merchantData is not set (but editMode and merchantID is)
        if(editMode && merchantID) {
            const fetchProdDetails = async () => {
                try{
                    const responseMerchant = await callApiAsync({
                            method: 'get',
                            url: getURL('get-merchant-for-edit', merchantID),
                        });
                    setMerchantDetails({data: responseMerchant.data, isFetching: false});
                    setEditDetails({data: responseMerchant.data.edit_details, isFetching: false});
                } catch(e) {
                    console.log(e);
                    setMerchantDetails({isFetching: false});
                }
            };
            fetchProdDetails();
        } else if(editMode && editStageID) {
            const fetchProdDetails = async () => {
                try{
                    const responseMerchant = await callApiAsync({
                            method: 'get',
                            url: getURL('get-staged-edit-for-edit', editStageID),
                        });
                    setMerchantDetails({data: responseMerchant.data.merchant_data, isFetching: false});
                } catch(e) {
                    console.log(e);
                    setMerchantDetails({isFetching: false});
                }
            };
            fetchProdDetails();
        }
        // eslint-disable-next-line
    }, [editMode, merchantID, editStageID])

    async function handleEditMerchantDialogSave(event) {
        console.log(`New Merchant Mode - ${newMerchant}`);
        try {
            const response = await callApiAsync({
                url: newMerchant ? saveMerchantNewURL : saveMerchantEditURL,
                method: 'post',
                data: { merchant_data: merchantDetails.data, new_object: newMerchant }
            })
            
            setEditDetails({data: response.data.edit_details, isFetching: false});
            setDirtyData(false);
            enqueueSnackbar('Merchant changes saved succesfully',  { variant: 'success' })
            if(!keepOpenOnSave) resetState();
            if(onSave) onSave(event);
        } catch(e) {
            console.log(e);
            enqueueSnackbar('There was an issue saving changes, please try again',  { variant: 'error' });
        }
    }

    function handleEditMerchantDialogClose(event) {
        resetState();
        if(onClose) onClose(event);
    }

    function resetState() {
        setOpenState(false);
        setDirtyData(false);
        setMerchantDetails({...merchantDetails, data: merchantInitialValues});
        setEditDetails({data: {}, isFetching: false});
    }

    function translateFieldWrapper(fieldName, label, values, valueIndex, multiline, rowsMax) {
        return(
            <TranslateTextFieldWrapper
                fieldName={fieldName}
                label={label}
                values={values}
                valueIndex={valueIndex}
                multiline={multiline}
                rowsMax={rowsMax}
                fullWidth={true} 
                margin="dense"
                variant="outlined"
                setFieldValue={setLangFieldValue}
            />
        )
    }

    function textFieldWrapper(fieldName, label, value, multiline, rowsMax) {
        return(
            <FormControl fullWidth>
                <TextField
                    id={fieldName}
                    name={fieldName}
                    label={label}
                    value={value || ''}
                    multiline={multiline}
                    rowsMax={rowsMax}
                    fullWidth={true} 
                    margin="dense"
                    variant="outlined"
                    onChange={handleFieldChange}
                />
            </FormControl>
        )
    }

    function updateFieldValue(fieldName, fieldValue) {
        var merchantDataClone = _.cloneDeep(merchantDetails);
        merchantDataClone.data[fieldName] = fieldValue;
        setDirtyData(true);
        setMerchantDetails(merchantDataClone);
    }

    function handleFieldChange(e) {
        updateFieldValue(e.target.name, e.target.value);
    }

    function setFieldValue(name, value) {
        updateFieldValue(name, value);
    }

    function setLangFieldValue(name, value) {
        var merchantDataClone = _.cloneDeep(merchantDetails);
        merchantDataClone.data.lang[name] = value;
        setDirtyData(true);
        setMerchantDetails(merchantDataClone);
    }

    function getLastUpdatedString() {
        if(editDetails.data && editDetails.data.updated_at) {
            return `Last updated on ${moment(editDetails.data.updated_at).format('YYYY/MM/DD HH:mm:ss')}`
        } else {
            return '';
        }
    }

    function getMerchantIDString() {
        if(merchantDetails && merchantDetails.data && !_.isUndefined(merchantDetails.data.merchant_id)) {
            return `- ${merchantDetails.data.merchant_id}`
        } else {
            return null;
        }
    }
    
    const editorLanguageSelector = (
        <EditorLanguageConsumer>
            {({ language, updateLanguage }) => {
                return (
                    <LanguageSelect 
                        initialValue={language}
                        onChange={(event) => updateLanguage(event.target.value)}
                    />
                )}
            }
        </EditorLanguageConsumer>
    );

    return(
            <EditorLanguageProvider initialValue={defaultInitialLanguage}> 
                <Dialog
                    fullScreen 
                    maxWidth="xl"
                    open={openState}
                    onClose={handleEditMerchantDialogClose}
                >
                <AppBar position="sticky">
                    <Toolbar>
                    <Grid container direction="row">
                        <Grid container item direction="row" alignItems="center" sm={6}>
                            <IconButton edge="start" color="inherit" onClick={handleEditMerchantDialogClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Edit Merchant {getMerchantIDString()}
                            </Typography>
                            <Typography variant="subtitle1" className={classes.title}>
                                {getLastUpdatedString()}
                            </Typography>
                        </Grid>
                        <Grid container item direction="row" alignItems="center" justify="flex-end" spacing={1} sm={6}>
                            <Grid item>
                                {editorLanguageSelector}
                            </Grid>
                            <Grid item>
                                {dirtyData ? 
                                (
                                    <Button 
                                        variant="outlined"
                                        color="inherit"
                                        className={classes.button}
                                        onClick={handleEditMerchantDialogSave} 
                                    >
                                        Save
                                    </Button>
                                ) : null
                                }
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="outlined"
                                    color="inherit"
                                    className={classes.button}
                                    onClick={handleEditMerchantDialogClose} 
                                >
                                    {dirtyData ? 'Cancel' : 'Close'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    </Toolbar>
                </AppBar> 
                <Container maxWidth="xl">
                    <EditorLanguageConsumer>
                        {({ language, updateLanguage }) => (
                            <span>
                                <form className={classes.container} noValidate autoComplete="off">
                                    <Grid 
                                        container
                                        item
                                        // direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={3}
                                    >
                                        <Grid container item xs={11} spacing={3}>
                                            <AssociatedMediaField 
                                                fieldName="assoc_media"
                                                // Uncomment on change so that it automatically saves instead of needing to click save on the dialog
                                                // created by this field
                                                // onChange={(newValue) => {debugger; setFieldValue('assoc_media', newValue);}}
                                                onSave={(newValue) => setFieldValue('assoc_media', newValue)}
                                                label="Media"
                                                value={merchantDetails.data.assoc_media}
                                                listURL={buildMediaURL('merchant', merchantID)}
                                            />
                                        </Grid>
                                        <Grid item xs={11}>
                                            {translateFieldWrapper('merchant_name', 'Name', merchantDetails.data.lang.merchant_name, language)}
                                        </Grid>
                                        <Grid item xs={11}>
                                            {translateFieldWrapper('merchant_description', 'Description', merchantDetails.data.lang.merchant_description, language, true, 10)}
                                        </Grid>
                                        <Grid item xs={11}>
                                            {textFieldWrapper('merchant_contact_number', 'Contact Number', merchantDetails.data.merchant_contact_number)}
                                        </Grid>
                                        <Grid item xs={11}>
                                            {translateFieldWrapper('merchant_address', 'Address', merchantDetails.data.lang.merchant_address, language, true, 2)}
                                        </Grid>
                                    </Grid>
                                </form>
                            </span>
                        )}
                    </EditorLanguageConsumer>
                </Container>
            </Dialog>
        </EditorLanguageProvider>
    )
}