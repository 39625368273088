import React from "react";
import AsyncSelect from './AsyncSelect';

import { getURL } from '../../_helpers/url-builder';

const optionsURL = getURL('language-options');

export default function LanguageSelect (props) {
  const { initialValue, onChange, ...restProps } = props;

  function mapOptions(values) {
    return values.map(value => {return {
      value: value.id,
      key: value.id,
      name: value.name
    }})
  }

  return (
    <AsyncSelect
      initialValue={initialValue}
      onChange={onChange}
      optionsURL={optionsURL}
      mapDataToOptions={mapOptions}
      {...restProps}
    />
  );
}