import React, {useEffect, useState} from 'react';
import _ from "lodash";

import moment from 'moment';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import Zoom from '@material-ui/core/Zoom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add'; 
import CloseIcon from '@material-ui/icons/Close';

import { EditorLanguageProvider, EditorLanguageConsumer } from '../contexts/editor-language-context';
import LanguageSelect from './fields/LanguageSelect';
import CountrySelect from './fields/CountrySelect';
import AvailabilityDatesField from './fields/AvailabilityDatesField';
import AssociatedMediaField from './fields/AssociatedMediaField';

import TranslateTextFieldWrapper from './fields/TranslateTextFieldWrapper';
import AsyncKeywordSelect from './fields/AsyncKeywordSelect';
// import GooglePlacesAutoComplete from './fields/GooglePlacesAutoComplete';

import ActivityCard from './ActivityCard';

import EditActivity from './EditActivity'
import PricingTable from './PricingTable'


import { getURL, buildMediaURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';

const defaultInitialLanguage = 1;
const saveProductURL = getURL('save-product');

const useStyles = makeStyles(theme => ({
    langSelect: {
        backgroundColor: '#eeeeee',
        margin: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1),
    },
    appBar: {
        position: 'relative',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    title: {
        marginLeft: theme.spacing(2),
    },
    spacer: {
        flex: 1
    }
}));

export default function EditProduct(props) {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const { callApiAsync } = useAPI();

    const { editMode, productID, editStageID, newProduct, productData, open, onClose, onSave, keepOpenOnSave } = props;
    
    const [openState, setOpenState] = useState(false);
    const [activityDetails, setActivityDetails] = useState([]);
    const [activityEditor, setActivityEditor] = useState({open: false, editMode: false, activityID: null, activityData: {}})
    const [pricingEditor, setPricingEditor] = useState({open: false, editMode: false, activityID: null, activityData: {}, pricingData: {}})

    const productInitialValues = {
        product_type: '',
        country_code: '',
        district_code: '',
        schedule: [],
        keywords: [],
        lang: {
            product_name: {},
            keywords: {},
            depart_from: {},
            slogan: {},
            overview: {},
            major_attraction: {}
        }
    }

    const [productDetails, setProductDetails] = useState({data: productData || productInitialValues, isFetching: true});
    const [editDetails, setEditDetails] = useState({data: {}, isFetching: true});
    const [dirtyData, setDirtyData] = useState(false);

    useEffect(() => {
        setOpenState(open);
    }, [open])

    useEffect(() => {
        // TODO: Have the logic only really fetch if productData is not set (but editMode and productID is)
        if(editMode && productID) {
            const fetchProdDetails = async () => {
                try{
                    const responseProduct = await callApiAsync({
                            method: 'get',
                            url: getURL('get-product-for-edit', productID),
                        });
                    setProductDetails({data: responseProduct.data.product_data, isFetching: false});
                    setActivityDetails(responseProduct.data.product_data.t_activity || []);
                    setEditDetails({data: responseProduct.data.edit_details, isFetching: false});
                } catch(e) {
                    console.log(e);
                    setProductDetails({isFetching: false});
                }
            };
            fetchProdDetails();
        } else if(editMode && editStageID) {
            const fetchProdDetails = async () => {
                try{
                    const responseProduct = await callApiAsync({
                            method: 'get',
                            url: getURL('get-staged-edit-for-edit', editStageID),
                        });
                    setProductDetails({data: responseProduct.data.product_data, isFetching: false});
                    setActivityDetails(responseProduct.data.product_data.t_activity || []);
                } catch(e) {
                    console.log(e);
                    setProductDetails({isFetching: false});
                }
            };
            fetchProdDetails();
        }
        // eslint-disable-next-line
    }, [editMode, productID, editStageID])

    function handleAddActivity() {
        setActivityEditor({ open: true, newRecord: true });
        // const fetchProdDetails = async () => {
        //     try{
        //         const response = await callApiAsync({
        //             method: 'get',
        //             url: getURL('get-new-activity-id'),
        //         });
        //     } catch(e) {
        //         console.log(e);
        //     }
        // };
        // fetchProdDetails();
        // 'get-new-activity-id'
    }

    function handleCancelActivity(values) {
        setActivityEditor({ open: false });
    }

    function handleSaveActivity(values) {
        setActivityEditor({ open: false });
        var productDataClone = _.cloneDeep(productDetails);
        if(typeof values.activity_id === 'undefined') {
            values.activity_id = activityDetails.length;
        }
        if(activityEditor.newRecord) {
            values.newActivity = true;
            let activities = [...activityDetails, values];
            setActivityDetails(activities);
            productDataClone.data.t_activity = activities;
            setDirtyData(true);
            setProductDetails(productDataClone);
        } else {
            let newEntries = _.map(activityDetails, (entry) => {
                return entry.activity_id === values.activity_id ? values : entry;
            });
            setActivityDetails(newEntries);
            productDataClone.data.t_activity = newEntries;
            setDirtyData(true);
            setProductDetails(productDataClone);
        }
    }

    function handleEditActivity(activityData) {
        setActivityEditor({ open: true, editMode: true, activityData: activityData });
    }

    function handleEditActivityPricing(activityData) {
        setPricingEditor({ open: true, editMode: true, activityID: activityData.activity_id, activityData: activityData, pricingData: activityData.t_activity_pricing });
    }

    function handleDeleteActivity(activityData) {
        setActivityEditor({open: false, editMode: false, activityID: null, activityData: {}});
        var productDataClone = _.cloneDeep(productDetails);
        let newActivityEntries = _.filter(productDataClone.data.t_activity, (activity) => {
            return activity.activity_id !== activityData.activity_id;
        });
        setActivityDetails(newActivityEntries);
        productDataClone.data.t_activity = newActivityEntries;
        setDirtyData(true);
        setProductDetails(productDataClone);
    }

    function closePricingTable() {
        setPricingEditor({ open: false, editMode: false, activityID: null, activityData: {}, pricingData: {} })
    }

    function handlePricingDataChange(pricingData) {
        // Update the current open activity's pricing data
        let activityID = pricingEditor.activityID;
        var productDataClone = _.cloneDeep(productDetails);

        let newEntries = _.map(activityDetails, (entry) => {
            if(entry.activity_id === activityID) {
                entry.t_activity_pricing = pricingData;
            } 
            return entry;
        });
        setActivityDetails(newEntries);
        productDataClone.data.t_activity = newEntries;
        setDirtyData(true);
        setProductDetails(productDataClone);
    }

    // function savePricingData(pricingData) {
    //     // Update the current open activity's pricing data
    //     let activityID = pricingEditor.activityID;
    //     var productDataClone = _.cloneDeep(productDetails);

    //     // Scrub tableData from pricingData
    //     let updatedPricingData = _.map(pricingData, p => _.omit(p, ['tableData']));
    //     let newEntries = _.map(activityDetails, (entry) => {
    //         if(entry.activity_id === activityID) {
    //             entry.t_activity_pricing = updatedPricingData;
    //         } 
    //         return entry;
    //     });
    //     setActivityDetails(newEntries);
    //     productDataClone.data.t_activity = newEntries;
    //     setDirtyData(true);
    //     setProductDetails(productDataClone);
        
    //     setPricingEditor({ open: false, editMode: false, activityID: null, activityData: {}, pricingData: {} })
    // }

    async function handleEditProductDialogSave(event) {
        try {
            const response = await callApiAsync({
                url: saveProductURL,
                method: 'post',
                data: { product_data: productDetails.data, new_object: newProduct }
            })
            
            setEditDetails({data: response.data.edit_details, isFetching: false});
            setDirtyData(false);
            enqueueSnackbar('Product changes saved succesfully',  { variant: 'success' })
            if(!keepOpenOnSave) resetState();
            if(onSave) onSave(event);
        } catch(e) {
            console.log(e);
            enqueueSnackbar('There was an issue saving changes, please try again',  { variant: 'error' });
        }
    }

    function handleEditProductDialogClose(event) {
        resetState();
        if(onClose) onClose(event);
    }

    function resetState() {
        setOpenState(false);
        setActivityDetails([]);
        setDirtyData(false);
        setProductDetails({...productDetails, data: productInitialValues});
        setActivityEditor({open: false, editMode: false, activityID: null, activityData: {}});
        setEditDetails({data: {}, isFetching: false});
        setPricingEditor({open: false, editMode: false, activityID: null, activityData: {}, pricingData: {}});
    }

    function pricingTable(activityPricing, language) {
        return(
            <Paper>
                <PricingTable 
                    pricingData={activityPricing}
                    language={language}
                    onClose={closePricingTable}
                    onChange={handlePricingDataChange}
                    // onSave={savePricingData}
                    onCancel={closePricingTable}
                />
            </Paper> 
        )
    }

    const activityCards = activityDetails.map(activity => (
        <EditorLanguageConsumer key={activity.activity_id}>
            {({ language, updateLanguage }) => (
                <Grid 
                    container
                    item
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                    xs={pricingEditor.open && activity.activity_id === pricingEditor.activityID ? 11 : 3} 
                    key={activity.id}
                >
                    <Zoom in={true}>
                        <Grid item xs={3}>
                            <ActivityCard 
                                activityData={activity}
                                onEdit={handleEditActivity}
                                onEditPricing={handleEditActivityPricing}
                                onDelete={handleDeleteActivity}
                                valueIndex={language}
                            />
                        </Grid>
                    </Zoom>
                </Grid>
            )}
        </EditorLanguageConsumer>
    ));

    function translateFieldWrapper(fieldName, label, values, valueIndex, multiline, rowsMax) {
        return(
            <TranslateTextFieldWrapper
                fieldName={fieldName}
                label={label}
                values={values}
                valueIndex={valueIndex}
                multiline={multiline}
                rowsMax={rowsMax}
                fullWidth={true} 
                margin="dense"
                variant="outlined"
                setFieldValue={setLangFieldValue}
            />
        )
    }

    function textFieldWrapper(fieldName, label, value, multiline, rowsMax) {
        return(
            <FormControl fullWidth>
                <TextField
                    id={fieldName}
                    name={fieldName}
                    label={label}
                    value={value || ''}
                    multiline={multiline}
                    rowsMax={rowsMax}
                    fullWidth={true} 
                    margin="dense"
                    variant="outlined"
                    onChange={handleFieldChange}
                />
            </FormControl>
        )
    }

    function updateFieldValue(fieldName, fieldValue) {
        var productDataClone = _.cloneDeep(productDetails);
        productDataClone.data[fieldName] = fieldValue;
        setDirtyData(true);
        setProductDetails(productDataClone);
    }

    function handleFieldChange(e) {
        updateFieldValue(e.target.name, e.target.value);
    }

    function setFieldValue(name, value) {
        updateFieldValue(name, value);
    }

    function setLangFieldValue(name, value) {
        var productDataClone = _.cloneDeep(productDetails);
        productDataClone.data.lang[name] = value;
        setDirtyData(true);
        setProductDetails(productDataClone);
    }

    function getLastUpdatedString() {
        if(editDetails.data && editDetails.data.updated_at) {
            return `Last updated on ${moment(editDetails.data.updated_at).format('YYYY/MM/DD HH:mm:ss')}`
        } else {
            return '';
        }
    }

    function getProductIDString() {
        if(productDetails && productDetails.data && !_.isUndefined(productDetails.data.product_id)) {
            return `- ${productDetails.data.product_id}`
        } else {
            return null;
        }
    }
    
    const editorLanguageSelector = (
        <EditorLanguageConsumer>
            {({ language, updateLanguage }) => {
                return (
                    <LanguageSelect 
                        initialValue={language}
                        onChange={(event) => updateLanguage(event.target.value)}
                    />
                )}
            }
        </EditorLanguageConsumer>
    );

    return(
            <EditorLanguageProvider initialValue={defaultInitialLanguage}> 
                <Dialog
                    fullScreen 
                    maxWidth="xl"
                    open={openState}
                    onClose={handleEditProductDialogClose}
                >
                <AppBar position="sticky">
                    <Toolbar>
                    <Grid container direction="row">
                        <Grid container item direction="row" alignItems="center" sm={6}>
                            <IconButton edge="start" color="inherit" onClick={handleEditProductDialogClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Edit Product {getProductIDString()}
                            </Typography>
                            <Typography variant="subtitle1" className={classes.title}>
                                {getLastUpdatedString()}
                            </Typography>
                        </Grid>
                        <Grid container item direction="row" alignItems="center" justify="flex-end" spacing={1} sm={6}>
                            <Grid item>
                                {editorLanguageSelector}
                            </Grid>
                            <Grid item>
                                {dirtyData ? 
                                (
                                    <Button 
                                        variant="outlined"
                                        color="inherit"
                                        className={classes.button}
                                        onClick={handleEditProductDialogSave} 
                                    >
                                        Save
                                    </Button>
                                ) : null
                                }
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="outlined"
                                    color="inherit"
                                    className={classes.button}
                                    onClick={handleEditProductDialogClose} 
                                >
                                    {dirtyData ? 'Cancel' : 'Close'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    </Toolbar>
                </AppBar> 
                <Container maxWidth="xl">
                    <EditorLanguageConsumer>
                        {({ language, updateLanguage }) => (
                            <span>
                                <form className={classes.container} noValidate autoComplete="off">
                                    <Grid 
                                        container
                                        item
                                        // direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={3}
                                    >
                                        <Grid container item xs={11} spacing={3}>
                                            <AssociatedMediaField 
                                                fieldName="assoc_media"
                                                // Uncomment on change so that it automatically saves instead of needing to click save on the dialog
                                                // created by this field
                                                // onChange={(newValue) => {debugger; setFieldValue('assoc_media', newValue);}}
                                                onSave={(newValue) => setFieldValue('assoc_media', newValue)}
                                                label="Media"
                                                value={productDetails.data.assoc_media}
                                                listURL={buildMediaURL('product', productID)}
                                            />
                                        </Grid>
                                        <Grid item xs={11}>
                                            {translateFieldWrapper('product_name', 'Name', productDetails.data.lang.product_name, language)}
                                        </Grid>
                                        <Grid item xs={11}>
                                            <FormControl fullWidth variant="outlined" margin="dense">
                                                <InputLabel id="country_code">
                                                    Country
                                                </InputLabel>
                                                <CountrySelect 
                                                    label="Country"
                                                    value={productDetails.data.country_code}
                                                    name='country_code'
                                                    onChange={handleFieldChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={11}>
                                            {textFieldWrapper('district_code', 'District Code', productDetails.data.district_code)}
                                        </Grid>
                                        <Grid item xs={11}>
                                            {/* {translateFieldWrapper('keywords', 'Keywords', productDetails.data.lang.keywords, language)} */}
                                            <AsyncKeywordSelect 
                                                id="keywords"
                                                name="keywords"
                                                language={language}
                                                value={productDetails.data.keywords}
                                                onChange={(val) => updateFieldValue("keywords", val)}
                                            />
                                        </Grid>
                                        <Grid item xs={11}>
                                            <FormControl fullWidth variant="outlined" margin="dense">
                                                <InputLabel ref={inputLabel} id="product_type">
                                                    Type
                                                </InputLabel>
                                                <Select
                                                    id="product_type"
                                                    name="product_type"
                                                    label="Type"
                                                    onChange={handleFieldChange}
                                                    value={productDetails.data.product_type}
                                                >
                                                    <MenuItem value={1}>Flight</MenuItem>
                                                    <MenuItem value={2}>Accomodation</MenuItem>
                                                    <MenuItem value={3}>Transportation</MenuItem>
                                                    <MenuItem value={4}>Activity</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item xs={11}>
                                            <GooglePlacesAutoComplete />
                                        </Grid> */}
                                        <Grid item xs={11}>
                                            {translateFieldWrapper('depart_from', 'Depart From', productDetails.data.lang.depart_from, language)}
                                        </Grid>
                                        <Grid item xs={11}>
                                            {translateFieldWrapper('slogan', 'Slogan', productDetails.data.lang.slogan, language, true, 5)}
                                        </Grid>
                                        <Grid item xs={11}>
                                            {translateFieldWrapper('overview', 'Overview', productDetails.data.lang.overview, language, true, 10)}
                                        </Grid>
                                        <Grid item xs={11}>
                                            {translateFieldWrapper('major_attraction', 'Major Attraction', productDetails.data.lang.major_attraction, language)}
                                        </Grid>
                                        <Grid container item xs={11} spacing={3}>
                                            <AvailabilityDatesField 
                                                fieldName="schedule"
                                                // Uncomment on change so that it automatically saves instead of needing to click save on the dialog
                                                // created by this field
                                                // onChange={(newValue) => setFieldValue('schedule', newValue)}
                                                onSave={(newValue) => setFieldValue('schedule', newValue)}
                                                label="Availability"
                                                value={productDetails.data.schedule}
                                            />
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                //className={classes.button}
                                                startIcon={<AddIcon />}
                                                onClick={handleAddActivity}
                                            >
                                                Add Activity
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                                <Grid 
                                    container
                                    // direction="column"
                                    justify="center"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    { activityEditor.open ? <EditActivity editMode={activityEditor.editMode} activityID={activityEditor.activityID} activityData={activityEditor.activityData} newRecord={activityEditor.newRecord} onCancelActivity={handleCancelActivity} onSaveActivity={handleSaveActivity}/> : null}
                                    { pricingEditor.open ? pricingTable(pricingEditor.pricingData, language) : null}
                                    { activityEditor.open || pricingEditor.open ? null : activityCards }
                                </Grid>
                            </span>
                        )}
                    </EditorLanguageConsumer>
                </Container>
            </Dialog>
        </EditorLanguageProvider>
    )
}