import _ from "lodash";

export function formatPercent(value) {
    if(_.isNaN(value) || _.isNull(value) || _.isUndefined(value)) {
        return '';
    }
    return (parseFloat(value)*100).toFixed(2);
}

export function formatMoney(value) {
    if(_.isNaN(value) || _.isNull(value) || _.isUndefined(value)) {
        return '';
    }
    return parseFloat(value).toFixed(2);
}