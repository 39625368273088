import React from "react";

import CompanyLogo from "../img/atl_logo_cropped_circle.png"

export default function LogoComponent (props) {
  const { className, ...other } = props;

    return (
      <img src={CompanyLogo} alt='Starhoptour Logo' className={className} {...other} />
    );

}