import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import LoadingSpinner from '../components/LoadingSpinner';
import ReviewProductView from './ReviewProductView';

import { getURL } from '../_helpers/url-builder';
import { useAPI } from '../_helpers/auth-request';

export default function ReviewProductDiff(props) {
    const { callApiAsync } = useAPI();

    const { edit_stage_id, product_id } = useParams();

    const [reviewDetails, setReviewDetails] = useState({ data: null, isFetching: true, newProduct: false, removeProduct: false });
    
    useEffect(() => {
        // TODO: Have the logic only really fetch if productData is not set (but editMode and productID is)
        if (edit_stage_id && product_id) {
            const fetchEditDetails = async () => {
                try {
                    let ids = { edit_stage_id, product_id };
                    let url = getURL('get-staged-and-product-diff', ids);
                    const response = await callApiAsync({
                        method: 'get',
                        url,
                    });
                    setReviewDetails({ data: response.data, isFetching: false, newProduct: false, removeProduct: false });
                } catch (e) {
                    console.log(e);
                    setReviewDetails({ isFetching: false });
                }
            };
            fetchEditDetails();
        } else if (edit_stage_id && !product_id) {
            const fetchEditDetails = async () => {
                try {
                    let url = getURL('get-staged-edit', edit_stage_id);
                    const response = await callApiAsync({
                        method: 'get',
                        url,
                    });
                    let removeProduct = false;
                    if(response.data.edit && response.data.edit.mark_remove) {
                        removeProduct = true;
                    }
                    setReviewDetails({ data: response.data, isFetching: false, newProduct: true, removeProduct: removeProduct });
                } catch (e) {
                    console.log(e);
                    setReviewDetails({ isFetching: false });
                }
            };
            fetchEditDetails();
        }
        // eslint-disable-next-line
    }, [edit_stage_id, product_id])

    return reviewDetails.isFetching ? (<LoadingSpinner alpha={0}/>) : (<ReviewProductView productData={reviewDetails.data} newProduct={reviewDetails.newProduct} removeProduct={reviewDetails.removeProduct} editStageID={edit_stage_id}/>);
}