import React from "react";
import AsyncSelect from './AsyncSelect';

import { getURL } from '../../_helpers/url-builder';

const getCountriesURL = getURL('country-options');

export default function CountrySelect (props) {
    const { initialValue, onChange, ...restProps } = props;

    function mapOptions(values) {
      return values.map(value => {return {
        value: value.country_code,
        key: value.country_code,
        name: value.name
      }})
    }

    return (
      <AsyncSelect
        initialValue={initialValue}
        onChange={onChange}
        optionsURL={getCountriesURL}
        mapDataToOptions={mapOptions}
        {...restProps}
      />
    );
}