import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles(theme => ({
  vertTabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function VerticalTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

VerticalTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function ActivityPricingTable(props) {
  const classes = useStyles();
  const { children, value, index, activityPricingData, language, ...other } = props;

  if(other) {

  }

  function resolveTargetString(record, language) {
    if(language === 1) {
      return record.target_name_en;
    } else {
      return record.target_name_ch
    }
  }

  return (
    <Paper>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Target Name</TableCell>
            <TableCell>Period</TableCell>
            <TableCell>Retail Price</TableCell>
            <TableCell>List Price</TableCell>
            <TableCell>Service Rate</TableCell>
            <TableCell>Net Price</TableCell>
            <TableCell>Qty</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activityPricingData.map(row => {
            let serviceRate = Math.floor(row.service_rate * 10000) / 100;
            let period_str = row.period;
            if(!row.period && (row.period_start && row.period_end)) {
              period_str = new Date(row.period_start).toLocaleDateString() +" - "+ new Date(row.period_end).toLocaleDateString(); 
            }
              return (
                <TableRow key={row.activity_pricing_id}>
                  <TableCell component="th" scope="row">
                    {resolveTargetString(row.t_lang_activity_pricing, language)}
                  </TableCell>
                  <TableCell>{period_str}</TableCell>
                  <TableCell>{row.retail_price}</TableCell>
                  <TableCell>{row.list_price}</TableCell>
                  <TableCell>{serviceRate} %</TableCell>
                  <TableCell>{row.net_price}</TableCell>
                  <TableCell>{row.stock_qty}</TableCell>
                </TableRow>
              )
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

function buildDurationString(days, nights, hours, minutes) {
  if(days && nights) {
    return days +"D"+ nights +"N";
  } else {
    let daysPart = (days > 1) ? days+" days" : ((days > 0) ? days+" day" : "");
    let hoursPart = (days > 1) ? hours+" hours" : ((hours > 0) ? hours+" hour" : "");
    let minutesPart = (days > 1) ? minutes+" minutes" : ((minutes > 0) ? minutes+" minute" : "");

    let combString = daysPart +" "+ hoursPart +" "+ minutesPart;
    
    return combString;
  }
}

export default function ActivitiesPanel (props) {
    const classes = useStyles();
    const activityData = props.data;
    const language = props.language;
    
    return (
      <div className={classes.vertTabsRoot}>
        {activityData.map(activityRow => {
          let durationString = buildDurationString(activityRow.duration_days, activityRow.duration_nights, activityRow.duration_hours, activityRow.duration_minutes);

          let language_index = 0;
          if(activityRow.t_lang_activity[language-1]) {
            language_index = language-1;
          }

          return (
            <div key={activityRow.activity_id}>
              <div className={"activity-title"}>{activityRow.t_lang_activity[language_index].activity_name}</div>
              <div className={"activity-duration"}>{durationString}</div>
              <ActivityPricingTable
                activityPricingData = {activityRow.t_activity_pricing}
                language = {language}
              />
            </div>
          )
        })}
      </div>
    );

}