import React, {useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertLinkIcon from '@material-ui/icons/InsertLink';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import MaterialTable from 'material-table';

import { useSnackbar } from 'notistack';

import _ from "lodash";

import UploadMediaPreview from '../components/UploadMediaPreview';
import LoadingSpinner from '../components/LoadingSpinner';
import { useAPI } from '../_helpers/auth-request';
import { getURL, buildURL } from '../_helpers/url-builder';

// Both list and card view for media
const GET_ALL_MEDIA_URL = getURL('get-all-media');
const UPLOAD_IMAGE_URL = getURL('upload-media');

const DEFAULT_MAX_DIMS = {
    maxHeight: 150, 
    maxWidth: 200
}

const UPLOAD_DEFAULT_MAX_DIMS = {
    maxHeight: 600,
    maxWidth: 800
}

const useStyles = makeStyles(theme => ({
  mediaRoot: {
    maxWidth: 345,
  },
  media: {
    height: 160,
  },
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    marginLeft: '100px',
  },
  mediaPreview: DEFAULT_MAX_DIMS,
  uploadMediaPreview: UPLOAD_DEFAULT_MAX_DIMS,
  uploadInput: {
    display: 'none',
  },
}));

const valueLabels = {
  'category': 'Category ID',
  'product': 'Product ID',
  'direct': 'URL'
}

function MediaManager(props) {
  const { pageSize, pageSizeOptions, listURL, /* removeURL, */ saveURL, /* onSave, */ onChange, allowUpdateLinks } = props;

  const classes = useStyles();
  const { callApiAsync } = useAPI();
  const [mediaData, setMediaData] = useState({mediaList: [], isFetching: false});
  const [view, setView] = useState('list');
  const [uploadImage, setUploadImage] = useState({ set: false });
  const [editMediaDialog, setEditMediaDialog] = useState({ open: false, mediaData: {} })
  const [linkProperties, setLinkProperties] = useState({ linkType:'', catType:'', value:''})

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
      let fetchData = async () => {
          try {
              setMediaData({ mediaList: [], isFetching: true });
              const response = await callApiAsync({
                  method: "get",
                  url: listURL ? listURL : GET_ALL_MEDIA_URL,
              });
              setMediaData({
                  mediaList: response.data.media,
                  isFetching: false,
              });
          } catch (e) {
              console.log(e);
              setMediaData({ mediaList: [], isFetching: false });
          }
      };
      fetchData();
      // eslint-disable-next-line
  }, [listURL]);

  function showMediaEditDialog(rowData) {
    setEditMediaDialog({ open: true, mediaData: rowData});
    if(rowData.link_properties) {
      let { linkType, catType, value } = rowData.link_properties;
      setLinkProperties({ 
        linkType: linkType || '',
        catType: catType || '',
        value: value || '',
      });
    }
  }

  function closeMediaEditDialog() {
    setEditMediaDialog({ open: false, mediaData: {}});
    setLinkProperties({ linkType:'', catType:'', value:''});
  }

  function updateLink(name, value) {
    setLinkProperties({ ...linkProperties, [name]: value});
  }

  async function updateMediaLink() {
    let mediaID = editMediaDialog.mediaData.media_id;
    let newMediaData = _.map(mediaData.mediaList, m => {
      if(m.media_id === mediaID) {
        return {
          ...m,
          link_properties: linkProperties
        }
      } else {
        return m;
      }
    })
    try{
      const saveResponse = await callApiAsync({
          method: "post",
          url: getURL('update-media-link', mediaID),
          data: {
              link_properties: linkProperties
          }
      })
      if(saveResponse.status === 200) {
        enqueueSnackbar('Media link details saved succesfully',  { variant: 'success' })
      } else {
        enqueueSnackbar('Encountered an issue while saving media link details',  { variant: 'error' })
      }
    }
    catch(e) {
      enqueueSnackbar('Encountered an issue while saving media link details',  { variant: 'error' })
    }
    setMediaData({
        mediaList: newMediaData,
        isFetching: false,
    });
    closeMediaEditDialog();
    
  }

  let mediaColumns = [
    {
      field: 'media_id', 
      type: 'string', 
      title: 'Media ID',
      // render: rowData => <span>{rowData.lang.keyword_text[language]}</span>
    },
    {
      field: 'media_type', 
      type: 'string', 
      title: 'Type'
    },
    {
      field: 'imageURL', 
      type: 'string', 
      title: 'Preview',
      render: rowData => <img alt={rowData.media_id} src={buildURL(`/images/product/${rowData.file_path}`)} style={DEFAULT_MAX_DIMS}></img>
    }
  ];

  let resolveLinkString = (linkProps) => {
    if(linkProps && linkProps.value) {
      return (
        <Grid container direction="column">
          <Grid item>{linkProps.linkType}</Grid>
          <Grid item>{linkProps.catType}</Grid>
          <Grid item>{linkProps.value}</Grid>
        </Grid>
      )
    } else {
      return 'Not Set';
    }
  }

  if(allowUpdateLinks) {
      mediaColumns.push({
          field: 'imageLinkURL',
          type: 'string',
          title: 'Link',
          render: rowData => {
              let linkStr = resolveLinkString(rowData.link_properties);
              return (
                <span>{linkStr}</span>
              );
          }
      })
  }

  function renderListView() {
    return(
      <MaterialTable
        maxWidth="xs"
        columns={mediaColumns}
        components={{
          OverlayLoading: LoadingSpinner
        }}
        options={{
          search: true,
          toolbar: false,
          paging: true,
          filtering: false,
          actionsColumnIndex: -1,
          pageSize: pageSize ? pageSize : 20,
          pageSizeOptions: pageSizeOptions ? pageSizeOptions : [20,50,100]
        }}
        isLoading={mediaData.isFetching}
        data={mediaData.mediaList}
        editable={{
          onRowDelete: oldData =>
            new Promise(async (resolve, reject) => {
              let result = await handleRemoveMedia(oldData.media_id);
              if(result) {
                resolve();
              } else {
                reject();
              }
            }),
        }}
        actions={[
          {
            icon: rowData => <InsertLinkIcon />,
            tooltip: 'Edit Link',
            onClick: (event, rowData) => showMediaEditDialog(rowData)
          }
        ]}
      />
    )
  }

  function renderCardsView() {
    return (
      <Grid container spacing={2}>
        {
          mediaData.mediaList.map(mediaItem => {
            return(
              <Grid item sm={3} key={mediaItem.media_id}>
                <Card className={classes.mediaRoot}>
                  <CardActionArea>
                    <CardMedia 
                      className={classes.media}
                      image={buildURL(`/images/product/${mediaItem.file_path}`)}
                      title={mediaItem.title}
                    />
                  </CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {mediaItem.title || "No title set"}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {mediaItem.caption || "No caption set"}
                  </Typography>
                </CardContent>
                  <CardActions>
                    {/* <Button size="small" color="primary">
                      View Details
                    </Button> */}
                    <Button size="small" color="primary">
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Grid> 
            )
          })
        }
      </Grid>
    )
  }

  function handleViewChange(event, newView) {
    if(newView !== null) {
      setView(newView);
    }
  }

  async function handleRemoveMedia(remove_media_id) {
      let canCommit = true;
      
      const newMediaList = _.filter(mediaData.mediaList, m => m.media_id !== remove_media_id );
      const mediaIds = _.map(newMediaList, m => m.media_id);

      if(saveURL) {
          // Add it to our list and save it
          const saveResponse = await callApiAsync({
              method: "post",
              url: saveURL,
              data: {
                  banner_media: mediaIds
              }
          })
          canCommit = saveResponse.status === 200;
      }

      if(canCommit) {
          setUploadImage({ set: false });
          // if(onSave) { onSave(mediaIds) }
          if(onChange) { onChange(newMediaList) }
          setMediaData({ mediaList: _.cloneDeep(newMediaList), isFetching: false });
          return true;
      } else {
          return false;
      }
  }

  async function handleAddMediaDialogSave() {
      const formData = new FormData();
      formData.append("file", uploadImage.file, uploadImage.file.name);

      const response = await callApiAsync({
          method: "post",
          url: UPLOAD_IMAGE_URL,
          data: formData
      })

      if(response.status === 200) {
          let canCommit = true;
          
          let newMediaList = _.cloneDeep(mediaData.mediaList);
          newMediaList.push(response.data);
          const mediaIds = _.map(newMediaList, m => m.media_id);

          if(saveURL && response.data && response.data.media_id) {
              // Add it to our list and save it
              const saveResponse = await callApiAsync({
                  method: "post",
                  url: saveURL,
                  data: {
                      banner_media: mediaIds
                  }
              })
              canCommit = saveResponse.status === 200;
          }

          if(canCommit) {
              setUploadImage({ set: false });
              if(onChange) { onChange(newMediaList) }
              setMediaData({ mediaList: newMediaList, isFetching: false });
              return true;
          } else {
              return false;
          }
      }
  }

  function handleAddMediaDialogCancel() {
      setUploadImage({ set: false });
  }

  function handleImageChange(e) {
      e.preventDefault();
  
      let reader = new FileReader();
      let file = e.target.files[0];
  
      reader.onloadend = () => {
          setUploadImage({
              file: file,
              imagePreviewUrl: reader.result,
              set: true
          });
      }
  
      reader.readAsDataURL(file)
  }

  return (
      <Container component="main">
          <Grid container spacing={1}>
              <Grid
                  item
                  container
                  sm={12}
                  justify="space-between"
                  alignItems="center"
              >
                  <Grid item sm={3}>
                      <input
                          accept="image/*"
                          className={classes.uploadInput}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleImageChange}
                      />
                      <label htmlFor="contained-button-file">
                          <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              startIcon={<CloudUploadIcon />}
                          >
                              Add Media
                          </Button>
                      </label>
                  </Grid>
                  <Grid item>
                      <ToggleButtonGroup
                          value={view}
                          exclusive
                          onChange={handleViewChange}
                          aria-label="view mode"
                          size="small"
                      >
                          <ToggleButton value="list" aria-label="view list">
                              <ViewListIcon />
                          </ToggleButton>
                          <ToggleButton value="cards" aria-label="view cards">
                              <ViewModuleIcon />
                          </ToggleButton>
                      </ToggleButtonGroup>
                  </Grid>
              </Grid>
              <Grid item sm={12}>
                  {view === "list" ? renderListView() : null}
                  {view === "cards" ? renderCardsView() : null}
              </Grid>
          </Grid>
          <UploadMediaPreview
              open={uploadImage.set}
              imageURL={uploadImage.imagePreviewUrl}
              name={uploadImage.file && uploadImage.file.name}
              className={classes.uploadMediaPreview}
              onConfirm={handleAddMediaDialogSave}
              onCancel={handleAddMediaDialogCancel}
          />
          <Dialog
              maxWidth="md"
              fullWidth={true}
              open={editMediaDialog.open}
              onClose={closeMediaEditDialog}
          >
              <DialogContent>
                  {/* {renderUploadPreview()} */}
                  <Grid container spacing={2} direction="column">
                    <Grid container item>
                      <Grid item sm={2}>Type:</Grid>
                      <Grid item sm={10}>
                        <Select
                          value={linkProperties.linkType}
                          onChange={(e) => updateLink('linkType', e.target.value)}
                          displayEmpty
                          margin="dense"
                          variant="outlined"
                          label="Link Type"
                        >
                          <MenuItem value={''}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={'category'}>Category</MenuItem>
                          <MenuItem value={'product'}>Product</MenuItem>
                          <MenuItem value={'direct'}>Direct</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    {(linkProperties && linkProperties.linkType === 'category')
                      ?
                      (
                      <Grid container item>
                        <Grid item sm={2}>Category View:</Grid>
                        <Grid item sm={10}>
                          <Select
                            value={linkProperties.catType}
                            onChange={(e) => updateLink('catType', e.target.value)}
                            displayEmpty
                            margin="dense"
                            variant="outlined"
                            label="Category Type"
                          >
                            <MenuItem value={'top_level'}>Top Level</MenuItem>
                            <MenuItem value={'destination_group'}>Destination Group</MenuItem>
                            <MenuItem value={'destination'}>Destination</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      )
                      :
                      null
                    }
                    {(linkProperties && linkProperties.linkType)
                      ?
                      (
                        <Grid container item>
                          <Grid item sm={2}>Value:</Grid>
                          <Grid item sm={10}>
                            <TextField 
                              id="linkValue"
                              label={valueLabels[linkProperties && linkProperties.linkType] || "Value"}
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={linkProperties.value}
                              onChange={(e) => updateLink('value', e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      )
                      :
                      null
                    }
                  </Grid>
              </DialogContent>
              <DialogActions>
                  <Button onClick={updateMediaLink} color="primary">
                      Confirm 
                  </Button>
                  <Button onClick={closeMediaEditDialog} color="primary">
                      Cancel
                  </Button>
              </DialogActions>
          </Dialog>
      </Container>
  );
}

export default MediaManager;