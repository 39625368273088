import React, {useState} from "react";
import moment from 'moment';
import MaterialTable from 'material-table';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Grid from '@material-ui/core/Grid';

import _ from "lodash";
import WeekdaysSelector from './WeekDaysSelector';
import Calendar from './YearlyCalendar/Calendar';
import CalendarControls from './YearlyCalendar/CalendarControls';

import EditDatesComponent from "../components/EditDatesComponent";

export default function CalendarScheduler (props) {
  const { title, onChange, values, dayHighlightFn, displayColumns/*, dayHighlightClasses */} = props;

  const tableDisplayColumns = displayColumns || [];
  const WEEKDAYS_INITIAL_VALUE = ['Su','M','T','W','Th','F','S'];

  const today = moment();
  const [calendar, setCalendarState] = useState({
    year: today.year(), 
    // selectedDay: today,
    // selectedRange: [today, moment(today).add(15, 'day')],
    allowDaySelection: true,
    showDaysOfWeek: true,
    showTodayBtn: false,
    showWeekSeparators: true,
    selectRange: false,
    // firstDayOfWeek: 1 // Monday
    firstDayOfWeek: 0 // Sunday
  })
  const [scheduleDetails, setScheduleDetails] = useState(values);

  const mTableEditable = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
          let newSchedData = _.cloneDeep(scheduleDetails);
          let { tableData, ...updatedData } = newData;
          newSchedData.push(updatedData);
          setScheduleDetails(newSchedData);
          if(onChange) onChange(newSchedData);
          resolve();
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
          let newSchedData = _.cloneDeep(scheduleDetails);
          const index = oldData.tableData.id;
          let { tableData, ...updatedData } = newData;
          newSchedData[index] = updatedData;
          setScheduleDetails(newSchedData);
          if(onChange) onChange(newSchedData);
          resolve();
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
          let newSchedData = _.cloneDeep(scheduleDetails);
          const index = newSchedData.indexOf(oldData);
          newSchedData.splice(index, 1);
          setScheduleDetails(newSchedData);
          if(onChange) onChange(newSchedData);
          resolve();
      }),
  }

  function changeYear(newYear) {
    setCalendarState({
      ...calendar,
      year: newYear
    })
  }

  function defaultDayHighlightFn(day) {
    
    // Check exclusions
    let exclusionFound = _.some(scheduleDetails, sched => {
      return _.some(sched.excluded_dates, date => {
        
        // Check based on it being a single or range
        if(date.dateMode === 1) { // SINGLE
          // console.log(day.format() +' ==> '+ day.isSame(date.value, 'day'));
          return day.isSame(date.value, 'day');
        } else if (date.dateMode === 2) { // RANGE
          if(day.isBetween(date.value.start, date.value.end, null, '[]')) {
            let dayOfWeek = day.day();
            if(sched.avail_days[dayOfWeek +'']) {
              return true;
            } else {
              return false;
            }
          }
        }

      })
    })

    // If an exclusion is found, we don't need to check inclusions, just move on
    if(exclusionFound) return false;

    // Check inclusions
    let inclusionFound = _.some(scheduleDetails, sched => {
      return _.some(sched.included_dates, date => {
        
        // Check based on it being a single or range
        if(date.dateMode === 1) { // SINGLE
          return day.isSame(date.value, 'day');
        } else if (date.dateMode === 2) { // RANGE
          // console.log(day.format() +' ==> '+ day.isBetween(date.value.start, date.value.end, null, '[]'));
          if(day.isBetween(date.value.start, date.value.end, null, '[]')) {
            let dayOfWeek = day.day();
            if(sched.avail_days[dayOfWeek +'']) {
              return true;
            } else {
              return false;
            }
          }
        }

      })
    })

    return inclusionFound;
  }

  function handleDateComponentChange(newDetails) {
    let newData = _.map(scheduleDetails, sched => {
      return sched.id === newDetails.id ? newDetails : sched;
    })
    setScheduleDetails(newData);
    if(onChange) onChange(newData);
  }

  function scheduleDetailPanel(scheduleData) {
      return (
        <Grid container direction="row" justify="space-around" alignItems="flex-start">
          {/* <Grid container item sm={5}> */}
            <Grid item sm={5}>
              <EditDatesComponent 
                title="Included Dates"
                data={scheduleData.included_dates}
                onChange={(newDates) => handleDateComponentChange({ ...scheduleData, included_dates: newDates })}
              />
            </Grid>
          {/* </Grid> */}
          {/* <Grid container item sm={5}> */}
            <Grid item sm={5}>
              <EditDatesComponent 
                title="Excluded Dates"
                data={scheduleData.excluded_dates}
                onChange={(newDates) => handleDateComponentChange({ ...scheduleData, excluded_dates: newDates })}
              />
            </Grid>
          {/* </Grid> */}
        </Grid>
      )
  }

  function renderCalendar() {
    return(
      <div>
        <CalendarControls
          year={calendar.year}
          showTodayButton={calendar.showTodayBtn}
          onPrevYear={() => changeYear(calendar.year-1)}
          onNextYear={() =>changeYear(calendar.year+1)}
        />
        <Calendar
          // onPickDate={datePicked}
          customClasses={{
            available: dayHighlightFn ? dayHighlightFn : defaultDayHighlightFn
          }}
          {...calendar}
        />
      </div>
    )
  }

  return (
    <div>
      { title ? (<h2> {title} </h2>) : null }
        <MaterialTable
          columns={[
            ...tableDisplayColumns,
            {
                field: 'avail_days', 
                type: 'string', 
                title: 'Days of the Week',
                render: rowData => <WeekdaysSelector value={ rowData.avail_days } viewMode={true} />,
                editComponent: props => (
                    <WeekdaysSelector 
                        value={props.value}
                        onChange={weekdays => props.onChange(weekdays) }
                    />
                ),
                initialEditValue: WEEKDAYS_INITIAL_VALUE
                // initialEditValue: {
                //     '0': true,    // Starting with 0 being Sunday
                //     '1': true,    // Monday
                //     '2': true,    // Tuesday
                //     '3': true,    // Wednesday
                //     '4': true,    // Thursday
                //     '5': true,    // Friday
                //     '6': true,    // Saturday
                // }
            }
          ]}
          editable={mTableEditable}
          options={{
            search: false,
            paging: false
          }}
          title={''}
          data={scheduleDetails}
          detailPanel={[
              {
                  tooltip: 'Edit Pricing Schedule',
                  icon: () => (<DateRangeIcon />),
                  openIcon: () => (<DateRangeIcon />),
                  render: rowData => scheduleDetailPanel(rowData)
              }
          ]}
        />
      {renderCalendar()}
    </div>  
  );

}