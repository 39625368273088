import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from './contexts/auth-context';
import { AppVersionProvider } from './contexts/app-version-context';

import 'react-sortable-tree/style.css'; // This only needs to be imported once
 
ReactDOM.render(
    <AuthProvider>
      <AppVersionProvider>
        <Router>
          <App />
        </Router>
      </AppVersionProvider>
    </AuthProvider>,
    document.getElementById("root")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
