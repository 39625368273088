import React, {useState, useEffect} from "react";
import MaterialTable from 'material-table';
import moment from 'moment';

import _ from "lodash";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  // KeyboardDatePicker,
} from '@material-ui/pickers';

export default function ScheduleEditor (props) {
  const { title, duration, calcDuration, onChange, values } = props;
  const editableEndDate = calcDuration ? 'never' : 'always';

  const today = moment();
  const [productDatesData, setProductDatesData] = useState(values);

  useEffect(() => {
    if(onChange) {
      onChange(productDatesData);
    }
  }, [productDatesData, onChange])

  const mTableEditable = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
          const data = productDatesData;
          if(calcDuration) newData.end = calcEndTime(newData.start);
          data.push(newData);
          setProductDatesData(_.clone(data));
          resolve();
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
          const data = productDatesData;
          if(calcDuration) newData.end = calcEndTime(newData.start);
          const index = data.indexOf(oldData);
          data[index] = newData;
          setProductDatesData(_.clone(data));
          resolve();
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
          let data = productDatesData;
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          setProductDatesData(_.clone(data));
          resolve();
      }),
  }

  function extractTime(date) {
    return moment(date).format('HH:mm');
  }

  function timeToDateObj(time) {
    let datePart = today.clone().format('YYYY-MM-DD');
    return moment(`${datePart} ${time}`, 'YYYY-MM-DD HH:mm').toDate();
  }

  function buildDurationString(durationObj) {
    let { days, nights, hours, minutes } = durationObj
    if(days && nights) {
      return days +"D"+ nights +"N";
    } else {
      let daysPart = (days > 1) ? days+" days" : ((days > 0) ? days+" day" : "");
      let hoursPart = (hours > 1) ? hours+" hours" : ((hours > 0) ? hours+" hour" : "");
      let minutesPart = (minutes > 1) ? minutes+" minutes" : ((minutes > 0) ? minutes+" minute" : "");
  
      let combString = daysPart +" "+ hoursPart +" "+ minutesPart;
      
      return combString;
    }
  }

  function calcEndTime(startTime) {
    let datePart = today.clone().format('YYYY-MM-DD');
    return moment(`${datePart} ${startTime}`, 'YYYY-MM-DD HH:mm').add(duration).format('HH:mm');
  }

  function buildTitle() {
    let titlePart = title;
    let durationPart = calcDuration ? buildDurationString(duration) : "";

    if(titlePart && durationPart) {
      return (<h2> {titlePart} - {durationPart}</h2>);
    } else if(titlePart) {
      return (<h2> {title} </h2>);
    } else if(durationPart) {
      return (<h2> {durationPart} </h2>);
    } else {
      return null;
    }
  }

  return (
    <div>
      {buildTitle(title)}
      <MaterialTable
        columns={[
          {
            field: 'start', 
            type: 'time', 
            title: 'Start',
            editComponent: props => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  autoOk
                  margin="dense"
                  id="time-picker-start"
                  label="Start Time"
                  ampm={false}
                  value={timeToDateObj(props.value)}
                  onChange={date => props.onChange(extractTime(date))}
                />
              </MuiPickersUtilsProvider>
            ),
            initialEditValue: '10:00'
          },
          {
            field: 'start_location',
            title: 'Start Location'
          },
          {
            field: 'end', 
            type: 'time', 
            title: 'End',
            editable: editableEndDate,
          },
          {
            field: 'end_location',
            title: 'End Location'
          },
        ]}
        editable={mTableEditable}
        options={{
          search: false,
          paging: false
        }}
        title={''}
        data={productDatesData}
      />
    </div>  
  );

}