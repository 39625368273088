import React, { useState } from "react";
import _ from "lodash";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DateRangeIcon from '@material-ui/icons/DateRange';

import MaterialTable from 'material-table';

import { formatMoney, formatPercent } from '../_helpers/numbers';
import CurrencyField from './fields/CurrencyField';
import TranslateTextField from './fields/TranslateTextField';
import CalendarScheduler from './CalendarScheduler';

export default function PricingTable(props) {
    const editDatesDialogReset = { open:false, activityPricingID: null, activityPricingSchedule: {} };

    const { pricingData, language, label, onChange, onSave, onClose, onCancel } = props;
    const [ internalPricingData, setInternalPricingData ] = useState(pricingData || []);
    const [ dirtyData, setDirtyData ] = useState(false);
    const [ editDatesDialog, setEditDatesDialog ] = useState(editDatesDialogReset)

    function currencyComponent(props) {
        return (
            <CurrencyField 
                value={props.value} 
                onChange={e => props.onChange(e.target.value)}
            />
        )
    }
    const displayColumns = [
        { title: 'Retail Price',    field: 'retail_price',  editComponent: currencyComponent,
                render: rowData => (<span>{formatMoney(rowData.retail_price)}</span>)                               },
        { title: 'List Price',      field: 'list_price',    editComponent: currencyComponent,
                render: rowData => (<span>{formatMoney(rowData.list_price)}</span>)                                 },
        { title: 'Service Rate',    field: 'service_rate',  
                render: rowData => (<span>{formatPercent(rowData.service_rate)}</span>)                             },
        { title: 'Net Price',       field: 'net_price',     editComponent: currencyComponent,
                render: rowData => (<span>{formatMoney(rowData.net_price)}</span>)                                  },
        { title: 'Qty',             field: 'stock_qty'                                                              },
        { title: 'Currency',        field: 'currency_code'                                                          }
    ];

    function handleEditDatesDialogClose() {
        setEditDatesDialog(editDatesDialogReset);
    }

    function handleDataUpdate(newPricingData) {
        let cleanPricingData = _.map(newPricingData, p => {
            const {tableData, ...record} = p;
            return record;
        });
        setInternalPricingData(newPricingData);
        if(onChange) {
            onChange(cleanPricingData);
        } else {
            // If onChange is not set, then we use the internal dirty data mechanism
            setDirtyData(true);
        }
    }


    function handleDatesChange(newValues) {
        let newInternalPricingData = _.map(internalPricingData, p => {
            if(p.activity_pricing_id === editDatesDialog.activityPricingID) {
                let cleanSchedule = _.map(newValues, sched => {
                    const {tableData, ...record} = sched;
                    return record;
                });
                return {
                    ...p,
                    schedule: cleanSchedule
                }
            } else {
                return p;
            }
        });
        setEditDatesDialog({ ...editDatesDialog,activityPricingSchedule: newValues });
        handleDataUpdate(newInternalPricingData);
    }

    function handleClose() {
        if(onClose) onClose()
    }

    function handleSave() {
        if(onSave) onSave(internalPricingData);
        setDirtyData(false);
    }

    function handleCancel() {
        if(onCancel) onCancel();
    }

    function showSaveOptions() {
        return (
            <span>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </span>
        )
    }

    function showCloseOption() {
        return (
            <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
        >
            Close
        </Button>
        )
    }

    return (
        <div>
            <MaterialTable
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            let newInternalData = _.cloneDeep(internalPricingData);
                            newInternalData.push(newData);
                            handleDataUpdate(newInternalData);
                            resolve();
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            let newInternalData = _.cloneDeep(internalPricingData);
                            const index = oldData.tableData.id;
                            let { tableData, ...updatedData } = newData;
                            newInternalData[index] = updatedData;
                            handleDataUpdate(newInternalData);
                            resolve();
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            let newInternalData = _.cloneDeep(internalPricingData);
                            const index = oldData.tableData.id;
                            newInternalData.splice(index, 1);
                            handleDataUpdate(newInternalData);
                            resolve();
                        })
                }}
                columns={[
                    { title: 'Target Name',     field: 'lang.target_name',
                            editComponent: props => {
                                return (
                                    <TranslateTextField
                                        values={props.value}
                                        margin="dense"
                                        valueIndex={1}
                                        onChange={(newValue) => props.onChange(newValue)}
                                    />
                                );
                            },
                            render: rowData => <span>{rowData.lang.target_name[language]}</span>                    },
                    { title: 'Age Min',          field: 'age_min',              type: 'numeric'  },
                    { title: 'Age Max',          field: 'age_max',              type: 'numeric'  },
                    { title: 'Pax Min',          field: 'pax_min',              type: 'numeric'  },
                    { title: 'Pax Max',          field: 'pax_max',              type: 'numeric'  },
                    // { title: 'Attributes',       field: 'target_attributes',    emptyValue: ''  },
                    { title: 'Schedules Set',    field: 'schedule_count', 
                            editable: 'never',          
                            render: rowData => <span>{rowData.schedule.length}</span>                              }
                ]}
                actions={[
                    {
                        icon: () => (<DateRangeIcon />),
                        tooltip: 'Edit Pricing Schedule',
                        onClick: (event, rowData) => {console.log(rowData); setEditDatesDialog({ open: true, activityPricingID: rowData.activity_pricing_id, activityPricingSchedule: rowData.schedule });}
                    },
                ]}
                options={{
                    search: false,
                    paging: false
                }}
                title={''}
                data={internalPricingData}
            />
            { dirtyData ? showSaveOptions() : showCloseOption() }
            <Dialog
                maxWidth="xl"
                open={editDatesDialog.open}
                onClose={handleEditDatesDialogClose}
            > 
                <DialogContent>
                    <CalendarScheduler 
                        title={label}
                        displayColumns={displayColumns}
                        onChange={handleDatesChange}
                        values={editDatesDialog.activityPricingSchedule}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDatesDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}