import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import ActivitiesPanel from './ActivitiesPanel';


const useStyles = makeStyles(theme => ({
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    width: "100%"
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  details: {
    alignItems: "center"
  },
  column: {
    flexBasis: "50%"
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ProductPanel (props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { language, setProductPreview, ...restProps } = props;

    if(restProps) {
      
    }

    function handleChange(event, newValue) {
      setValue(newValue);
    }

    function showProductDialog() {
      setProductPreview({data: props.data});
    }

    let language_index = 0;
    if(props.data.t_lang_product[language-1]) {
      language_index = language-1;
    }

    return (
      <div className={classes.root}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className={classes.column}>
              <Typography className={classes.heading}>{props.data.product_code} - {props.data.t_lang_product[language_index].product_name}</Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <div className={classes.tabsRoot}>
              <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} aria-label="product tabs" centered>
                  <Tab label="Details" {...a11yProps(0)} />
                  <Tab label="Activities" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>{props.data.country_code}</Typography>
                    <Typography variant="h6" gutterBottom>{props.data.district_code}</Typography>
                    <Typography variant="subtitle2" gutterBottom>{props.data.product_code}</Typography>
                    <Typography variant="body2" gutterBottom>{props.data.t_lang_product[language_index].slogan}</Typography>
                    <Button variant="contained" color="primary"
                      onClick={showProductDialog}>
                      Show Product Details
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={9}>
                  <Paper className={classes.paper}>
                    {props.data.t_lang_product[language_index].overview.split ('\n').map ((item, i) => <Typography variant="body1" gutterBottom key={i}>{item}</Typography>)}
                  </Paper>
                </Grid>
              </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ActivitiesPanel
                  data = {props.data.t_activity}
                  language = {language}
                />
              </TabPanel>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );

}

ProductPanel.defaultProps = {
  language: 1
}